.search {
  &-modal {
    .modal__body {
      padding-top: 0;
    }
  }
  &-close-button {
    top: 1.8rem;
    right: 1.8rem;

    .ReactModal__Content & {
      transform: translateY(0);
    }
  }

  &-dialog__form {
    margin-top: 10.9rem;
  }

  &-suggestions {
    &__title {
      margin-bottom: 2rem;
    }

    &__list {
      padding: 0;
      list-style: none;
    }

    &__suggestion {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }

  &-promocards {
    &__list {
      overflow: hidden;
    }
    &__card {
      width: unset;
      flex: 1;
    }
  }
}

// ANCHOR - NO RESULTS --------------------------------------------------------------------
.no-results {
  &-content {
    padding: 7.5rem 0 16em;
  }
  .productTiles-rail {
    padding-top: 0;
  }

  &__title {
    &--wrapper {
      margin-bottom: 0;
    }
  }
  &__description {
    margin-bottom: 0;
  }
}
