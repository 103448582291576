.selector-quantity {
  display: flex;
  align-items: center;

  &__controls {
    display: flex;
    align-items: center;
  }

  .minus-button,
  .plus-button {
    @include touchtarget-area();
    &:focus {
      @include touchtarget-focus();
    }
    &.disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  .minus-button {
    @include fixedSprite('10-minimize');
  }
  .plus-button {
    @include fixedSprite('10-open');
  }

  &__value {
    &--outofstock {
      color: $disabled-dark;
      // @include strokeThrough;
    }
    margin-top: 0.2rem;
    margin-right: $space-unit;
    margin-left: $space-unit;
  }

  &__msg {
    // @extend %primary-xxs;
    margin-left: ($space-unit * 3);
    color: $disabled-dark;
  }
}
