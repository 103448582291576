.size-modal {
  .sizechart-tabs {
    [role='tablist'] {
      overflow: auto;
    }
  }
  .sizechart__mannequin {
    flex: 0 0 40%;
  }
}
