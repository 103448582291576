.thank-you {
  &-text {
    padding: 6rem 0 4.4rem;
  }
  &-button {
    padding: 4.8rem 0 4.4rem;
    row-gap: 2rem;
  }

  @include breakpoint(lg, min) {
    &-text {
      padding: 12rem 0 6rem;
    }
    &-button {
      padding: 0 0 12rem;
    }
  }
}
