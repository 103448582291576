#product-details-modal {
  .modal__content {
    display: flex;
    flex-direction: column;
    .modal__body {
      flex: 1;
      padding: 6rem 0 8rem;
    }
  }

  @include breakpoint(lg, min) {
    width: calc(calc(#{var(--col-width)} * 9) + #{var(--container-fill-half-padding)} + calc(#{var(--container-gap)} * 9));
    .modal__content {
      max-width: unset;
      width: 100%;
      padding: 0 var(--container-fill-half-padding) 0 var(--container-gap);
    }
    .modal__wrapper {
      max-width: unset;
    }
  }
  @include breakpoint(xxl, min) {
    width: calc(calc(#{var(--col-width)} * 7) + #{var(--container-fill-half-padding)} + calc(#{var(--container-gap)} * 7));
  }
}
.product-details {
  height: 100%;

  .accordion {
    &__group {
      height: 100%;
    }
  }
  .accordion__panel {
    &#fabric-research-panel {
      .icons-list {
        margin: 4rem 0 0;
        padding: 0 0 2rem;
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        li {
          margin: 0;
          padding: 0;
          height: 2.4rem;
          display: flex;
          align-items: center;
        }
        img {
          height: 2.4rem;
          width: auto;
        }
      }
    }

    > div {
      max-width: 46.2rem;

      p:not(:last-child) {
        margin-bottom: 1em;
      }
    }
  }

  .composition-care-icons-container {
    padding: 0;
    list-style: none;
    li {
      padding: 0;
      margin: 0;

      display: flex;
      align-items: center;
      gap: 0.8rem;
      img {
        width: 2.4rem;
        height: 2.4rem;
        margin: 0;
        filter: invert(100%);
      }
      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }
    }
  }

  .pdp-drawer-ca {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    gap: 4rem;
    dl {
      dt {
        text-transform: uppercase;
      }
      dt + dd {
        margin-top: 0.8rem;
      }
      dd + dt {
        margin-top: 2rem;
      }

      &.doubled {
        dd + dt {
          margin-top: 4rem;
        }
      }
    }
  }
}
