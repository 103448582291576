.selector-color {
  .selector__header {
    margin-bottom: 0.8rem;
  }

  &.inpdp {
    margin-bottom: 3.2rem;
  }

  &.intile {
    height: $selector-size-intile-height;
    margin-top: 2.4rem;
  }
}

.selector-wrapper {
  display: flex;
  .selector-navigation {
    flex-grow: 0;
    align-items: flex-end;
    min-width: 16%;
    justify-content: flex-end;

    button {
      width: 3.2rem;
      height: 3.2rem;
    }

    .selector-navigation-chevrons {
      gap: 0.4rem;
    }

    &.selector-navigation-top {
      margin-top: 1.4rem;
    }
  }
}
