.login-modal {
  // .modal__header {
  //   display: none;
  // }
  &.in-drawer {
    .modal__body {
      display: flex;
      flex-direction: column;
    }

    h2 {
      @extend %font__title;
    }
  }

  .modal__body--right {
    display: flex;
    flex-direction: column;
  }

  .form-group {
    input:not([type='radio'], [type='checkbox']),
    select,
    textarea {
      height: 3.6rem;
    }
  }

  .form-section {
    & > .row {
      row-gap: 4rem;
    }
  }

  .remember-me {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3.2rem;
  }

  .privacy-link {
    text-align: right;
    @extend .button--link-underline, %font__title;
  }

  .login-register-social {
    display: flex;
    flex-direction: column;
    gap: 1.9rem;
    justify-content: flex-start;
  }

  h2.login-modal-subtitle {
    margin-bottom: 0;
  }

  &__login-social {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;

    .row:first-child {
      margin-bottom: 0.8rem;
    }
  }
}
