$subnavDrawerHeight: 30rem;

.subnav-header {
  // margin: 0;
  height: $subnavfilterHeight;
}

/* sorting */

.subnav-sortby {
  top: 100%;
  min-width: 23rem;
  width: auto;
  .subnav-content-fixed {
    opacity: 1;
    @include animate(0.2, 'opacity');
    position: relative;
    width: 100%;
    height: $subnavDrawerHeight;
  }
  &.rah-static--height-zero,
  &.rah-animating--up {
    .subnav-content-fixed {
      opacity: 0;
    }
  }
}

.subnav-refinements-backdrop {
  position: absolute;
  height: 0;
  width: 100%;
  left: 0;
  top: 100%;
  background-color: $content-white;
  z-index: -1;
  @include animate(0.2, 'height', $easeOutQuart, 0.2);
  &.is-open {
    height: $subnavDrawerHeight;
    transition-delay: 0s;
  }
}

.subnav-content {
  &.subnav-refinements-content .subnav-content-fixed {
    position: absolute;
    top: 0;
    height: $subnavDrawerHeight;
    width: 100%;
    z-index: 0;
    &.refinement-expanded {
      z-index: 1;
    }
  }

  .subnav-content-fixed {
    padding-top: 2.4rem;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .subnav-refinements-actions {
    @include border('top');
    text-align: right;
  }

  .btn-refinement-apply {
    min-width: 32.2rem;
  }

  .btn-refinement-clear {
    border-width: 0;
  }
}

.subnav-filters {
  &__refinements-section {
    z-index: 0;
    //   visibility: hidden;
    //   opacity: 0;

    //   &.expanded {
    //     z-index: 1;
    //     visibility: visible;
    //     opacity: 1;
    //   }

    .container,
    .row {
      height: 100%;
    }

    ul {
      display: grid;
      justify-content: start;
      grid-template-columns: repeat(5, 19%);
      grid-template-rows: repeat(6, 1fr);
      grid-auto-flow: column;
      height: 100%;
      padding-left: $half-gutter-width;
      row-gap: 0.4rem;
      max-height: 24rem;
    }

    &.one-column {
      ul {
        grid-template-columns: repeat(1, 100%);
      }
    }
  }

  &__refinement-overflow {
    overflow: hidden;
  }
}

.subnav-refinements {
  &-list {
    visibility: hidden;
    opacity: 0;
    @include animate(0.4);

    .subnav-refinements-actions {
      opacity: 0;
      @include animate(0.1s, 'all', linear);
    }

    &.is-visible {
      visibility: visible;
      opacity: 1;
      .subnav-refinements-actions {
        opacity: 1;
        transition-delay: 0.15s;
        transition-duration: 0.1s;
      }
    }

    .refine-button[aria-expanded='true'],
    .refine-button[aria-selected='true'] {
      span {
        border-bottom: solid 1px #000;
      }
    }

    li.refine-button {
      display: flex;
      align-items: center;
    }
  }
}

#for-overlayposition {
  display: block;
}
