$colors: red, orange, yellow, green, blue, purple;
$repeat: 20;
.latest_carousel {
  &--container {
  }

  &--carousel {
    overflow: hidden;
    .swiper-wrapper {
      transition-timing-function: linear;
    }
  }

  &--slide {
  }

  &--tile {
    text-decoration: none;
    position: relative;
    display: block;
    margin-top: 3px;
    margin-bottom: 3px;
    @include animate(0.5, 'opacity');
    @include apply-focusablearea(100%);

    .tile--content {
      &_details {
        > .icon {
          margin-left: -0.6rem;
        }

        &-content {
          opacity: 0;
          visibility: hidden;
          @include animate(0.5);
        }

        &.is-open {
          .tile--content_details-content {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }

    .product-image {
      height: auto;
      img {
        height: auto;
      }
    }
  }

  &--carousel:hover &--tile {
    opacity: 0.5;
  }

  &--carousel:hover &--tile:hover {
    opacity: 1;
  }
}
