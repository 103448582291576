.size-modal {
  .modal-body {
    padding-top: 4.8rem;
  }

  .sizeguide-modelsizes {
    display: flex;
    border: 1px solid $background-product;
    padding: 1.2rem;
    margin: 4rem 0;
    img {
      margin-right: 1.7rem;
      max-width: 7.2rem;
      align-self: center;
    }
    .sizeguide-modelsizes-head {
      margin-bottom: 3.6rem;
    }

    .sizeguide-modelsizes-table {
      display: grid;
      grid-auto-flow: column;
      grid-template-rows: repeat(2, 1fr);
      column-gap: 3.5rem;
      row-gap: 0.6rem;
    }
  }
  .sizeguide-modelsizes-desc {
    margin-left: 0.8rem;
  }
  .sizechart {
    --sizechart-height: 0;
    .country-select .form-select {
      width: max-content;
      select {
        padding-right: 5rem;
      }
    }
    .scrollable-area {
      display: flex;
      flex-grow: 1;
      column-gap: 2.4rem;
      padding-bottom: 2.4rem;
      margin-left: 2.4rem;
      @include scrollbar;
    }
    .custom-table {
      margin-top: 4rem;
      margin-bottom: 10.8rem;
      display: flex;
    }
    .column {
      display: flex;
      flex-direction: column;
      row-gap: 1.6rem;
      flex-shrink: 0;
      min-width: 10rem;
      width: min-content;
      &.fixed-col {
        padding-right: 2.4rem;
        flex-basis: auto;
      }
      .column-head {
        min-height: var(--sizechart-height);
      }
    }
  }
  .sizechart--measure {
    display: flex;
    margin-top: 4.8rem;
    min-height: inherit;
    justify-content: space-between;
  }
  .sizechart__description {
    margin-bottom: 4rem;
  }
  .sizechart-tabs {
    [role='tablist'] {
      justify-content: space-between;
      overflow: scroll;
      [role='tab'] {
        flex-shrink: 1;
      }
    }
    .react-tabs__tab-panel {
      display: none;
      &--selected {
        display: block;
      }
    }
    .sizechart__filters {
      .radio + .radio {
        margin-left: 2.4rem;
      }
    }
  }
}
.sizechart__legend {
  dt {
    margin-bottom: 0.6rem;
  }
  dd {
    margin-bottom: 3.2rem;
  }
}
.sizechart__wrapper {
  width: 38.2rem;
}
@mixin mannequinSprite($name) {
  $sprite: map-get($sprites, $name);
  background: url('#{$sprite}') top no-repeat;
  height: auto;
}

// If you wonder why all this questionable code, see SIE-4584
@mixin mannequinSquareRatio() {
  aspect-ratio: 1/1;
  min-height: 0;
  max-width: 45rem;
  height: 100%;
}

@mixin calcMannequinAnnotationWidth($lineBaseWidth, $imgHeight, $imageRatio) {
  $imgWidth: ($imgHeight * get($imageRatio, x)) / get($imageRatio, y);
  $halfImgWidth: $imgWidth / 2;
  $percentageRelativeWidth: ($imgWidth / 100) * $lineBaseWidth;
  width: calc(50% - #{$halfImgWidth}px + #{$percentageRelativeWidth}px);
}
@mixin mannequinAnnotationWidth($lineBaseWidth, $imgHeight, $imgHeightDesktop, $imageRatio) {
  @include calcMannequinAnnotationWidth($lineBaseWidth, $imgHeight, $imageRatio);
  @include breakpoint(md, min) {
    @include calcMannequinAnnotationWidth($lineBaseWidth, $imgHeightDesktop, $imageRatio);
  }
}

.size-modal .sizechart__mannequin {
  position: relative;
  .sizechart__annotations {
    position: absolute;
    right: 0;
    width: 100%;
    text-align: right;
    &::before {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      background-color: #000;
      opacity: 0.2;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  $mannequinHeightDesktop: 400;
  $mannequinHeightMobile: 450;
  $menMannequinImgRatio: (
    x: 1,
    y: 2,
  );

  &--men-top {
    @include mannequinSprite('sizeguide-male-top');
    .sizechart__annotations {
      &:first-child {
        top: 9.5%;
        @include breakpoint(md, min) {
          top: 9.1%;
        }
        &::before {
          $lineWidthPercentage: 43;
          @include mannequinAnnotationWidth($lineWidthPercentage, $mannequinHeightMobile, $mannequinHeightDesktop, $menMannequinImgRatio);
        }
      }
      &:nth-child(2) {
        top: 20.6%;
        @include breakpoint(md, min) {
          top: 20.2%;
        }
        &::before {
          $lineWidthPercentage: 31;
          @include mannequinAnnotationWidth($lineWidthPercentage, $mannequinHeightMobile, $mannequinHeightDesktop, $menMannequinImgRatio);
        }
      }
      &:last-child {
        top: 34.6%;
        @include breakpoint(md, min) {
          top: 34.1%;
        }
        &::before {
          $lineWidthPercentage: 34;
          @include mannequinAnnotationWidth($lineWidthPercentage, $mannequinHeightMobile, $mannequinHeightDesktop, $menMannequinImgRatio);
        }
      }
    }
  }
  &--men-bottom {
    @include mannequinSprite('sizeguide-male-bottom');
    .sizechart__annotations {
      &:first-child {
        top: 38.9%;
        @include breakpoint(md, min) {
          top: 38.4%;
        }
        &::before {
          $lineWidthPercentage: 33;
          @include mannequinAnnotationWidth($lineWidthPercentage, $mannequinHeightMobile, $mannequinHeightDesktop, $menMannequinImgRatio);
        }
      }
      &:last-child {
        top: 44%;
        @include breakpoint(md, min) {
          top: 43.5%;
        }
        &::before {
          $lineWidthPercentage: 31;
          @include mannequinAnnotationWidth($lineWidthPercentage, $mannequinHeightMobile, $mannequinHeightDesktop, $menMannequinImgRatio);
        }
      }
    }
  }
  &--belt.sizechart__mannequin {
    @include mannequinSprite('sizeguide-belt');
    @include mannequinSquareRatio;
    .sizechart__annotations {
      &:first-child {
        top: 32.4%;
        transform: translateY(-100%);
        &::before {
          width: 23.5%;
        }
      }
    }
  }
  &--glove.sizechart__mannequin {
    @include mannequinSprite('sizeguide-hand');
    @include mannequinSquareRatio;
    .sizechart__annotations {
      &:first-child {
        text-align: left;
        top: 43%;
        transform: translateY(-100%);
        &::before {
          width: 23%;
          left: 0;
        }
      }
    }
  }
  &--hat.sizechart__mannequin {
    @include mannequinSprite('sizeguide-head');
    @include mannequinSquareRatio;
    .sizechart__annotations {
      &:first-child {
        top: 33%;
        transform: translateY(-100%);
        &::before {
          width: 25%;
        }
      }
    }
  }
  &--kid {
    @include mannequinSprite('sizeguide-kid');
  }
}
