.myaccount {
  &-wrapper {
    padding: 2rem 0 12rem;
  }

  &-content {
    padding: 0;
  }

  //* DASHBOARD --------------------------------------------------------------------
  &-dashboard {
    &__imgwrapper {
      padding-top: 2rem;
    }

    &__imgcard {
      img {
        aspect-ratio: 3/4;
        width: 100%;
        height: auto;
        &.lazyload,
        &.lazyloading {
          opacity: 1;
          @include animate-skeleton;
        }
      }
    }

    &__linkswrapper {
      padding: 12rem 0;
      row-gap: 12rem;

      &-link {
        .link {
          &-content {
            margin-bottom: 3.2rem;
          }
        }
      }
    }
  }

  //* SECTIONS --------------------------------------------------------------------
  &__section {
    &-infos {
      &--row {
        display: flex;
        justify-content: flex-start;
      }
    }
    //* SECTIONS - SINGLES --------------------------------------------------------------------
    //* ANCHOR - PERSONAL INFO  --------------------------------------------------------------------
    &#personalinfo {
      .edit-profile-form {
        .form-group--gender-radio {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      .edit-email-form {
        display: flex;
        flex-direction: column;
        gap: 4rem;
        margin-top: 6rem;
        .button-row {
          margin-top: 2rem;
        }
      }
    }

    //* ANCHOR - ADDRESSES --------------------------------------------------------------------
    &#addresses,
    &#payments {
      .accordion {
        &__header {
          height: 3.2rem;
        }

        &__panel > div {
          padding: 1.2rem 0;
        }
      }
      .button-row {
        &--edit {
          padding: 2rem 0 2.8rem;
          flex-direction: row;
          text-align: left;
        }
      }

      .checkout-shipping__address__address-book {
        &__edit,
        &__new {
          .new-item {
            &-title {
              padding: 4rem 0 2rem;
            }
          }
        }
      }
    }

    // !SECTION END SINGLES
  }
}
