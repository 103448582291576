.product-price {
  @extend %font__title;
  &-list {
    margin: 0;
    display: block;
    color: $interactive-light;
    @extend %font__strike-price;
  }

  span {
    display: block;
    text-align: right;
  }

  &.inline {
    span {
      text-align: left;
    }
  }

  &.inline,
  &.inline &-wrap {
    display: flex;
    column-gap: 0.8rem;
  }

  &.inline &-wrap {
    flex-wrap: wrap;
  }

  &-msg {
    font-weight: 400;
    display: block;
  }
}
