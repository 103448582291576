.register_form {
  &__actions {
    gap: 1.2rem;

    &--back,
    &--confirm {
      flex-grow: 1;
      width: 50%;
    }
    .button--tertiary {
      min-height: 2.4rem;
    }
  }
}
