.cs-content-block {
  &-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
  }

  .row {
    row-gap: 5.2rem;
  }
}
