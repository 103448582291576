.product-tile__wishlist.product-tile {
  .product-tile__info_content {
    position: relative;
  }

  .product-tile__info {
    height: auto;
  }

  .selector-color.intile {
    height: auto;
  }
}
