.accordion-mini__header {
  @extend %font__title;
  position: relative;
  text-decoration: none;
  color: $content-black;
  padding-right: 2.4rem;
  padding-top: $space-unit;
  padding-bottom: $space-unit;

  &::after {
    content: '';
    transform: translateY(-50%);
    position: absolute;
    right: 0;
    top: 1.2rem;
    @include fixedSprite('10-open');
    @include animate;
  }

  &[aria-expanded='false'] {
    & + .accordion-mini__panel {
      height: 0;
      visibility: hidden;
    }
  }

  &[aria-expanded='true'] {
    &::after {
      transform: translateY(-50%) rotate(45deg);
    }
  }
}

.accordion-mini__panel {
  overflow: hidden;
  visibility: visible;
  @include animate;
  > .form-group {
    margin-top: 0.8rem;
  }
  > .row {
    padding-top: ($space-unit * 2);
  }
  .button.button--primary {
    height: 3.6rem;
    min-height: initial;
  }
}
