.cs-steps {
  &_wrapper {
    overflow: hidden;

    .section__content {
      position: relative;
    }

    .selector-navigation {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.cs-step {
  &_group {
    display: flex;
    flex-direction: column;
    gap: 6rem;
  }
}
