%cart_flex_column {
  display: flex;
  flex-direction: column;
}

.minibag {
  &__empty {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 6rem;
  }
  &-modal__wrapper {
    ul {
      @include reset-list;
    }

    .dialog__title {
      margin: 0;
    }
    .modal__content {
      @extend %cart_flex_column;
      height: 100%;
      overflow: hidden;
    }
    .modal__header {
      flex-direction: row;
      justify-content: space-between;
      flex-grow: 0;
      min-height: $space-unit * 30;
      align-items: flex-end;
      padding-bottom: $space-unit * 6;
      margin-bottom: $space-unit * 6;
      border-bottom: solid 1px $outline;

      .dialog__description {
        @extend %font__title;
      }
    }
    .modal__body {
      padding-bottom: 0;
      flex-grow: 1;
      min-height: 0;

      .scrollable-inner {
        @extend %cart_flex_column;
        height: 100%;
        gap: $space-unit * 6;
      }
    }

    .container-minibag-up {
      flex-grow: 1;
      padding-right: 0.8rem;
      @include scrollbar;
      min-height: 20rem;
    }

    .container-minibag-middle,
    .container-minibag-down {
      flex-grow: 0;
    }

    .container-minibag-down {
      @extend %cart_flex_column;
      border-top: solid 1px $outline;
      padding: $space-unit * 5 0 $space-unit * 6;
      gap: $space-unit * 8;

      .minibag__total {
        @extend %font__title;
        display: flex;
        justify-content: space-between;
      }

      .minibag__buttons__wrapper {
        @extend %cart_flex_column;
        align-items: center;
        gap: $space-unit * 3;

        .paymentslogos-list {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.8rem;
          padding-top: 1.2rem;
          .inline-list {
            gap: 0.4rem;
          }
        }

        &.hide {
          display: none;
        }
      }

      .cta-gotobag {
        margin-top: $space-unit * 2;
      }
    }
  }

  &__expresspayment {
    transform: translateY(100%);
    visibility: hidden;
    opacity: 0;
    @include animate;

    &.open {
      transition-delay: 0.2s;
      transform: translateY(0);
      visibility: visible;
      opacity: 1;
    }

    &__intro {
      margin-bottom: 0.8rem;
      border-color: transparent;
      @extend %font__cta;

      &::before {
        content: '';
        @include fixedSprite('10-left-chevron');
      }
    }
  }
}
