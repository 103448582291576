.cart-container {
  padding-top: calc(#{var(--header-h)} + 6rem);

  .tabs {
    &-list {
      &--wrapper {
        background-color: white;
        position: sticky;
        top: 0;
        @include z-index('stickybar-m');
      }
    }
    // .react-tabs {
    //   &__tab {
    //     flex: 1;
    //     & > div {
    //       white-space: nowrap;
    //     }
    //   }
    //   &__tab-list {
    //     [role='tab'] {
    //       padding-right: 1.2rem;
    //     }
    //     [role='tab'] + [role='tab'] {
    //       margin-left: 1.2rem;
    //     }
    //   }
    // }
  }
}
