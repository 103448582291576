.fieldset--plain {
  margin-bottom: 4rem;
  .radio {
    display: flex;
    width: 100%;
    gap: 2rem;
    height: auto;
    @include checkoutSmallSpacing();
    position: relative;
    align-items: flex-start;
    cursor: pointer;
    flex-wrap: nowrap;

    > input[type='radio'] {
      margin-top: 0.4rem;
    }

    .inner-label {
      flex-grow: 1;
    }

    input:checked + .inner-label {
      &:after {
        background-color: $content-black;
      }
    }

    .radio__icon {
      & > [class^='icon--payment'] > div {
        display: none;
      }
    }

    .paymentmethods-cards {
      display: flex;
      flex-grow: 1;
      gap: 0.8rem;
      justify-content: flex-end;
      flex-wrap: wrap;
    }
  }
}

.wrapper-payment-methods {
  justify-content: space-between;
  button {
    @extend .button, .button--tertiary;
  }
}

.checkout-payment {
  .fieldset--plain {
    .radio {
      position: relative;
      padding: 0;
      @include border('bottom');
      align-items: baseline;
      @extend .font__cta;
      label {
        box-sizing: content-box;
        min-height: 4rem;
        width: 100%;
        padding: 1.6rem 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      input:checked + .inner-label::after {
        content: '';
        height: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-bottom: $content-black solid 0.1rem;
      }
    }
  }

  .gpay-button.short,
  .gpay-button.plain {
    width: 100%;
  }

  .billingAddressForm {
    > *:last-child {
      margin-bottom: 4rem;
    }
    .checkout-subtitle {
      margin: 0;
      @include checkoutSmallSpacing('bottom');
      @include checkoutSpacing('top');
    }
  }
}

.placeorderdisabled {
  > .adyen-checkout__paypal,
  > .adyen-checkout__paywithgoogle,
  > .adyen-checkout__amazonpay {
    position: relative;
    display: block;
    &::after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $content-white;
      opacity: 0.5;
      z-index: 101;
    }
  }
}

.checkout-payment {
  .fieldset--plain .form-group__helper-text {
    @include visually-hidden;
  }
}
