//spacings
$space-unit: 0.4rem;
$space-line: 1px;
$header-height-desktop: 5.6rem;
$header-height-mobile: 6rem;
$footer-clientservice-height-desktop: 31.6rem; // arbitrary height, could vary depending on content
$footer-clientservice-height-mobile: 57rem; // arbitrary height, could vary depending on content
$ticker-bar: 3.2rem;
$ticker-bar-m: 3.2rem;
$contextual-navigation: 4rem;
$contextual-navigation-m: 5rem;
$gradient-height: 12rem;
$spacing-multiplier: (0, 1, 2, 3, 4, 5, 6, 7, 8, 10, 12, 15, 16, 20, 24, 30, 32);
$refine-options-height: 3.6rem;

$checkout-vert-spacer: (
  xsmall: 3.2rem,
  small: 4rem,
  medium: 6rem,
  large: 10rem,
);
