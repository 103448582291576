.product-image {
  &-description {
    &__index {
      width: 30.7%;
    }
  }
}

.product-hero,
.product-hero--placeholder {
  padding-top: 4rem;
  // margin-bottom: 8rem;
}

.product-hero__col--sticky {
  padding-bottom: 4rem;
}
.product-hero__anchorscroll {
  --col-offset: 16;
  top: calc(var(--header-h) + 4rem);
  min-height: calc(var(--view-height) - var(--header-h) - 4rem - 0.1rem - 0.8rem);
}
