.checkout-packaging {
  .col-24 + .col-24 {
    padding-top: 0;
  }

  .col-24.col-lg-12:first-child {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // gap: 2rem;
  }

  // .checkout-subtitle {
  //   & + .checkout-packaging__intro {
  //     margin-top: 0;
  //   }
  // }

  &__gift {
    display: flex;
    flex: 1;
    align-items: flex-end;
    .checkbox {
      min-height: unset;
    }
  }

  &__options {
    display: flex;

    .optionbox {
      flex: 1;
      justify-content: center;
      &__wrapper {
        height: unset;
      }
    }
    .option-packaging__img {
      flex: 1;
    }
  }
}
