.cs-hero {
  .container {
    min-height: 40rem;
    margin-top: 28rem;
  }

  &-topics {
    flex-direction: row;
    align-items: baseline;
    margin-top: 1.6rem;
    gap: 0;
    > p {
      flex-grow: 0;
    }
    > ul {
      flex-direction: row;
      flex-grow: 1;
      justify-content: flex-end;
      gap: 2.4rem;
    }
  }
}
