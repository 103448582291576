.wishlist__intro {
  display: block;
  &--left {
    @include absolute-col(24);
  }
}

.wishlist-rail,
.wishlist-rail--2cols {
  .grid-container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
