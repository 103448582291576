.cs-faqs {
  &__accordiongroup {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    @include checkoutSmallSpacing('bottom');
    @include border('bottom');

    &.no-divider {
      padding-bottom: 0;
      border-bottom-width: 0;
    }

    .faq--card {
      .primary-s {
        @extend %font__cta;
        mark {
          @extend %font__title;
          background-color: transparent;
        }
      }
    }
  }
}
