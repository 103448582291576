.text-section {
  &__description {
    & + & {
      @include checkoutSpacing();
    }

    .order-number {
      @extend %font__title;
      margin-right: 1.2rem;
    }
  }

  & + & {
    @include checkoutSpacing();
  }

  &__description__line {
    display: block;
  }

  &__dl {
    display: grid;
    grid-template-columns: 1.25fr 0.75fr;
    column-gap: 0.6rem;
    row-gap: 0.6rem;

    dt,
    dd {
      display: block;
    }

    dd {
      .label {
        display: inline-block;
      }
    }

    &--justified {
      dd {
        text-align: right;
        justify-self: flex-end;
      }
    }
  }
}
