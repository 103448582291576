.modal {
  &--country-selector {
    .button {
      width: 100%;
    }

    .countryselector-modal__description + .countryselector-modal__description {
      padding: 4rem 0 0.8rem;
      margin-top: 4rem;
      @include border('top');
    }
  }
}
