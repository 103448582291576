//CONTENT -----------------------------------------------------------
$content-black: #000000;
$content-white: #ffffff;
$content-secondary: rgba(black, 0.55);

//OVERLAY -----------------------------------------------------------
$overlay-image: rgba(black, 0.55);
$overlay-modal: rgba(black, 0.4);
$outline: rgba(0, 0, 0, 0.1);

//DISABLED ----------------------------------------------------------
$disabled-light: #999999;
$disabled-dark: #666666;

//BACKGROUND --------------------------------------------------------
$background-product: #f1f1f1;

//INTERACTIVE -------------------------------------------------------
$interactive-light: #666666;
$interactive-dark: #999999;
$interactive-white: rgba(white, 0.4);
$interactive-black: rgba(black, 0.4);

//ALERT -------------------------------------------------------------
$alert-error: #db3339;
$alert-success: #167e33;
//BORDERS -------------------------------------------------------------
$border-light: #e5e5e5;
