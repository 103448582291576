.login-registration {
  padding-top: 10rem;
  padding-bottom: 16rem;
  .myaccoint__title {
    border-width: 0;
  }

  &__registration--static {
    margin-bottom: 16rem;
  }
}
