.component-details {
  padding: 8rem 0 12rem;

  &__images {
    row-gap: 1.2rem;
    margin-bottom: 8rem;

    img {
      width: 100%;
      aspect-ratio: 16/9;
      object-fit: cover;
    }
  }

  &__title {
    @extend %font__title;
    display: block;
    margin-bottom: 3.2rem;

    &.single {
      margin-bottom: 3.2;
    }
  }

  &__contents {
    &--content {
      .details__content {
        max-width: 40.4rem;
      }
    }
    &--accordion {
      .accordion__panel {
        & > div {
          max-width: 83.6rem;
        }
      }
    }
  }

  &--extended {
    padding: 8rem 0 12rem;

    .accordion {
      &__header {
        display: block;
      }
      &__panel > div {
        padding: 2.4rem 0 10rem;
      }
    }

    .component-details {
      &__title {
        margin-bottom: 8rem;
        &.single {
          margin-bottom: 3.2rem;
        }
      }
      &__images {
        margin-bottom: 2.4rem;
        &.single {
          margin-bottom: 8rem;
        }
      }
    }
  }
}

.page-template-archive {
  .component-details {
    background-color: $content-black;
    &__title,
    &__content {
      color: $content-white;
    }
    button {
      filter: invert(100%);
    }
  }
}
