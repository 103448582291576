.checkbox {
  // margin-bottom: ($space-unit * 3);
  break-inside: avoid-column;
  position: relative;
  flex-wrap: wrap;
  &--flex {
    display: flex;
  }

  .invalid-feedback {
    color: $alert-error;
    margin-top: 0.8rem;
    padding-left: $checkbox-size + 2.8rem;
  }

  input[type='checkbox'] {
    @include checkbox();
  }

  input[type='radio'] {
    @include radio();
  }

  input.button--checkbox {
    @include fakeCheckbox();
  }

  input.button--radio {
    @include fakeCheckbox('radio');
  }

  input[type='checkbox'],
  input[type='radio'] {
    top: 0.2rem;
    position: relative;
  }

  input[type='checkbox'],
  input[type='radio'],
  input.button--checkbox,
  input.button--radio {
    [data-whatintent='keyboard'] & {
      &:focus {
        outline-offset: 0.2rem;
      }
    }

    & + label,
    & + .form-group__label {
      display: inline-block;
      padding: 0 0 0 2.8rem;
      cursor: pointer;
    }
  }

  // container
  .checkbox__field {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
  }

  .invalid-feedback {
    width: 100%;
  }
}
