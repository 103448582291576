.tabs {
  .react-tabs__tab-list {
    list-style: none;
    position: relative;
    display: flex;
    @include reset-list();

    &--overflow {
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      [role='tab'] {
        white-space: nowrap;
      }
    }

    &:not(.no-divider) {
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        height: 0.1rem;
        background-color: $outline;
      }
    }

    [role='tab'] {
      @extend %font__title;
      color: $content-secondary;
      cursor: pointer;
      padding: $space-unit * 2 0.1rem;
      position: relative;
      display: flex;
      > div {
        position: relative;
      }

      & + [role='tab'] {
        margin-left: 4rem;
      }

      &:after {
        content: '';
        position: absolute;
        height: $space-line;
        background-color: transparent;
        bottom: 0;
        left: 0;
        right: 0;
        @include animate(0.3, 'background-color');
      }

      &[aria-selected='true'] {
        color: $content-black;
        &::after {
          content: '';
          background-color: $content-black;
        }
      }
    }
  }

  .client-service__tablist {
    [role='tab'] {
      & + [role='tab'] {
        margin-left: 1.2rem;
      }
    }
    & > li {
      flex-grow: 0;
      &[aria-selected='false'] {
        &::after {
          content: '';
          position: absolute;
          height: $space-line;
          background-color: transparent;
          bottom: 0;
        }
      }
    }
  }
  .client-service__tabpanel {
    min-height: unset;
    padding-top: 4rem;
    &--baa {
      min-height: unset;
    }
  }

  .react-tabs__tab--disabled {
    pointer-events: none;
    cursor: initial;
    opacity: 0.55;
  }

  .apex {
    display: inline-block;
    font-size: 1rem;
    line-height: 1.4rem;
    position: absolute;
    top: -6px;
    right: -2px;
    transform: translateX(100%);
    &.big {
      font-size: 1.2rem;
      line-height: 1.6rem;
      top: -4px;
    }
  }

  &-stilized-hide {
    .react-tabs__tab-panel {
      opacity: 0;
      position: absolute;
      z-index: -1;
      visibility: hidden;
      @include animate(0.4, 'opacity', $easeOutQuart, 0.1);
      &--selected {
        opacity: 1;
        z-index: 0;
        position: relative;
        visibility: visible;
      }
    }
  }
}
