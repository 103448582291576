// [data-whatinput='mouse'] {
//   .a11y-action-bar {
//     display: none;
//   }
// }

.a11y-action-bar {
  background-color: $content-black;
  color: $content-white;
  min-height: 3.2rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  @extend .visible-on-focus;

  &__menu {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    .container {
      text-align: right;
    }
  }

  .button-close {
    &:focus,
    &:focus-within {
      border: 1px solid $content-white;
    }
  }

  a,
  a:link,
  a:visited {
    @extend %font__cta;
    color: $content-white;
    @include link-focus-underline(true);
    margin-right: $space-unit * 4;
    * {
      color: $content-white !important;
    }
  }

  &:focus {
    outline: none;
  }
}

.account-dropdown {
  .a11y-action-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    // margin-left: $space-unit * -8;
    // margin-right: $space-unit * -8;
    padding-left: $space-unit * 8;
    z-index: 1;
  }
}

.account-dropdown-logged {
  .a11y-action-bar {
    position: relative;
    top: $space-unit * -8;
    left: 0;
    right: 0;
    margin-left: $space-unit * -8;
    margin-right: $space-unit * -8;
    padding-left: $space-unit * 8;
  }
}

.header-topbar > .a11y-action-bar {
  position: absolute;
  width: 100%;
  z-index: 1;
}
