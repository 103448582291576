.bag-summary {
  position: sticky;
  top: 6.4rem;

  .wrapper-sticky-desktop {
    border: 1px solid $outline;
    padding: 0 1.6rem 1.6rem;
    // margin-bottom: 2.4rem;
  }

  .items {
    &--totals {
      padding: 2.4rem 0;
    }
  }

  .paymentslogos-list {
    .inline-list {
      li {
        width: 3.2rem;
      }
    }
  }
}
