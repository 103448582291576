/* REMOVE ME ON AFTERCARE DEVELOPMENT*/
.cs-form-container {
  position: relative;
  // &::before {
  //   content: 'ths page is a simple Moncler porting wihtout any change';
  //   display: block;
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   background-color: red;
  //   font-weight: bold;
  //   border-radius: 4px;
  //   color: white;
  //   padding: 2px 4px;
  // }

  // .col-sm-10 {
  //   @extend .col-sm-10;
  // }
  // .col-md-8 {
  //   @extend .col-md-8;
  // }
  // .col-lg-6 {
  //   @extend .col-lg-12;
  // }

  // .optionbox__item__content,
  // .bag-product {
  //   flex-wrap: wrap;

  //   .bag-product__image {
  //     width: 30%;
  //     flex-grow: 0;
  //   }
  // }
}
