.footer {
  padding: $space-unit * 9 0;

  // * AREAS --------------------------------------------------------------------------
  &-area {
    &__logo {
      justify-content: flex-start;
      margin-top: 0;
      gap: 2.8rem;
      .footer-copyright {
        flex: 1;
        transform: translateY(0.4rem);
        margin-top: 0;
      }
    }
    &-bottom {
      align-items: flex-end;
      margin-top: 34.7rem;
    }
  }
  // * COLLECTIONS --------------------------------------------------------------------------
  &-collection {
    &__item {
      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }
    }
    &__list {
      padding-top: 0;
    }
    &--last {
      height: 100%;
      .footer-collection__list {
        height: 100%;

        display: flex;
        align-items: flex-end;
        gap: 4rem;
        padding-right: 4rem;
      }
      .footer-collection__item {
        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }
  }
}

// * MODALS --------------------------------------------------------------------
.newsletter-modal {
  &__radio-wrapper {
    padding-bottom: 3.2rem;
    .checkbox-wrapper {
      .checkbox {
        min-height: 2.4rem;
        &:not(:last-child) {
          margin-bottom: 0.8rem;
        }
      }
    }
  }
}
