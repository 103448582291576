.cs {
  &-page {
    &.hero-serach-active {
      display: none;
    }
  }
}

.faq-search {
  .button-delete {
    padding: 0 1.2rem;

    display: flex;
    align-items: center;
  }
  .button-search {
    // margin-left: -0.1rem;
    // border-width: 0.1rem;
    // border-left-width: 0;
    // border-style: solid;
    // background-color: white;
    padding: 0 1.2rem;
    display: flex;
    align-items: center;
  }
}
