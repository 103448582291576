.editorial-hero {
  > .container {
    padding-top: calc(12.4rem + $header-height-desktop);
    height: 53.2rem;
    padding-bottom: 8rem;
  }

  &__visual {
    margin-top: -2rem;
  }

  &__counters {
    margin-top: 0;
  }

  &__collection {
    text-align: right;
  }
}
