.corporate-locations {
  &__title {
    padding-bottom: 4rem;
  }
  &_panels {
    padding-top: 4rem;
  }

  &_panel {
    display: flex;
    flex-wrap: wrap;
    gap: 1.2rem;
    row-gap: 4rem;
  }

  ul {
    @include reset-list;
  }

  &_row {
    width: 100%;

    &.is-multiloc {
      &:not(:last-child) {
        @include border('bottom');
        padding-bottom: 4rem;
      }

      .locations {
        display: flex;
        flex-direction: column;
        gap: 4rem;
      }
    }

    h4 {
      margin-bottom: 2.8rem;
      @extend .font__subtitle;
    }
  }

  &_location {
    > * + *,
    .info > p + p {
      margin-top: 0.8rem;
    }
    .sublocation + * {
      margin-top: 2rem;
    }
  }
}
