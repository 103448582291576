.container-bag-summary {
  padding: 2.4rem;
  border: solid 1px $outline;
  position: relative;

  > .row {
    gap: 2.4rem;
  }

  .edit {
    @extend %font__cta--ul;
    display: block;
    margin: 2.4rem 0;
  }

  .wrapper-info-total {
    @include border('top');
  }
}

.checkout {
  &--summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1.2rem;

    &__block {
      display: flex;
      justify-content: space-between;
      flex-grow: 0;
      padding: 0;

      h2.items__label,
      p.items__value {
        @extend %font__title;
      }
    }
  }
}
