.in-dialog {
  &.login-modal {
    h2 {
      margin-bottom: 2rem;
    }

    .modal__header {
      margin-bottom: 4rem;
    }

    .login-modal {
      &-bottom {
        padding-top: 4rem;
      }
    }
    // .login-modal__registartion {
    //   margin-top: -4rem;
    // }

    &--successfulregisterd {
      .modal__header {
        display: none;
      }
      .login-modal__registartion--success {
        padding-top: 2rem;
      }
    }
  }
}
