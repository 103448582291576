.react-tabs__tab-panel--selected {
  min-height: 40rem;
}

.bag-overview {
  position: relative;
  text-align: left;
  overflow: hidden;

  &--empty {
    padding-top: 4rem;
    padding-bottom: ($space-unit * 30);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      text-align: center;
      margin-bottom: $space-unit * 4;
    }

    &__button-container {
      padding: 0 1.6rem;
    }
  }

  &__products {
    padding: 0 0 6rem 0;
  }

  &__sectiontitle {
    @include checkoutSmallSpacing('bottom');
  }
}

.bag-tabpanel__wrapper {
  padding-top: 5.2rem;
}

.bag-tabs {
  .tabs-list--wrapper {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 0.1rem;
      background-color: $outline;
    }
  }
}
