.heading-tabs-checkout {
  .react-tabs__tab {
    margin-left: 0;
  }
  .bag-summary {
    margin-top: 1.2rem;
  }
  .wrapper-tab-list-checkout {
    top: -9rem;
    .react-tabs__tab-list {
      &.show-summary-mobile {
        top: 0;
      }

      [role='tab'] {
        justify-content: flex-start;
      }
    }
  }
}
