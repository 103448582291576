.modal#notifyme {
  .dialog__title {
    margin-bottom: 4rem;
    @media (min-width: get($grid-breakpoints, md)) and (max-width: (get($grid-breakpoints, lg) - 1)) {
      margin-bottom: 6rem;
    }
  }

  .modal__divisor {
    @media (min-width: get($grid-breakpoints, md)) and (max-width: (get($grid-breakpoints, lg) - 1)) {
      margin: 6rem 0;
    }
  }

  @media (min-width: get($grid-breakpoints, lg)) {
    .modal__content {
      min-height: unset;
    }
  }

  form {
    .row {
      margin-bottom: 4rem;
      row-gap: 4rem;
      &--consent {
        row-gap: 2rem;
        .checkbox {
          min-height: unset;
          padding: 0.4rem 0 0.2rem;
        }
      }
      // @media (min-width: get($grid-breakpoints, md)) {
      //   row-gap: 2.4rem;
      // }
    }

    .fieldset--plain {
      margin: 0;
    }

    .form-group--gender-radio {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
      margin-top: 2rem;

      .radio {
        padding: 0;
        min-height: unset;
      }

      @include breakpoint(lg, min) {
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 6rem;
        row-gap: 1.2rem;
        .radio {
          width: auto;
        }
      }
    }

    .newsletter-modal__required-label {
      margin: 0;
    }

    [type='submit'] {
      width: 100%;
    }
  }
}
