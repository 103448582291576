.component-carousel-hero {
  width: 100%;
  position: relative;
  color: $content-white;
  aspect-ratio: 9/16;
  @include vh();
  padding-top: calc(var(--header-height) + 1.2rem);
  // overflow: hidden;
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-end;
  // padding-bottom: 11.8rem;
  &--wrapper {
    background-color: $content-black;
    & + div,
    & + section {
      position: relative;
      background-color: $content-white;
    }
  }

  .carousel-hero {
    &__slide {
      opacity: 0.3;
      display: flex;
      flex-direction: column;
      @include absolute-col(24);
      height: 100%;

      &-index {
        margin-bottom: 0.8rem;
      }

      &-title {
        margin-bottom: 3.2rem;
      }
      &-description {
        margin-bottom: 4.4rem;
      }

      &-links {
        flex: 1;
        display: flex;
        align-items: flex-end;
        gap: 2.8rem;
        flex-wrap: wrap;
        pointer-events: auto;
      }

      .button {
        &:focus-visible {
          outline-color: $content-white;
        }
      }

      &.active {
        opacity: 1;
      }
    }

    &__media {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      // height: var(--viewport-height);
      height: 100%;
      @include z-index('overlay-m');

      .video-container {
        height: 100%;
      }
      .th-video .th-video-player .th-video-poster {
        background-size: cover;
      }

      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--overlay-color);
        opacity: var(--overlay-transparency);
        @include z-index('overlay-m');
      }

      .btn--container {
        // display: flex;
        // justify-content: flex-end;
        position: sticky;
        bottom: 1.2rem;
        right: 0;
        // left: 50%;
        // transform: translateX(-50%);
        margin-top: -3.1rem;
        @include z-index('overlay');
        text-align: right;
      }
    }

    &__content {
      position: sticky;
      bottom: 0;
      left: 0;
      width: 100%;
      max-height: 100%;
      padding-bottom: 11.8rem;
      overflow: hidden;

      &--wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow: visible;
        display: flex;
        align-items: flex-end;
        @include z-index('overlay-m');
        pointer-events: none;
        padding-top: var(--header-h);

        // margin-top: calc(50vh - 0.7rem);
      }
    }

    &__controls {
      // position: relative;
      // z-index: 1;
      pointer-events: all;
      margin-top: 6rem;
      margin-bottom: 1.6rem;

      .button--pause {
        width: 2.4rem;
        height: 2.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .wrapper {
        height: 1.6rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 -0.8rem;
      }

      [data-whatintent='keyboard'] & .navigation button:focus-visible {
        @include focus-visible($content-black);
      }
    }
  }

  .swiper {
    @include absolute-col(24);
    margin: 0 auto;
  }
  .swiper-wrapper {
    // @extend %container;
    overflow-x: visible;
    pointer-events: all;
  }

  .pagination {
    display: flex;
    gap: 1.2rem;
    pointer-events: all;
    &--container {
      position: relative;
      @include z-index('overlay');
    }
    .swiper-pagination-bullet {
      flex: 1;
      height: 0.2rem;
      position: relative;
      display: flex;
      flex-direction: column;
      &:focus-visible {
        outline-color: $content-white;
      }
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-40%);
        left: 0;
        width: 100%;
        height: 4.4rem;
      }
      &::after {
        content: '';
        // position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        height: 0.1rem;
        background-color: rgba($content-white, 0.8);
      }

      .progress {
        display: block;
        height: 0.1rem;
        background-color: $content-white;
        width: 0;
        &.past {
          width: 100%;
        }
      }

      &:has(~ .swiper-pagination-bullet-active) {
        .progress {
          width: 100%;
        }
      }
    }

    &.paused {
      .swiper-pagination-bullet {
        .progress {
          animation-play-state: paused;
        }
      }
    }

    .swiper-pagination-bullet-active {
      .progress {
        animation-name: countingBar;
        animation-duration: var(--carousel-hero-autoplay);
        animation-timing-function: linear;
        animation-iteration-count: 1;
        animation-direction: alternate;
        animation-fill-mode: forwards;
        animation-play-state: running;
      }
    }
  }

  @include breakpoint(lg, min) {
    justify-content: flex-start;
    aspect-ratio: 16/9;

    .swiper-slide {
      @include absolute-col(23);
    }

    .carousel-hero {
      &__slide {
        @include absolute-row();

        &-index {
          flex: 1 0 auto;
          @include absolute-col(2);
        }
        &-content {
          flex: 1 0 auto;
          @include absolute-col(14);
        }

        &-description {
          @include absolute-col-size(max-width, 7);
          margin-bottom: 0;
        }

        &-links {
          align-items: flex-start;
          gap: 0.8rem;
          justify-content: space-between;
          @include absolute-col(6);
        }
      }

      &__content {
        height: calc(var(--view-height) / 2);
        padding-bottom: 0;
      }

      &__media {
        .btn--container {
          bottom: 1.4rem;
        }
      }

      &__controls {
        margin-top: 6rem;
      }
    }

    .pagination {
      // margin-top: 9.4rem;
    }

    .swiper {
      padding-top: 0.9rem; //fix centered title and accessibility overflow
    }
  }

  @include breakpoint(xxl, min) {
    .carousel-hero {
      &__slide {
        &-description {
          @include absolute-col-size(max-width, 6);
        }
      }

      &__controls {
        margin-top: 8rem;
      }
    }

    .swiper {
      overflow: hidden;
    }
  }
}

@keyframes countingBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
