.shopthelookmodule {
  .shopthelookmodule-closeup {
    .product-tile {
      margin-top: clamp(8rem, 100vh - 87rem, 15.2rem);
      .product-tile__info_content {
        position: absolute;
        padding-bottom: 6.2rem;
      }
      .product-tile__price {
        margin-top: 2.2rem;
      }
    }
  }
}
