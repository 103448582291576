.faq {
  &-dropdown {
    &__content {
      display: grid;
      grid-template-columns: repeat(14, 1fr);
      justify-items: stretch;
      justify-content: stretch;
      align-items: flex-end;
      column-gap: 1.2rem;
    }

    &__select-container {
      grid-column: 1 / 11;
    }

    &__cta {
      display: block;
      grid-column: 11 / 15;
    }
  }
}
