.checkout-pickup {
  .one-to-pickup-paragraph {
    @include checkoutSpacing();
    @include checkoutSpacing('bottom');
  }
  .optionbox--horizontal-two-items {
    @include checkoutSpacing();
    max-height: 436px;
    @include scrollbar;
  }

  .checkout-pickup__map {
    @include checkoutSpacing('bottom', true);
  }
  .option-store {
    padding-left: 0;
    width: 100%;
    .clothing-office__title {
      margin-left: 0;
    }
  }
  .store-details {
    @include checkoutSmallSpacing();
    .email {
      margin-bottom: 0.8rem;
    }
    .store-details__hours {
      width: 100%;
      @include checkoutSmallSpacing();
    }
  }
  .globalerrors {
    &.addressban {
      padding-top: 0;
    }
  }

  ul {
    @include checkoutSmallSpacing();
    @include checkoutSmallSpacing('bottom');
  }

  p.mandatory-fields {
    padding-bottom: 2rem;
  }
}
