.product {
  &-content {
    position: relative;
    z-index: 1;
    background-color: $content-white;

    @include breakpoint(lg, min) {
      margin-top: 12rem;
    }
  }

  &-stories {
    position: relative;
    z-index: 1;
  }
}

.breadcrumb {
  padding: 1.2rem 0;
  &__item {
    @extend %font__cta;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    margin: 0.2rem 0;
    &::after {
      @include fixedSprite('10-right-chevron');
      display: inline-block;
      transform: rotate(-180deg);
    }
  }

  ol {
    overflow-x: auto;
    overflow-y: visible;
    @extend .container;
    list-style: none;
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
    > li {
      flex-shrink: 0;
      list-style-position: outside;
      padding: 0;
      display: flex;

      &:not(:last-child) {
        .breadcrumb__item::after {
          content: '';
        }
      }
    }
  }
}
