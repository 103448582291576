// SIZE SELECTOR USED IN: PDP, PLP Tile, Reserve in boutique, Shop by Look

.selector-size-expanded {
  &.inpdp {
    margin-bottom: 8rem;
    .selector__header {
      padding: 0.3rem 0;
      margin-bottom: 0.7rem;
    }
  }
}
