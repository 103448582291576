.product-tile {
  position: relative;

  &__image-wrapper {
    position: relative;
    background-color: $background-product;
  }

  &__image-toolbar,
  .product-labels {
    pointer-events: none;
    padding: 1.6rem;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    top: 0;
    z-index: 1;

    .product-tag-wrapper {
      padding-left: 0.4rem;
      flex-grow: 1;
      text-transform: uppercase;
    }
  }

  &__seoname {
    @extend %font__labelling;
    margin-top: 0.4rem;
    height: 3.6rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @supports (-webkit-line-clamp: 2) {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
  }

  &__name,
  &__price {
    @extend %font__title;
    margin-top: 1.6rem;
    display: block;
  }

  &__name {
    height: 3.6rem;
    a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @supports (-webkit-line-clamp: 2) {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
      }
    }
  }

  &__hr {
    margin: 2.4rem 0 0;
    width: 1rem;
    height: 0;
    border: solid 0 #000;
    border-top-width: 0.1rem;
  }

  &__color-link {
    margin-top: 1.6rem;
  }

  &_quick-buy-wrp {
    display: flex;
    flex-direction: column;
    margin-top: 1.6rem;
  }

  &__quick-buy {
    margin-top: 3.1rem;
    display: flex;
    flex-direction: column-reverse;
    justify-content: left;
    gap: 1.2rem;
  }

  &__link {
    text-decoration: none;
    &--button {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .overvisual {
    position: absolute;
    width: 100%;
    z-index: 1;
    .product-image {
      background-color: transparent;
    }
  }

  // &__info_base {
  //   @extend %font__subtitle;
  // }

  &__avb_colours {
    @extend %font__subtitle;
    margin-top: 1.2rem;
  }

  // SECTION - TYPINGS ------------------------------------------------------------------
  // ANCHOR - REVIEW --------------------------------------------------------------------
  &-review {
    display: flex;
    justify-content: space-between;
    gap: 4rem;
    width: 100%;

    &__content {
      display: grid;
      grid-template-columns: 10.4rem minmax(0, 1fr);
      gap: 1.2rem;
      width: 100%;
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      z-index: 2;

      &-pricetitle {
        display: flex;
        justify-content: space-between;
      }

      &-title {
        @extend %font__title;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-grow: 1;
      }
      &-specs {
        p {
          display: flex;
          column-gap: 0.6rem;
          flex-wrap: wrap;
        }

        .selector-size-expanded.inpdp {
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }

      &-label {
        @extend %font__title;
      }
      &-value {
        &.size {
          @extend %font__cta;
          color: $interactive-light;
          margin-left: 0.4rem;
        }
      }
    }
    &__price {
      @extend %font__title;
      flex-shrink: 0;
    }
  }

  // SECTION - TYPINGS ------------------------------------------------------------------
  // ANCHOR - EXPANDED STATIC -----------------------------------------------------------
  &--static {
    .selector-wrapper {
      position: relative;
    }

    .selector-navigation {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  // ANCHOR - ORDER --------------------------------------------------------------------
  &-order {
    &__content {
      display: flex;
      gap: 1.2rem;
      position: relative;

      .product-image {
        width: 10.4rem;
      }
    }

    &__listing {
      display: flex;
      flex-direction: column;
      .product-tile {
        &:not(:last-child) {
          padding-bottom: 3.2rem;
          border-bottom: solid 0.1rem $outline;
        }

        &:not(:first-child) {
          padding-top: 3.2rem;
        }
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      &-title {
        display: flex;
        justify-content: space-between;
        h2,
        h3 {
          @extend %font__title;
          max-width: 11rem;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      &-label {
        @extend %font__title;
      }

      &-attributes {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        flex: 1;
      }
    }

    .returned-flag {
      display: flex;
      align-items: center;
      margin-left: 11.6rem;
      margin-top: 3.2rem;
      transform: translateX(-0.7rem);
    }
  }

  // ANCHOR - SIMPLE --------------------------------------------------------------------
  &--SIMPLE {
    height: 100%;
    display: flex;
    flex-direction: column;

    .product-tile {
      &__name {
        height: unset;
      }

      &__info {
        flex: 1;
        display: flex;
        flex-direction: column;

        &_content {
          flex: 1;
          display: flex;
          flex-direction: column;
        }

        &_base {
          flex: 1;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          .product-cta {
            flex-shrink: 0;
          }
        }
      }

      .produt-price {
        @extend %font__cta;
      }
    }
  }

  //!SECTION END TYPINGS

  &__wishlist-actions {
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
    z-index: 1;
  }

  .selector-size-expanded {
    padding: 2rem 0 2rem;
    @include touchtarget-area(100%);
    &:focus-visible {
      margin-top: 0.1rem;
      @include touchtarget-focus();
    }
  }

  .errormessage {
    @extend %font__title;
  }

  &__quite {
    padding-top: 3.2rem;
  }

  &__image-video {
    @include fixedRatio;
    overflow: hidden;
    video {
      width: 100%;
    }
  }

  .selector-navigation {
    gap: 0;
  }
}

@mixin fullExpandedTile {
  &__info {
    height: 25.2rem;
    position: relative;

    &_content {
      background-color: $content-white;
      min-height: 100%;
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      z-index: 1;
    }
  }

  &__name {
    height: 3.2rem;
  }

  &-order {
    &__info {
      &-title {
        h3 {
          max-width: unset;
        }
        h2 {
          max-width: 16.2rem;
        }
      }

      &-specs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        gap: 1.2rem;
      }
    }

    .returned-flag {
      margin-top: 0;
      margin-left: 0;
      transform: translateX(0);
    }
  }

  &-review {
    gap: 4rem;

    &__content.image--large {
      grid-template-columns: 22.5rem minmax(0, 1fr);
    }

    &__info {
      &-specs {
        gap: 1.4rem;

        //COLOR SERLECTOR
        .selector {
          &-wrapper {
            display: flex;
            align-items: flex-end;
            gap: 0.6rem;
          }
          &-navigation-chevrons {
            gap: 1.4rem;
          }

          &-button {
            height: 3.2rem;
            justify-content: center;
          }
        }
      }
    }
  }

  &--static {
    .selector-navigation {
      position: relative;
      top: unset;
      right: unset;
    }
  }

  &__quick-buy {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }

  // ANCHOR - SIMPLE --------------------------------------------------------------------
  &--SIMPLE {
    .product-tile {
      &__name {
        margin-top: 0;
        a {
          -webkit-line-clamp: 1;
        }
      }

      &__info {
        margin-top: 1.2rem;
        height: unset;
        &_content {
          position: relative;
        }
      }
    }
  }
}

.fullExpanded.product-tile {
  @include fullExpandedTile;

  // RESERVE IN BOUTIQUE VERSION

  &--reserve {
    .product-tile__name {
      height: auto;
    }
    .product-tile_quick-buy-wrp {
      margin-top: 2.4rem;
    }
    .product-tile__price {
      margin-top: 0;
      margin-bottom: 2.4rem;
    }

    .product-tile .selector-size-expanded {
      padding: 0 2.4rem;
    }

    .selector__menu-labels,
    .selector__header {
      padding-bottom: 1.6rem;
    }
  }
}

.page-template-archive {
  .productTiles-rail {
    .rail-title {
      color: white;
    }
    .rail-links,
    .rail-navigation {
      button,
      a {
        filter: invert(100%);
      }
    }
    .tile--content {
      color: white;
      .icon {
        filter: invert(100%);
      }
    }
  }
}
