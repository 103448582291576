.autocomplete {
  background-color: $content-white;
  position: absolute;
  z-index: 100;
  width: 100%;
  top: 100%;
  @include border();
  ul {
    padding: 0;
    list-style-type: none;
    li {
      min-height: 3.6rem;
      @include border('top');
      display: flex;
      align-items: center;
      padding: 0 ($space-unit * 2);

      &:first-child {
        border-top: initial;
      }

      &.focused {
        background-color: #ebf2fe;
        @include focus-visible();
        z-index: 1;
      }

      &:hover {
        background-color: #fafafa;
      }
    }
  }
  .attribution {
    padding: $space-unit;
    display: flex;
    justify-content: flex-end;
    img {
      height: 18px;
      width: 144px;
    }
  }
}

.address-search {
  position: relative;
}
