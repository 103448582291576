.myaccount {
  // SECTION - SECTIONS --------------------------------------------------------------------
  &__section {
    // ANCHOR - CHENGE PASSWORD --------------------------------------------------------------------
    &#changepassword {
      .button-row {
        text-align: center;
        row-gap: 2rem;
      }
    }

    &#socialaccounts {
      .social-row {
        display: flex;
        justify-content: space-between;

        .is-connect {
          display: flex;
          align-items: center;
          gap: 0.4rem;
        }

        .connected-tick {
          @include fixedSprite('10-correct');
        }

        p {
          @extend %font__subtitle;
        }

        &:not(:last-child) {
          margin-bottom: 1.2rem;
        }
      }
    }

    &#privacysettings {
      form {
        display: flex;
        flex-direction: column;
        gap: 4rem;
        padding-top: 4rem;
        .checkbox {
          height: auto;
        }
      }
    }

    &#marketingpreferences {
      .preferences-marketing,
      .radio-wrapper form {
        display: flex;
        flex-direction: column;
        gap: 3.2rem;
      }
    }
  }
  // !SECTION - END SECTIONS
}
