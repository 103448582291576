.in-dialog {
  &.login-modal {
    .register_form__actions {
      flex-direction: column;
      .register_form__actions--back,
      .register_form__actions--confirm {
        width: 100%;
      }
    }
  }
}
