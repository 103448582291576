.errorfallback {
  margin: 2rem 0;
  pre {
    margin-top: 2rem;
    margin-bottom: 2rem;
    white-space: pre-wrap;
  }
}

.container-404,
.container-error {
  margin-top: $space-unit * 30;
  text-align: center;
  .title {
    @extend %font__subtitle;
    margin-bottom: ($space-unit * 6);
  }
  .text-wrapper {
    margin-bottom: ($space-unit * 8);
  }
  .button {
    width: auto;
    margin-bottom: $space-unit * 30;
  }
}

.courtesy-page {
  .container-404 {
    margin-top: 4rem;
    margin-bottom: 5.6rem;
    @include appearAnimation();
    .title {
      margin-bottom: 1.2rem;
    }
    .text-wrapper {
      margin-bottom: 0;
    }
  }
}

.container-error {
  margin-top: $space-unit * 20;
  .logo-header img {
    max-width: $space-unit * 30;
    margin: 0 auto $space-unit * 8 auto;
  }
  .code {
    margin-bottom: ($space-unit * 8);
  }
}

// questo serve, non cancellarlo. E' lo stile per gli errori globali mostrato in pagina in caso di errori lato server
.invalid-feedback {
  // invalid-feedback is used also for field errors
  &.globalerrors {
    // global/generic submission errors
    margin-top: ($space-unit * 4); // non rimuovere
    margin-bottom: ($space-unit * 4); // non rimuovere
    text-align: left; // non rimuovere
    position: relative;
    // display: block;
  }
  &.centered {
    justify-content: center;
  }
}
body.checkout {
  .invalid-feedback {
    &.globalerrors {
      text-align: center;
    }
  }
}
