.aftercare {
  &__form {
    &-btn-container {
      margin-top: 6rem;
      row-gap: 2rem;
      align-items: center;
    }

    &__step-description {
      margin: 4rem 0 2rem;
    }

    &-images-upload {
      .button--fileupload:not(:first-child) {
        margin-top: 2rem;
      }
    }

    &__button-link {
      margin-top: 4rem;
      .accordion-mini__header {
        &::after {
          @include fixedSprite('10-down-chevron');
        }
        &[aria-expanded='true']::after {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    .kr-name-field {
      margin-top: 4rem;
    }

    .form-group {
      .radio {
        padding: 0;
      }

      &__helper-text {
        display: flex;
        color: $interactive-light;
        text-decoration: none;
        gap: 0.2rem;
        &::before {
          content: '';
          width: 2.4rem;
          height: 2.4rem;
          @include fixedSprite('10-alert');
          color: $alert-error;
          transform: translate(-0.4rem, -0.4rem);
        }
      }
    }

    .orders-listing--cs {
      margin-top: 4rem;
      .items-container {
        margin-top: 0;
        padding-top: 2rem;
      }
      .accordion__item {
        margin-bottom: 0;
      }
      .optionbox {
        padding-bottom: 0.1rem;
      }
      .optionbox__item__radio {
        border: none;
      }
    }
  }

  &-confirm__row {
    padding-bottom: 2rem;
    border-bottom: 0.1rem solid $outline;
    row-gap: 2rem;
    &:not(:first-child) {
      padding-top: 4rem;
    }

    &:first-child {
      @include breakpoint(lg, max) {
        padding-bottom: 4rem;
      }
    }

    .aftercare__title {
      margin-bottom: 2rem;
    }
  }
  &-confirm__btn-row {
    margin-top: 5.2rem;
    @include breakpoint(lg, min) {
      margin-top: 4rem;
    }
  }

  .step {
    &__panel {
      margin-top: 4rem;
    }
  }
}
