.orders {
  &-listing {
    &__order {
      &-section {
        padding: 6rem 0;

        &:last-child {
          padding-bottom: 2rem;

          .order-detail-page & {
            padding-bottom: 6rem;
          }
        }

        //ANCHOR - SINGLES --------------------------------------------------------------------
        &.order {
          &-infos {
            row-gap: 3.2rem;
          }
        }
      }
    }
  }
}
