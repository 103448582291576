.editorial-hero {
  > .container {
    padding-top: calc(19.6rem + $header-height-desktop);
    height: 72rem;
    padding-bottom: 8rem;
  }
  &__visual {
    margin-top: -2.4rem;
  }
}
