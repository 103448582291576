.cs-contactus {
  &-form {
    [class^='row'] + [class^='row'],
    [class^='row'] + .form-group,
    .form-group + [class^='row'] {
      @include checkoutSmallSpacing;
    }
    .row.phonewithprefix-label,
    .row.phonewithprefix-fields {
      padding-top: 0;
    }

    @include breakpoint(lg) {
      .col-lg-6.col-xxl-8 {
        @include checkoutSmallSpacing('bottom');
      }
    }

    .button--fileupload {
      @include border();
    }
  }

  &__desc {
    @include checkoutSmallSpacing('bottom');
  }

  &__submit-registration {
    .register_form {
      .login-register-social {
        padding-top: 2rem;
      }
    }
  }
}
