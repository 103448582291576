.holidaycalendar.modal {
  .holidaycalendar__body {
    ul {
      @include reset-list;
      @include border('top');

      padding-top: 4rem;
      margin-top: 4rem;
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
      li {
        display: flex;
        justify-content: space-between;

        > span {
          width: 66%;
        }
      }
    }
  }
}
