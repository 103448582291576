.button {
  &[class*='primary'],
  &[class*='secondary'] {
    min-height: $button-height-desktop;
    padding: $button-padding-desktop;
  }

  // ICON ------------------------------------------------------------
  &--icon,
  &--icon:link,
  &--icon:visited {
    min-width: $button-height-desktop;
    min-height: $button-height-desktop;
  }
}
