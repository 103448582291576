body[class] {
  &.no-overflow {
    header {
      transition-duration: 0s;
      background-color: $content-white;
    }
  }
}

//* ANCHOR - TITLE --------------------------------------------------------------------
.editorial-page-title {
  height: 17.6rem;
  padding-bottom: 5.8rem;
}

.hero {
  &-image {
    height: 50rem;
    padding-top: 24.2rem;
  }
}
