.myaccount {
  // DASHBOARD - WELCOME --------------------------------------------------------------------

  &-dashboard__welcome {
    margin-top: 10rem;
    margin-bottom: 6rem;

    .welcome_text {
      padding-top: 0;
    }

    .button {
      max-width: 30rem;
    }
  }

  // SECTION - SECTIONS --------------------------------------------------------------------
  &__section {
    // ANCHOR - CHENGE PASSWORD --------------------------------------------------------------------
    &#changepassword {
      .button-row {
        text-align: left;
      }
    }
  }
  // !SECTION - END SECTIONS
}
