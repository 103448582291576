@use 'sass:math';
@import './vars/';

@mixin lhCrop($top-crop: 0.3rem, $bottom-crop: 0.3rem) {
  &::before,
  &::after {
    content: '';
    display: block;
    height: 0;
    width: 0;
  }
  &::before {
    margin-bottom: $top-crop;
  }
  &::after {
    margin-top: $bottom-crop;
  }
}

// Media Queries
@mixin breakpoint($point, $type: max) {
  $width: get($grid-breakpoints, $point);
  @if $point == xxl {
    $type: min;
  }
  @if $type == max {
    $width: $width - 1px;
  }
  @media only screen and (#{$type}-width: $width) {
    @content;
  }
}

// Arrow mixin
@mixin arrow($direction: down, $size: 0.5rem, $color: #555) {
  width: 0;
  height: 0;
  @if ($direction == left) {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
  } @else if ($direction == right) {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  } @else if ($direction == down) {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
  } @else {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  }
}

// Trim text
@mixin text-shorten {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Define vertical, horizontal, or both position
@mixin center($position: '') {
  position: absolute;

  @if $position == 'vertical' {
    top: 50%;
    transform: translateY(-50%);
  } @else if $position == 'horizontal' {
    left: 50%;
    transform: translateX(-50%);
  } @else {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@mixin animate($time: 0.5, $props: 'all', $easing: $easeOutQuart, $delay: 0, $important: '') {
  @if $props == 'all' {
    transition: all #{$time}s #{$delay}s $easing #{$important};
  } @else {
    transition: #{$props} #{$time}s #{$delay}s #{$easing} #{$important};
  }
}

@mixin hover($time: 0.5) {
  @include animate($time);
  &:hover {
    opacity: $hover-opacity;
  }
}

@mixin appearAnimation($duration: 0.4, $delay: 0) {
  // opacity: 0;
  // &.reveal-animations {
  //   @include animate($duration, 'all', $easeOutQuart, $delay, '!important');
  //   opacity: 1;
  // }
}

@mixin labelIcon($name, $type: left) {
  content: '';
  position: absolute;
  top: 0.1rem; // non in percentuale per gestire il caso in cui c'è il messaggio di errore sotto
  transform: translateY(50%);
  @if $type == 'right' {
    right: $space-unit * 2;
  } @else {
    left: $space-unit * 2;
  }
  display: block;
  height: $space-unit * 3;
  width: $space-unit * 3;
  @include sprite($name);
  background-size: 100%;
}

// setting defaults values to current images ratio
@mixin fixedRatio() {
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-top: $product-ratio;
  }
  > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, $number * 0 + 1);
  }

  @return $number;
}

@mixin fixedSprite($name, $color: transparent, $show: true) {
  $sprite: map-get($sprites, $name);
  background: #{$color} url('#{$sprite}') center no-repeat;

  @if not $sprite {
    content: 'MISSING SPRITE: #{$name} in #{&}';
  }

  @if map-has-key($sizes, $name) {
    $size: map-get($sizes, $name);
    width: (strip-unit(map-get($size, width)) * 0.1) * 1rem;
    height: (strip-unit(map-get($size, height)) * 0.1) * 1rem;
  }
  @if ($show) {
    display: inline-block;
  }
}

@mixin z($name: default) {
  z-index: get($z-layers, $name);
}

@mixin visually-hidden {
  position: absolute !important;
  height: 0.1rem;
  width: 0.1rem;
  overflow: hidden;
  clip: rect(0.1rem 0.1rem 0.1rem 0.1rem);
  white-space: nowrap;
}

@mixin reset-visually-hidden {
  position: relative !important;
  height: initial;
  width: initial;
  overflow: initial;
  clip: initial;
  white-space: initial;
}

@mixin reset-list {
  list-style: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  > li {
    list-style-position: outside;
    padding-top: 0;
    padding-bottom: 0;
  }
}

@mixin fix-font-space($s: 0.2rem) {
  // margin-top: (-$s);
}

@mixin del-link {
  text-decoration: none;
  color: inherit;
}

// @mixin gray-gradient {
//   background: $gradient-separator;
//   background-size: 100% $gradient-height;
//   background-repeat: no-repeat;
// }

// @mixin gray-gradient-pseudo($type: before) {
//   &::#{$type} {
//     content: '';
//     height: $gradient-height;
//     background: $gradient-separator;
//     width: 100%;
//     position: absolute;
//     top: 0;
//     left: 0;
//     @include z(neg);
//   }
// }

@mixin scrollbar {
  overflow: auto;
  // scrollbar-width: none; /* Firefox */
  // -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0.3rem;
    height: 0.3rem;
  }

  &::-webkit-scrollbar-track {
    background: linear-gradient(90deg, transparent 25%, $interactive-light 50%, transparent 75%);
  }

  &::-webkit-scrollbar-thumb {
    background: $content-black;
  }
}

@mixin hiddenScrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
}

@mixin hoverablelink {
  text-decoration: none;
  &:focus,
  &:hover {
    text-decoration: underline;
  }
}

// mixin for interactions

@mixin focus-underline {
  [data-whatintent='keyboard'] & {
    outline: transparent;
    text-decoration: underline;
    -webkit-text-underline-position: under;
    text-underline-position: under;
  }
}

//Previous one is glitchy on chrome in long list ( ie. footer )
@mixin focus-underline-alt {
  [data-whatintent='keyboard'] & {
    position: relative;
    outline: transparent;
    &:before {
      content: '';
      position: absolute;
      bottom: -0.3rem;
      width: 100%;
      height: 1px;
      background-color: $content-black;
    }
  }
}

//remove foucs
@mixin clear-focus-visible($outlinecolor: $content-black) {
  [data-whatintent='keyboard'] &:focus-visible {
    outline: solid 0 transparent;
    outline-offset: 0;
  }
}

//focus visible
@mixin focus-visible($outlinecolor: $content-black) {
  outline: solid 0.2rem $outlinecolor;
  outline-offset: 0.1rem;
}

//focusable area
@mixin default-focus($outlinecolor: $content-black) {
  outline: transparent 0;
  @include animate(0.2, 'outline');
  &:focus-visible {
    @include focus-visible($outlinecolor);
  }
}

//focusable area
@mixin apply-focusablearea($size: $target-size-desktop, $outlinecolor: $outline-color) {
  @include touchtarget-area($size);

  &:focus-visible {
    @include touchtarget-focus($outlinecolor, true);
  }
}

// default touch area
@mixin touchtarget-area($size: $target-size-desktop) {
  position: relative;

  &::before {
    content: '';
    @include center();
    height: $size;
    width: $size;
  }
}

// on focus
@mixin touchtarget-focus($color: $content-black, $useOffset: false) {
  [data-whatintent='keyboard'] & {
    outline: none;

    &::before {
      outline: 0.2rem solid $color;
      @if $useOffset {
        outline-offset: 0.2rem;
      }
    }
  }
}
@mixin link-focus-underline($crop: false) {
  position: relative;
  text-decoration: none;

  &::after {
    @include animate;
    content: '';
    height: 1px;
    width: 100%;
    background: $content-white;
    position: absolute;
    left: 0;
    opacity: 0;

    @if $crop {
      bottom: -0.2rem;
    } @else {
      bottom: 0;
    }
  }

  &:focus {
    outline: none;

    &::after {
      opacity: 1;
    }
  }
}

@mixin border($where: '') {
  @if ($where == 'top') {
    border-top: $space-line solid $outline;
  } @else if ($where == 'bottom') {
    border-bottom: $space-line solid $outline;
  } @else {
    border: $space-line solid $outline;
  }
}

@mixin fakeCheckbox($type: 'checkbox') {
  color: $content-black;
  margin: 0 -0.6rem 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 0;
  border-width: 0;
  background-color: initial;

  @include fixedSprite('10-' + $type + '-empty');
  &[aria-pressed='true'] {
    @include fixedSprite('10-' + $type + '-filled');
  }
}

@mixin checkbox {
  color: $content-black;
  width: $checkbox-size;
  height: $checkbox-size;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 0;
  // to avoid user agent IOS styling issue
  background-color: initial;
  &:disabled {
    cursor: initial;
    & + label {
      color: $disabled-light;
      cursor: initial;
    }

    &::after {
      opacity: 0.3;
    }
  }

  &::after {
    @include fixedSprite('10-checkbox-empty');
    position: relative;
    display: block;
    height: 2.4rem;
    width: 2.4rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    content: '';
  }

  &:checked::after {
    @include fixedSprite('10-checkbox-filled');
    display: block;
  }
}

@mixin radio {
  color: $content-black;
  width: $radio-size;
  height: $radio-size;
  border-radius: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-width: 0;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 0;
  // to avoid user agent IOS styling issue
  background-color: initial;

  &:disabled {
    cursor: initial;
    & + label {
      color: $disabled-light;
      cursor: initial;
    }

    &::after {
      opacity: 0.3;
    }
  }

  &::after {
    @include fixedSprite('10-radio-empty');
    position: relative;
    display: block;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    content: '';
  }

  &:checked::after,
  &[aria-pressed='true']:after {
    @include fixedSprite('10-radio-filled');
    display: block;
  }
}

@mixin skeleton-text {
  position: relative;
  color: transparent;
  &::before {
    content: '';
    position: absolute;
    width: 50%;
    min-width: 240px;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
    @include animate-skeleton();
    z-index: 1;
  }
}

@mixin animate-skeleton {
  animation: skeleton 0.7s ease-out infinite alternate;
}

@keyframes skeleton {
  from {
    background: #c2c2c2;
  }
  to {
    background: #f3f3f3;
  }
}

@mixin z-index($val) {
  z-index: get($z-layers, $val);
}

@mixin vh($css: min-height, $percentage: 100, $control: 1svh) {
  #{$css}: $percentage * 1vh;
  // #{$css}: calc($percentage * #{var(--viewport-height)});

  @supports (height: #{$control}) {
    #{$css}: $percentage * $control;
  }
}

@mixin vh-calc($css: min-height, $percentage: 100, $control: 1svh, $operator: '-', $calcVal: 1) {
  #{$css}: calc(#{$percentage} * 1vh #{$operator}#{$calcVal});
  // #{$css}: calc($percentage * #{var(--viewport-height)});

  @supports (height: #{$control}) {
    #{$css}: calc(#{$percentage} * #{$control} #{$operator} #{$calcVal});
  }
}

@mixin formControlInput() {
  @extend %font-regular;
  width: 100%;
  font-size: 1.4rem;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: $input-height-mobile;
  @media (min-width: get($grid-breakpoints, md)) {
    height: $input-height-desktop;
  }

  color: $content-black;
  padding: 0.9rem 0 0.7rem;
  background-color: transparent;
  border: none;
  border-bottom: 0.1rem solid $interactive-dark;

  &.blockview {
    border: 0.1rem solid $interactive-dark;
    & ~ button {
      border-color: $interactive-dark;
    }
  }

  & ~ .show-password,
  & ~ button {
    border-bottom: 0.1rem solid $interactive-dark;
  }

  &::placeholder {
    color: $interactive-light;
  }

  &[aria-invalid='true'],
  &[aria-invalid='true'] ~ .show-password {
    border-bottom-color: $alert-error;
    &.blockview {
      border-color: $alert-error;
      & ~ button {
        border-color: $alert-error;
      }
    }
  }

  &:focus,
  &:focus ~ .show-password {
    border-bottom-color: $content-black;
    &.blockview {
      border-color: $content-black;
      & ~ button {
        border-color: $content-black;
      }
    }
  }

  &:disabled {
    color: $disabled-dark;
  }
}

@mixin checkoutLargeSpacing($position: 'top', $useMargin: false) {
  @if ($useMargin) {
    margin-#{$position}: map-get($checkout-vert-spacer, 'large');
  } @else {
    padding-#{$position}: map-get($checkout-vert-spacer, 'large');
  }
}

@mixin checkoutSmallSpacing($position: 'top', $useMargin: false) {
  @if ($useMargin) {
    margin-#{$position}: map-get($checkout-vert-spacer, 'small');
  } @else {
    padding-#{$position}: map-get($checkout-vert-spacer, 'small');
  }
}

@mixin checkoutXSmallSpacing($position: 'top', $useMargin: false) {
  @if ($useMargin) {
    margin-#{$position}: map-get($checkout-vert-spacer, 'xsmall');
  } @else {
    padding-#{$position}: map-get($checkout-vert-spacer, 'xsmall');
  }
}

@mixin checkoutSpacing($position: 'top', $useMargin: false) {
  @if ($useMargin) {
    margin-#{$position}: map-get($checkout-vert-spacer, 'medium');
  } @else {
    padding-#{$position}: map-get($checkout-vert-spacer, 'medium');
  }
}

@mixin getCheckoutSpacing($size, $position, $useMargin) {
  @if ($size == 'large') {
    @include checkoutLargeSpacing($position, $useMargin);
  } @else if ($size == 'small') {
    @include checkoutSmallSpacing($position, $useMargin);
  } @else {
    @include checkoutSpacing($position, $useMargin);
  }
}

@mixin minClicableArea($minHeight: 2.4rem, $minWidth: 2.4rem) {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    min-width: $minWidth;
    min-height: $minHeight;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
}

@mixin hidescrollbar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@function absolute-col-size($cols: 1, $bp: null, $bp-ctrl: null) {
  $inner_single_space: 1.2rem;

  $component_inner_spaces: calc($inner_single_space * ($cols - 1));

  @return calc(($cols * var(--col-width)) + $component_inner_spaces);
}

@mixin absolute-col-size($css, $cols: 1) {
  #{$css}: absolute-col-size($cols);
}

@mixin absolute-col($cols: 1, $bp: null, $bp-ctrl: null) {
  @if $bp != null {
    @if $bp-ctrl != null {
      @include breakpoint($bp, $bp-ctrl) {
        flex: 1 0 auto;
        @include absolute-col-size(flex-basis, $cols);
        @include absolute-col-size(max-width, $cols);
      }
    }
  } @else {
    flex: 1 0 auto;
    @include absolute-col-size(flex-basis, $cols);
    @include absolute-col-size(max-width, $cols);
  }
}

@function inner-col-size($cols: 1, $bp: null, $bp-ctrl: null) {
  $inner_single_space: 1.2rem;

  $component_inner_spaces: calc($inner_single_space * ($cols - 1));

  @return calc(($cols * var(--col-width)) + $component_inner_spaces + $inner_single_space);
}

@mixin inner-col-size($css, $cols: 1) {
  #{$css}: inner-col-size($cols);
}

@mixin inner-col($cols: 1) {
  flex: 1 0 auto;
  @include inner-col-size(flex-basis, $cols);
  @include inner-col-size(max-width, $cols);
}

@mixin manual-col($css) {
  flex: 1 0 auto;
  flex-basis: #{$css};
  max-width: #{$css};
}

@mixin absolute-row() {
  flex-direction: row;
  gap: 1.2rem;
  flex-wrap: wrap;
}
