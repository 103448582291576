//* ANCHOR - DEFAULT --------------------------------------------------------------------
.store,
.store-locator__map {
  height: $storelocator-height;
}
.store-locator__wrapper {
  position: relative;
  @extend %container;
  margin: 0;
  max-width: unset;
  overflow: hidden;
}

.store-locator {
  padding-top: var(--header-h);
  //* ANCHOR - MAP --------------------------------------------------------------------
  &__map {
    height: 60rem;
    .google-map {
      width: 100%;
      height: 100%;
    }
  }

  //* ANCHOR - MARKERS --------------------------------------------------------------------
  .cluster {
    div {
      @extend %font__cta;
    }
  }
  [data-marker-over='true'] {
    .cluster:not(:hover) {
      filter: invert(100%);
    }
  }

  //* SECTION - SEARCH --------------------------------------------------------------------
  .container--search {
    position: absolute;
    z-index: 1;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 1.2rem;

    .search-store {
      padding: 0 1.2rem;
      position: relative;

      &__resultslabel {
        display: flex;
      }

      //* ANCHOR - SEARCH FIELD --------------------------------------------------------------------
      &__autocomplete {
        background-color: $content-white;
        height: 7.2rem;
        display: flex;
        align-items: center;

        input {
          @include formControlInput();
          padding: 0 1.2rem 0 0;
          flex-grow: 1;

          &:focus + button {
            border-color: $content-black;
          }
        }

        .autocomplete {
          border: none;
          left: 0;
          padding: 2rem 1.2rem;
          ul {
            display: flex;
            flex-direction: column;
            gap: 0.2rem;
            box-shadow: none;
            margin-top: -0.1rem;

            border: none;
            z-index: 2;
            padding: 0;

            li {
              align-items: center;
              border: none;
              line-height: 1.8rem;
              font-size: 1.4rem;

              &.focused {
                background-color: $background-product;
              }

              .matched-text {
                @extend %font-medium;
                font-weight: 600;
              }
            }
          }
        }
      }

      &__searchbox {
        display: flex;
        flex-grow: 1;
      }

      //* ANCHOR - FILTERS --------------------------------------------------------------------

      %store-button {
        @extend %font__cta;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        gap: 0.4rem;
        line-height: 1;
        &::before {
          content: '';
          @include fixedSprite('10-open');
        }

        &[aria-expanded='true'] {
          &::before {
            @include fixedSprite('10-minimize');
          }
        }
      }
      &__results-filters {
        margin-top: 1.2rem;
        padding: 1.4rem 1.2rem;
        background-color: $content-white;
        display: flex;
        justify-content: space-between;

        .refine__toggle {
          @include buttonFocus();
        }

        #resultsOptions,
        #allLocationsOptions,
        #refineOptions {
          position: absolute;
          left: 1.2rem;
          right: 1.2rem;
          background-color: $content-white;
        }

        .allocations__toggle {
          &--nosearch {
            @extend %store-button;
          }
        }
        .refine__toggle {
          @extend %store-button;
        }
      }

      &__filters {
        padding: 2rem 1.2rem;
        overflow: auto;
        top: 13.6rem;
        max-height: 60rem - 2rem - 1.2rem - 13.6rem;

        &__container {
          .use-location {
            @extend .button;
            @extend .button--tertiary;
            margin-top: 5.2rem;
          }

          .storelocator-filters {
            padding: 0;
            list-style: none;

            li {
              padding: 0;
              display: flex;
              &:not(:last-child) {
                margin-bottom: 2rem;
              }

              position: relative;
              cursor: pointer;
              padding-right: 1.8rem;

              label {
                cursor: pointer;
                position: relative;
              }

              input {
                @extend .visually-hidden;

                &:checked + label {
                  &::after {
                    content: '';
                    position: absolute;
                    right: -2.4rem;
                    top: 50%;
                    transform: translateY(-50%);
                    @include animate;
                    @include fixedSprite('10-correct');
                  }
                }

                &:focus + label {
                  @include focus-underline;
                }
              }

              label {
                @extend %font__cta;
              }
            }
          }
        }
      }

      //*ANCHOR - ALL BOUTIQUES --------------------------------------------------------------------
      &__regions {
        top: 0;
        max-height: 60rem - 2.1rem - 1.2rem;
        display: flex;
        flex-direction: column;
        .button__close {
          position: absolute;
          right: 1.2rem;
          top: 2.4rem;
        }
        &__title {
          padding: 2.7rem 1.2rem;
        }

        .wrapper-regions {
          min-height: 43.2rem;
          padding: 2rem 1.2rem 4rem;
          flex: 1;
          overflow-y: auto;
          .accordion__panel > div {
            padding-top: 4rem;
          }

          .region-col {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 2rem;
            h3 {
              @extend %font__title;
            }
            button {
              text-align: left;
              @extend %font__cta;
            }

            ul {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 2rem;
            }
          }
        }
      }

      //*ANCHOR - RESULTS --------------------------------------------------------------------
      &__resultslist {
        top: 0;
        max-height: 50.4rem;
        display: flex;
        flex-direction: column;
        .button__close {
          position: absolute;
          right: 1.2rem;
          top: 2.4rem;
        }
        &__title {
          padding: 2.7rem 1.2rem;
        }

        ul {
          flex: 1;
          overflow: auto;
          list-style: none;
          display: flex;
          flex-direction: column;
          padding: 4rem 1.2rem;
        }

        &__menuitem {
          padding: 4rem 0;
          &:not(:last-child) {
            border-bottom: 0.1rem solid $border-light;
          }
          &:first-child {
            padding-top: 0;
          }
          &:last-child {
            padding-bottom: 0;
          }

          .address {
            @extend %font__subtitle;
          }

          &__name {
            button {
              @extend %font__title;
            }
            margin-bottom: 2rem;
          }

          &__phone {
            margin-top: 2rem;
          }
        }
      }
    }
  }
  //* !SECTION - End Section
}

//* ANCHOR - STORE PAGE --------------------------------------------------------------------
.store-page {
  padding: calc(#{var(--header-h)} + 6rem) 0 6rem;

  .contact-link {
    text-decoration: none;
  }

  .phone-note {
    margin-top: 0.8rem;
    color: $interactive-light;
  }

  &__section {
    &:not(:first-child) {
      padding: 4rem 0 6rem;
    }
    &-title {
      margin-bottom: 5.4rem;
    }

    //* ANCHOR SINGLES SECTION --------------------------------------------------------------------
    &#intro {
      .store-name {
        margin-bottom: 2rem;
      }
    }

    &#services {
      overflow-x: hidden;
      .service-index {
        margin-bottom: 3.2rem;
      }
      .service-title {
        margin-bottom: 1.2rem;
      }
      .section__content {
        position: relative;

        .store-page__section-title {
          padding-right: 6.4rem; // avoid navigation overlap
          height: 2.4rem;
          display: flex;
          align-items: center;
        }

        .navigation {
          position: absolute;
          right: 0;
          top: 0;
          display: flex;
          gap: 1.6rem;

          .swiper-button-disabled {
            opacity: 0.4;
          }
        }
      }
    }
  }

  &__inner-section {
    &:not(:last-child) {
      margin-bottom: 2.4rem;
    }

    &-title {
      margin-bottom: 1.2rem;
    }
  }

  &__nearby {
    &-infos {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
    }

    &-list {
      display: flex;
      flex-direction: column;
      gap: 5.2rem;
      .links {
        margin-top: 2.4rem;
        padding-top: 1.2rem;
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        align-items: flex-start;
      }
    }
  }

  .breadcrumb {
    padding: 0;
    margin-bottom: 5.2rem;
    ol {
      padding: 0.3rem;
      margin: -0.3rem 0;
      overflow-y: visible;
      gap: 0.8rem;
      li {
        @extend %font__cta;
        gap: 0.6rem;
        &::before {
          content: '<';
        }
      }
    }

    a {
      text-decoration: none;
    }
  }

  .store-locator__map {
    height: auto;
    aspect-ratio: 16/9;
    margin-top: 2rem;
  }
}
