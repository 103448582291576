.alert {
  color: $alert-error;
  .icon {
    vertical-align: middle;
  }

  &--success {
    color: $alert-success;
  }
}

.alert-on-link {
  a {
    color: $alert-error;
  }
}
