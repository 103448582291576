.checkout--title {
  @extend [role='tab'];
}
.heading-tabs-checkout {
  .wrapper-tab-list-checkout {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: $content-white;
    @include z;
    @include animate;
    margin: 0 auto;
    .react-tabs__tab-list {
      @extend .col-lg-20;
      width: 100%;
      display: flex;
      justify-content: center;
      &:not(.no-divider)::before {
        height: 100%;
        background-color: $content-white;
        width: 100vw;
        left: 50%;
        transform: translateX(-50%);
        border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
      }
      .checkout--title {
        position: absolute;
        left: 0;
        cursor: default;
        h1 {
          color: $content-black;
        }
      }
      .react-tabs__tab {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
      }
    }
  }
}

.wrapper-tab-panels-checkout {
  @include checkoutSpacing();
  @include checkoutLargeSpacing('bottom');
}

.checkoutpage.paybylink {
  .wrapper-tab-panels-checkout {
    padding-top: 0;
  }
  .checkout--title {
    margin: 6rem 1.2rem 3.2rem;
    padding: 0 0 0.7rem;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
    display: flex;
    @extend .col-lg-20;
  }
  .bag-summary.checkout {
    margin-top: 2rem;
  }
}
