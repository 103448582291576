.product-tile {
  &_quick-buy-wrp {
    margin-top: 0;
    visibility: hidden;
    opacity: 0;
    @include animate(1, 'opacity');
    &.is-show {
      opacity: 1;
      visibility: visible;
    }
  }
}
