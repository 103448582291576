.checkout-products-wrapper {
  @include scrollbar;
  max-height: 33rem;
  min-height: 13.1rem;
  padding-right: 0.8rem;
  padding-bottom: 0.1rem;
}
.checkout-products {
  @include reset-list;
}
