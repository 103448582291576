// https://www.w3.org/TR/wai-aria-practices-1.1/examples/radio/radio-1/radio-1.html

.optionbox {
  &--address {
    .optionbox__item {
      @include col-width(8);
    }
  }

  &--horizontal-two-items {
    @include grid-row();
    .optionbox__item {
      @include col(12);
    }
  }
}
