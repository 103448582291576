.checkoutpage.main--checkout {
  overflow: hidden;
}

.checkout--title,
.checkout-subtitle,
.primary-s,
.checkout-main-title {
  @extend %font__title;
}

.checkout-main-title {
  @include grid-container;
  @include border('bottom');
  @include checkoutSpacing('top', true);
  @include checkoutSmallSpacing('bottom', true);
  padding-bottom: 0.8rem;
}

.checkout-subtitle {
  @include checkoutSmallSpacing('bottom', true);
  @include checkoutSpacing('top', true);

  [data-whatintent='keyboard'] &:focus {
    @include focus-visible;
  }
}

.checkout-navigations {
  @include checkoutSpacing('top', true);
  button {
    @extend .full;
  }
}

.saveaddress,
.cta__checkout-navigation {
  @extend .full;
}

.contact-information {
  .checkout-subtitle {
    margin-top: 0;
  }
  .logged-as {
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.6rem;
    column-gap: 1.2rem;
    span:not(.bold) {
      flex-basis: 100%;
      @extend %font__title;
    }
    .bold {
      @extend %font__copy;
      font-weight: normal;
    }
    span:first-child + span {
      text-transform: uppercase;
    }
  }
}

//SPACES

.address_form,
.billingAddressForm {
  // overflow: hidden;
  .row + .row,
  .row-fake + .row {
    @include checkoutSmallSpacing();

    &.phonewithprefix-fields {
      padding-top: 0;
    }
  }

  .col-24 + .col-24 {
    @include checkoutSmallSpacing();
  }
}

.shipping-tabs {
  .container {
    padding: 0;
  }

  .shipping-tabs-tablist {
    padding-left: 0;
    padding-right: 0;
  }

  .container-shipping-detail {
    padding-top: 3.2rem;
  }
}

.checkout-privacyflags {
  p {
    @include checkoutSmallSpacing();
    @include checkoutSmallSpacing('bottom');
  }
}

.phonewithprefix-label {
  @include checkoutSmallSpacing();

  &--nopad {
    padding-top: 0;
  }
}
