.section-menu {
  @include reset-list;
  li {
    @extend %font__cta;
    color: $disabled-dark;
    padding-right: ($space-unit * 6);
    &.selected {
      color: $content-black;
    }
    &:not(:last-child) {
      margin-bottom: ($space-unit * 5);
    }
    a {
      text-decoration: initial;
    }
  }
}

.menu-services {
  padding: 0 1.2rem;
  margin: 1.2rem 0;
  align-items: unset;
  .menu-voice {
    margin: 0;

    & + .menu-voice {
      margin-top: 1.6rem;
    }
  }
}
