.grid-container {
  &,
  &--3tiles {
    @include reset-list;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: auto;
    width: 100%;
    column-gap: 1.2rem;
    row-gap: 2rem;
    padding: 1.2rem 0;
  }

  .full-grid {
    grid-column: 2 span;
    > .align-container {
      @each $breakpoint, $padding in $grid-container-padding {
        @if $breakpoint== 'xs' {
          margin-left: (-$padding);
          margin-right: (-$padding);
        } @else {
          @include breakpoint($breakpoint, min) {
            margin-left: (-$padding);
            margin-right: (-$padding);
          }
        }
      }
    }
  }

  &--3tiles {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.plp-loading,
.plp-error {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3.4rem;
  padding-bottom: 3.4rem;
}

.plp-loading {
  .wrapper-loading {
    position: relative;
    min-width: 154px;
  }
  span {
    display: block;
    padding-bottom: 1.2rem;
    text-align: center;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: $interactive-dark;
      left: 0;
    }
  }
  .progress-bar {
    position: relative;
    display: block;
    width: 100%;
    height: 1px;
    background-color: black;
  }
}

.plp-error {
  &::before {
    content: '';
    width: $space-unit * 4;
    height: $space-unit * 4;
    margin-right: $space-unit * 2;
  }
}

.infinite-scroll-component__outerdiv {
  padding-bottom: 9rem;
}

.plp-listing {
  &__counter {
    @extend %font__title;
    padding-top: 3rem;
    padding-bottom: 0.8rem;
  }
}
