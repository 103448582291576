.modal__legacy {
  &.info-modal {
    .modal__legacy-wrapper {
      .info-modal__description {
        padding-bottom: 4rem;
      }
      .option-address {
        gap: 0;
        > p {
          @extend %font__title;
          & + .option-address__detail:not(:empty) {
            padding-top: 4rem;
          }
        }
      }
    }
  }
  &.checkout-address-suggestion {
    .info-modal__description {
      padding-top: 4.2rem;
    }
    .optionbox--vertical {
      margin: 0 0 6rem;
      @include breakpoint(lg, min) {
        margin-bottom: 4rem;
      }
    }
  }

  @include breakpoint(lg, min) {
    .modal__legacy-wrapper {
      // @extend .col-lg-8, .col-xxl-12;
      @include absolute-col(8);
      .modal__content {
        @include absolute-col(6);
      }
    }

    &.info-modal {
      .modal__legacy-wrapper {
        @include absolute-col(10);
        .modal__content {
          @include absolute-col(8);
        }
      }
    }
  }

  @include breakpoint(xxl, min) {
    .modal__legacy-wrapper {
      @include absolute-col(12);
      .modal__content {
        @include absolute-col(10);
      }
    }

    &.info-modal {
      .modal__legacy-wrapper {
        @include absolute-col(7);
        .modal__content {
          @include absolute-col(6);
        }
      }
    }

    &.expresspayment-modal {
      .modal__legacy-wrapper {
        @include absolute-col(8);
        .modal__content {
          @include absolute-col(6);
        }
      }
    }
  }

  &.confirm-changepassword {
    p {
      margin-bottom: 2rem;
    }
  }
}
