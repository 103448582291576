.bag-summary {
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;

  #promotionalcode {
    .form-control {
      margin-top: 0;
    }
  }

  .items--totals-available {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2.4rem;
  }

  .items {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 0.4rem;

    &--promocode {
      justify-content: flex-start;
      gap: 1.6rem;
      font-weight: 600;
    }

    &--summary,
    &--totals {
      @extend .items;
      padding: 1.6rem 0;
      .items__label,
      .items__value {
        @extend %font__title;
      }
    }
    &--summary {
      padding-bottom: 1.2rem;
    }

    &__label,
    &__value {
      @extend %font__subtitle;
    }

    &__label {
      text-align: left;
    }

    &__item-count {
      white-space: nowrap;
    }

    &--subtotal {
      @extend .items;
      padding: 2.4rem 0;
    }
  }
  .accordion-mini {
    padding: 2.4rem 0;
    text-align: left;
  }
  .button-row {
    padding-top: $space-unit * 4;
  }
  .expecteddelivery {
    border-top: solid 1px $outline;
    margin-top: $space-unit * 3;
  }
  .tax-disclaimer--wrapper {
    margin-top: 0;
  }
  .bag-summary__ctas {
    display: grid;
    row-gap: 0.8rem;
  }
  .fixed-summary-mobile {
    @include animate(1);
    position: absolute;
    opacity: -1;
    visibility: hidden;
    z-index: 0;
    margin-top: -0.2rem;
    margin-left: -0.1rem;
    background-color: $content-white;
    transition-property: opacity, visibility;
    padding: 0 1.2rem;
    @include border();
    border-top-width: 0;
    &.visible {
      z-index: 1;
      opacity: 1;
      visibility: visible;
    }
  }

  .paymentslogos-list {
    margin-top: 3.2rem;
    .inline-list {
      margin-top: 1.2rem;
      row-gap: 0.4rem;
      li {
        width: 3.4rem;
      }
    }
  }
}
.accordion-summary {
  position: absolute;
  right: 0;
  top: 0;
}
.details-button {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  height: 1.4rem;
  align-items: center;

  &__open {
    @extend .details-button;
    &::after {
      content: '';
      @include fixedSprite('10-minimize');
    }
  }

  &__close {
    @extend .details-button;
    &::after {
      content: '';
      @include fixedSprite('10-open');
    }
  }
  &__container {
    position: absolute;
    width: calc(100vw - var(--scrollbar-width));
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: flex-end;
    top: 2rem;
    // @include animate(0.3);
  }
}

.bag-info-box {
  padding: 2.4rem;
  border: 1px solid $outline;

  .font__title + p,
  .button {
    margin-top: 2rem;
  }
}

.wrapper-sticky-mobile {
  background: white;
  width: 100%;
  left: 0;
  z-index: 3;
  padding-bottom: 2.4rem;
  &.fixed {
    @extend .container;
    position: fixed;
    bottom: 0;
    border-top: 1px solid $outline;
    padding-bottom: 1.2rem;
    .items--totals.border-top {
      border-top: initial;
    }
    .paymentslogos-list {
      display: none;
    }
  }
}
