.store {
  &-locator__wrapper {
    .store {
      position: relative;
      top: unset;
      left: unset;
      width: auto;
      background-color: $content-white;

      .button--close {
        top: 0;
      }
    }
  }

  &-wrapper {
    height: 100%;
    // max-width: 80%;
  }

  &__summary {
    padding: 0 0 4rem;
    border-bottom: 0.1rem solid $border-light;

    & > .container {
      padding-right: $column-gapped-width;
    }
    &__name {
      margin-bottom: 4rem;
    }

    &__viewmore {
      margin-top: 2rem;
    }
  }

  &__moreinfo {
    // & > .container {
    //   padding-right: $column-gapped-width;
    // }

    &__section {
      @each $breakpoint, $padding in $grid-container-padding {
        @if $breakpoint== 'xs' {
          padding: 6rem $column-gapped-width 6rem $padding;
        } @else {
          @include breakpoint($breakpoint, min) {
            padding: 6rem $column-gapped-width 6rem $padding;
          }
        }
      }

      &__title {
        margin-bottom: 4rem;
      }

      dl {
        display: flex;
        flex-direction: column;
        row-gap: 0;
      }

      dt {
        margin-bottom: 1.4rem;
      }

      dd {
        &:not(:last-child) {
          margin-bottom: 4rem;
        }
      }

      p {
        margin-bottom: 1.8rem;
      }
    }
  }
}
