.legalarea {
  &-accordion {
    &-list {
      @include reset-list;
      display: flex;
      flex-direction: column;
      gap: 4rem;
    }

    .accordion__header {
      text-align: left;
    }

    .accordion__item {
      margin-bottom: 0;
      padding-bottom: 4rem;
      @include border('bottom');
    }

    div#{&}_card {
      padding-top: 4rem;
      padding-bottom: 0;
    }
    .accordion__header.has-number {
      .accordion__headline {
        display: flex;
        gap: 0.2rem;

        &_number {
          @extend .legalarea-subitem_label;
        }
      }
    }
  }
  &-accordion_card,
  &-subitems {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    @include reset-list;
  }

  &-subitem {
    display: flex;
    gap: 0.2rem;

    .content_label {
      width: 5rem;
      flex-shrink: 0;
      text-align: left;
    }

    .content_text {
      flex-shrink: 1;
    }

    &-label {
      .content_label {
        width: 14rem;
      }
    }

    &-none {
      .content_label {
        display: none;
      }
    }
  }

  &-subitem_label,
  .accordion__headline_number {
    width: 5rem;
    flex-shrink: 0;
  }
}
