.minibag-login {
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  background-color: $content-white;
  left: 0;
  padding: 0 2.2rem 4rem;
  z-index: 1;
  overflow: auto;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }

  .login-modal-subtitle {
    @extend %font__title;
  }
}
