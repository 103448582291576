.checkout-shipping {
  @include checkoutLargeSpacing();

  &__options {
    &__deliveryinfo {
      button {
        @extend %font__link;
        display: inline-block;
        text-align: left;
      }
    }

    &__option {
      @include grid-row;

      &-cost,
      &-name {
        @extend %font__title;
      }

      &-cost {
        text-align: right;
      }

      &-description {
        margin-top: 1.2rem;
        display: block;
      }
    }
  }

  .mandatory-fields {
    padding-bottom: 2rem;
  }

  #newAddressName {
    padding-bottom: 3.2rem;
  }

  .info-standard-estimated-delivery {
    padding-top: 2rem;
  }

  .checkoutcountryselector {
    button {
      display: inline-block;
      text-align: left;
    }
  }

  .expected-delivery-pickup {
    margin-top: 3.2rem;
  }
}
