.page-legalarea,
.page-corporate {
  padding-bottom: 4rem;
}

.legalarea-container,
.corporate-container {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
