.orders {
  &-listing {
    // ANCHOR - CLEAN UP FROM ACCORDION --------------------------------------------------------------------

    .accordion {
      &__header {
        &.aftercare__header {
          @include buttonFocus();
        }
      }

      &__item {
        .header__open,
        .header__close {
          transform: translateY(0);
          bottom: unset;
          top: 0;
        }
      }
    }

    &__intro {
      margin-bottom: 6rem;
    }

    // ANCHOR - ORDER --------------------------------------------------------------------

    &__order {
      padding: 4rem 0 0;
      border-top: 0.1rem solid $outline;

      .accordion__header {
        -webkit-touch-callout: text;
        -webkit-user-select: text;
        -khtml-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
      }

      .accordion__panel {
        & > div {
          padding: 0;
        }
      }

      &:first-child {
        border-top: 0.1rem solid $outline;
      }

      &:not(:last-child) {
        margin-bottom: 4rem;
      }

      &-section {
        padding: 4rem 0;
        border-bottom: 0.1rem solid $outline;

        &:first-child {
          .order-detail-page & {
            padding-top: 0;
          }
        }

        &:last-child {
          border: none;
          padding-bottom: 0;
          .order-detail-page & {
            padding-bottom: 4rem;
            border-bottom: solid 0.1rem $outline;
          }
        }

        .detail-title {
          @extend %font__cta;
          margin-bottom: 1.2rem;
        }

        .section-details {
          display: flex;
          flex-direction: column;
          gap: 0.8rem;
          .telephone,
          .name,
          .email {
            margin: 0;
          }

          .get-directions {
            margin-top: 0.4rem;
          }
        }

        //ANCHOR - SINGLES --------------------------------------------------------------------
        &.order {
          &-infos {
            row-gap: 4rem;
          }

          &-ctas {
            .row {
              row-gap: 1rem;
            }
          }

          &-totals {
            display: flex;
            flex-direction: column;
            gap: 3.2rem;
            .totals-row {
              display: flex;
              justify-content: space-between;
              .dt {
                @extend %font__subtitle;
              }

              &--main {
                .dt {
                  @extend %font__title;
                }
              }

              &--wrapper {
                display: flex;
                flex-direction: column;
                gap: 1.2rem;
              }
            }
          }
        }
      }

      &-detail {
        width: 100%;
        text-align: left;
        flex: 1;
        row-gap: 2rem;

        .order-detail__column,
        h2 {
          row-gap: 1rem;
          display: flex;
          flex-direction: column;
        }

        .dt {
          @extend %font__title;
        }
      }
    }

    &--cs {
      @extend .orders-listing;
      .orders-listing {
        &__order {
          padding-top: 0;
          padding-bottom: 4rem;
          margin-bottom: 0;
          border-top: none;
          border-bottom: solid 0.1rem $outline;

          // .accordion__panel {
          //   & > div {
          //     padding-bottom: 2rem;
          //   }
          // }
          &:not(:first-child) {
            margin-top: 4rem;
          }
          // &:first-child {
          //   border: none;
          // }

          &-section {
            &.order {
              &-ctas {
                padding-bottom: 2rem;
              }
            }
          }
        }
      }

      //   .product-tile-order__listing {
      //     gap: 4rem;
      //     .product-tile {
      //       padding: 0;
      //       &:not(:first-child) {
      //         padding-top: 0;
      //       }
      //       &:not(:last-child) {
      //         padding-bottom: 0;
      //         border-bottom: none;
      //       }
      //     }
      //   }
    }
  }
}
