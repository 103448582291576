.login-register-social-wrapper {
  p + .login-register-social {
    padding-top: 0;
  }
  p:first-child {
    @include checkoutSmallSpacing('top');
  }
}

.login-register-social {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  @include checkoutSmallSpacing('top');
  &.is-inline {
    padding-top: 6rem;
    gap: 1.3rem;
    flex-direction: row;
    flex-wrap: wrap;
  }

  a,
  button {
    @extend %font__title;
  }

  .icon--social-apple,
  .icon--social-google,
  .icon--social-facebook {
    margin-right: 0.8rem;
    width: 1.4rem;
    background-position: left center;
  }
}

.other-login-methods {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
}

.button {
  &--primary,
  &--secondary {
    &.social-button {
      @extend %font__cta;
      @extend .full;
    }
    .icon--social-apple,
    .icon--social-google,
    .icon--social-facebook {
      margin-right: 0.8rem;
      transform: translateY(-0.1rem);
    }
  }
}
