.client-service {
  &__section {
    padding: 4rem 0 6rem;
    row-gap: 5.2rem;

    // .component-section-title {
    //   margin-bottom: 5.2rem;
    // }

    .react-tabs__tab-panel {
      min-height: unset;
    }

    .section {
      &__title {
        @extend %font__title;
      }
      &__content {
        row-gap: 5.2rem;
      }
    }

    // MODIFIERS --------------------------------------------------------------------
    &.section {
      &--no-padding {
        padding: 0;
      }
    }

    .baa {
      &--email {
        word-break: break-all;
      }
    }
  }

  &__modal {
    &-image-container {
      margin: 2.4rem 0;
      display: flex;
      justify-content: center;
    }
    &--offline {
      &__time {
        margin-top: 2rem;
        @extend %font__cta;
      }
    }
  }

  &__reason-summary {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    &:not(:last-child) {
      margin-bottom: 0.8rem;
    }
    &--reason {
      color: $interactive-light;
    }
  }

  .accordion-step {
    .step__header:not(.is-active):not(.is-done) {
      color: $interactive-light;
    }
  }
}

// SECTION - COMPONENTNS --------------------------------------------------------------------
.client-service {
  // ANCHOR - DETAIL --------------------------------------------------------------------
  &-detail {
    .row--scroll {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  // ANCHOR - HUB GRID --------------------------------------------------------------------
  &-hub-grid {
    .row {
      row-gap: 4rem;
    }
  }
  // ANCHOR - ACCORDION PAYMENTS --------------------------------------------------------------------
  &-accordion-payments {
    .accordion {
      &__header {
        align-items: flex-start;
        padding-bottom: 4rem;
        border-bottom: solid 0.1rem $outline;
        &--content {
          flex: 1;
        }
        p {
          text-align: left;
        }
        i {
          position: relative;
          transform: translate(0);
        }
      }
      &__title {
        margin-bottom: 1rem;
        text-align: left;
      }
      &__item {
        &:not(:last-child) {
          margin-bottom: 4rem;
        }
      }

      &__panel {
        &--content {
          padding: 4rem 0 0;
        }
      }
    }

    .please-note-description {
      color: $interactive-light;
      padding-bottom: 4rem;
      border-bottom: solid 0.1rem $outline;
    }

    .payment-methods {
      padding: 0 0 4rem 0;
      border-bottom: solid 0.1rem $outline;
      margin-bottom: 4rem;
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 4rem;
      .payment-method {
        padding: 0;

        &__title {
          margin-bottom: 0.2rem;
        }
        &__subtitle {
          color: $interactive-light;
        }
      }
    }
  }

  &-feature {
    img {
      width: 100%;
      height: auto;
    }
  }

  &__baa-guest-advice {
    row-gap: 2rem;
  }
}

// !SECTION
