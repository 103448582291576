.productTiles-rail {
  .rail-topbar {
    padding-bottom: 4.4rem;
    align-items: center;
  }

  &.editorial-rail {
    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;

    &,
    .product-tile__info_content {
      background-color: $background-product;
    }
  }

  &.hasheader-rail {
    padding-top: $grid-gutter-width + 7.2rem;
    padding-bottom: $grid-gutter-width + 7.2rem;
  }

  .tabs & {
    padding-top: 0.8rem;
    &.carousel-rail {
      padding-top: 2rem;
    }
  }

  .rail-links {
    margin-top: 6.8rem;
  }

  .rail-navigation,
  .rail-navitation--actions {
    display: flex;
    gap: 0.3rem;
    justify-content: flex-end;
  }
}
