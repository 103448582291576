.tracking {
  &__no-item {
    @extend %font__cta;
    padding-bottom: 4.6rem;
    border-bottom: solid 0.1rem $outline;
  }
}

.aftercare-detail-page {
  .orders-listing__order {
    padding-top: 0;
    padding-bottom: 6rem;
    &:first-child {
      border-top: none;
    }
    border-bottom: solid 0.1rem $outline;

    .dt {
      @extend %font__cta;
    }
  }
}
