.login-modal {
  &.in-drawer {
    .modal__body {
      gap: 4rem;
    }
  }

  .modal__body--right {
    gap: 4rem;
  }

  .button-login-cta,
  .button-cta-passwordlogin {
    margin-top: 4rem;
  }

  .edit-email-button {
    margin-top: 1.2rem;
  }
}
