.accordion-step {
  padding-bottom: 4rem;
  border-bottom: solid 0.1rem $outline;

  &:not(:last-child) {
    margin-bottom: 4rem;
  }

  .step {
    &__header {
      &__title {
        @extend %font__title;
      }

      &__content {
        display: flex;
        justify-content: space-between;
      }

      &__description {
        margin-top: 2rem;
      }
    }
  }
}
