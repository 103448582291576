.container-bag-summary {
  padding: 0;
}

.checkout {
  &--summary {
    .details-button__close,
    .details-button__open {
      margin: 0;
    }
  }

  .collapsed-row {
    overflow: hidden;
    height: 0;
    background-color: white;
    @include animate();
  }

  &__block {
    padding-bottom: 0.8rem;
    border-bottom: solid 1px $outline;
    display: flex;
    justify-content: space-between;
  }
}

.wrapper-info-total {
  padding-bottom: 1.2rem;
}
