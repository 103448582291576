.component-events-listing {
  padding: 7.1rem 0 9.3rem;
  overflow-x: hidden;
  .events-listing {
    &__title--wrapper {
      margin-bottom: 5.2rem;
      align-items: center;
      &.not-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }

    &__actions {
      display: flex;
      justify-content: space-between;
      gap: 1.2rem;
      align-items: center;
      .navigation {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        button[aria-disabled='true'] {
          opacity: 0.4;
        }
      }
    }
  }

  .event {
    @include absolute-col(20);

    &-heading {
      text-decoration: none;
      margin-bottom: 2.4rem;
      width: 100%;
      cursor: pointer;
    }

    &-date {
      margin-bottom: 1.2rem;
    }

    &-title {
      min-height: var(--title-height);
    }

    &-image {
      margin-bottom: 1.6rem;
      width: 100%;
      background-color: $interactive-dark;
    }

    &-content {
      margin-bottom: 2.4rem;
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      min-height: var(--content-height);
      &__detail-title {
        margin-bottom: 0.4rem;
      }
    }
  }
}

@include breakpoint(lg, min) {
  .component-events-listing {
    padding: 6.4rem 0 9.2rem;
    .events-listing {
      &__title--wrapper {
        margin-bottom: 11.5rem;
      }

      &__actions {
        .navigation {
          flex: 1;
          justify-content: flex-end;
        }
      }
    }

    .event {
      @include absolute-col(9);

      &-heading {
        margin-bottom: 8rem;
      }

      &-date {
        margin-bottom: 1.6rem;
      }

      &-content {
        margin-bottom: 6rem;
      }
    }
  }
}

@include breakpoint(xxl, min) {
  .component-events-listing {
    .event {
      @include absolute-col(6);
    }

    .swiper {
      overflow: hidden;
    }
  }
}
