.product-selection {
  min-height: calc(var(--view-height) - var(--header-h) - 4rem - 0.1rem - 4rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: calc(var(--header-h) + 4rem);

  --col-offset: 17;
  &.pinned {
    @include absolute-col(6);
  }

  &__maininfo {
    margin-bottom: 4rem;
  }

  &__price {
    margin-top: 8rem;
  }

  &__actions-shipping.on-pdp {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 3.4rem;

    &.show-more {
      gap: 0;
      justify-content: flex-start;
    }

    .product-selection__actions-shipping {
      &__header,
      &__body {
        display: flex;
        flex-wrap: wrap;
        column-gap: 1.8rem;
        width: 100%;
      }

      &__header {
        padding-right: 2.4rem;
        position: relative;
      }

      &__body {
        padding-top: 2.4rem;
      }

      &__openButton {
        position: absolute;
        top: -0.4rem;
        right: 0;
        width: 2.4rem;
        height: 2.4rem;
        min-width: 0;
        min-height: 0;
      }
    }
  }

  &__ctas {
    margin-bottom: 1.2rem;
  }
}
