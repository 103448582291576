.bag-product {
  padding-bottom: $space-unit * 15;
  margin-bottom: $space-unit * 15;
  &__content {
    justify-content: space-between;
    &.removing {
      justify-content: flex-start;
    }
  }
}
