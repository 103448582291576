header.checkout {
  .navigation {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5.2rem;
  }

  h1 {
    @extend .visually-hidden;
  }
}
