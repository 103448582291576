.header {
  // * TOP BAR -----------------------------------------------------------------
  &-topbar__container {
    padding: 1.6rem 0 1.6rem;
    display: block;
  }

  // * LOGO -----------------------------------------------------------------
  &-logo {
    svg {
      height: 4.8rem;
    }

    &.logo_us {
      height: 6rem;
    }
  }
}

//* MENU ----------------------------------------------------------------------
.menu {
  &-modal {
    &__container {
      padding-top: 10rem;
    }
  }
}
