.legalarea,
.cs {
  &-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    * + picture {
      margin-top: 2rem;
    }

    picture {
      display: block;

      img {
        width: 100%;
      }
    }
  }
}
