.wishlist {
  &-login-box {
    width: 100%;
    padding: 2.4rem;
    @include border;
    .wrapper-buttons {
      padding-top: 2rem;
    }
  }
}
