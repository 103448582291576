// TOUCH AREA --------------------------------------------------------------
$target-size-mobile: 4rem;
$target-size-desktop: 3.2rem;
$outline-width: 0.1rem;
$outline-color: $content-black;
$outline-color-inverted: $content-white;
$outline-color-alt: #f8e28f;
$outline-radio-size: 2.4rem;
$outline-checkbox-size: 2.4rem;

// GLOBAL ------------------------------------------------------------------------------------------------------------------
$hover-opacity: 0.7;

// CHECKBOX/RADIO -----------------------------------------------------------------------------------------------------------
$radio-size: 1rem;
$checkbox-size: 1rem;

// SWATCH -------------------------------------------------------------------------------------------------------------------
$swatch-size-mobile: 1.6rem;
$swatch-margin-mobile: 1.6rem;
$swatch-size-desktop: 1.6rem;
$swatch-margin-desktop: 1.6rem;
$swatch-pdp-size-desktop: 2.2rem;

// BUTTON ------------------------------------------------------------------------------------------------------------------
$button-height-mobile: 5.2rem;
$button-height-desktop: 3.6rem;
$button-padding-mobile: 0 1.6rem;
$button-padding-desktop: 0 1.4rem;

// INPUT -------------------------------------------------------------------------------------------------------------------
$input-height-mobile: 5.2rem;
$input-height-desktop: 3.6rem;
$input-disabled-opacity: 0.55; // sulla styleguide #FBFBFB

// SIZE E COLOR SELECTOR ---------------------------------------------------------------------------------------------------
$selector-intile-height: 2rem;
$selector-inpage-height-mobile: 4.6rem;
$selector-inpage-height-desktop: 3.6rem;

// SIZE SELECTOR IN PLP TILE ------------------------------------------------------------------------------------------------
$selector-size-intile-height: $selector-intile-height;
$selector-size-intile-cols: 6;
$selector-size-intile-rowgap: 1.2rem;
$selector-size-intile-padding: 4.8rem 0;

// SIZE SELECTOR IN PDP, RESERVE IN BOUTIQUE MODAL, SHOP BY LOOP -----------------------------------------------------------
$selector-size-inpage-height-mobile: $selector-inpage-height-mobile;
$selector-size-inpage-cols-mobile: 6;
$selector-size-inpage-rowgap-mobile: 2rem;
$selector-size-inpage-padding-mobile: 1.6rem 0;

$selector-size-inpage-height-desktop: $selector-inpage-height-desktop;
$selector-size-inpage-cols-desktop: 9; //note that children sizes may be long, 10 cols are too narrow
$selector-size-inpage-rowgap-desktop: 2rem;
$selector-size-inpage-padding-desktop: 1.1rem 0 6rem;

// SIZE SELECTOR IN CART ----------------------------------------------------------------------------------------------------
$selector-size-inline-cols-desktop: 7;
$selector-size-inline-rowgap-desktop: 2rem;

// COLOR SELECTOR IN PLP TILE------------------------------------------------------------------------------------------------
$selector-color-intile-height: $selector-intile-height;

// COLOR SELECTOR IN PDP, RESERVE IN BOUTIQUE MODAL -------------------------------------------------------------------------
$selector-color-inpage-height-mobile: $selector-inpage-height-mobile;
$selector-color-inpage-height-desktop: $selector-inpage-height-desktop;

// STORELOCATOR MAP FIXED HEIGHT --------------------------------------------------------------------------------------------
$storelocator-height: 60rem;
$storelocator-height-wticker: calc(var(--view-height) - #{$header-height-mobile} - #{$ticker-bar-m} - 6.8rem);
$storelocator-height-desktop: 85.8rem;
$storelocator-list-height: calc(var(--view-height) - 21.2rem);

// EASING -------------------------------------------------------------------------------------------------------------------
$easeOutQuart: cubic-bezier(0.25, 1, 0.5, 1);
$easeInQuart: cubic-bezier(0.5, 0, 0.75, 0);
$easeInOutQuart: cubic-bezier(0.76, 0, 0.24, 1);
$pseudoInQuart: cubic-bezier(0.76, 0, 0.88, 0.5);
$pseudoOutQuart: cubic-bezier(0.12, 0.5, 0.24, 1);
