// Utility debug

// for dev purposes only
.component-text-example {
  background-color: $content-white;
  margin-top: 5rem;
  margin-bottom: 5rem;
  padding: 5rem 10%;
  line-height: 200%;
  ul {
    list-style-type: circle;
  }
  h3 {
    font-size: ($space-unit * 6);
  }
  code {
    font-family: Monaco, Menlo, Consolas, 'Courier New', monospace;
    white-space: normal;
    color: #e01e5a;
    padding: 0.2rem 0.3rem 0.1rem;
    font-variant-ligatures: none;
    tab-size: 4;
    background-color: #f7f7f9;
    border: $space-line solid #e1e1e8;
    border-radius: 0.3rem;
  }
  .check-ok {
    background-color: greenyellow;
    padding: 1rem;
    width: 20rem;
  }
  .check-ko {
    background-color: #f99;
    padding: 1rem;
    width: 20rem;
  }
}

.formtester {
  margin-top: 20rem;
  h3 {
    margin-bottom: 2rem;
  }
  .formjson {
    margin-top: 5rem;
    padding-top: 5rem;
    border-top: $space-line solid black;
  }
}

.devmode {
  color: black;
  background-color: yellow; // serve ad indicare pulsanti abilitati solo in sviluppo che non dovrebbero esserci normalmente
}

.devmodebox {
  margin-bottom: ($space-unit * 4);
  margin-top: ($space-unit * 4);
  padding-top: ($space-unit * 2);
  padding-bottom: ($space-unit * 2);
  text-align: center;
  width: 100%;
  button:not(:last-child) {
    margin-bottom: 8px;
  }
}

// body.ssr {
// header {
// border-top: $space-unit solid yellow !important;
// }
// .uninitialized {
//   width: 50%;
//   margin: 2rem auto;
//   border: 2rem solid red;
// }
// .initializing {
//   border: 2rem solid blue;
// }
// }

code {
  background-color: #eee;
  border: 1px solid #999;
  display: block;
  padding: 20px;
}
