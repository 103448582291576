.faq {
  &-listitem {
    &-component,
    &-module {
      width: 100%;
    }
  }

  &-listitem + &-listitem {
    @include checkoutSpacing;
    @include checkoutSpacing('top', true);
    @include border('top');
  }

  &-listitem,
  &-listitem-module {
    .accordion__group {
      padding-bottom: 3.2rem;
    }

    .title + .accordion__group {
      padding-top: 5.2rem;
    }
  }

  &-dropdown {
    @include checkoutSpacing('bottom');
    &__content,
    &__container {
      width: 100%;
    }

    &__cta {
      display: none;
    }
  }
}
