.grid-container {
  grid-template-columns: repeat(4, minmax(0, 1fr));

  .full-grid {
    grid-column: 4 span;
  }

  &--3tiles {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.listing-container {
  .grid-container,
  .grid-container--3tiles {
    row-gap: 4rem;
  }
}

.infinite-scroll-component__outerdiv {
  padding-bottom: 12rem;
}
