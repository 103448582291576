.register_form {
  &__actions {
    flex-direction: row-reverse;
    gap: 1.2rem;

    &--back {
      flex-grow: 1;
    }
  }

  .fieldset--plain {
    .form-group--gender-radio {
      width: 50%;
      display: flex;
      column-gap: 0;
      flex-direction: row;
      flex-wrap: wrap;
      .radio {
        min-width: 34%;
        width: auto;
      }
    }
  }

  &--included {
    .fieldset--plain {
      .form-group--gender-radio {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 4rem;
        row-gap: 0.8rem;
        .radio {
          min-width: 0;
        }
      }
    }

    .register_form__email-row {
      .register_form__actions {
        gap: 1.2rem;

        &--back {
          flex-grow: 0;
        }
      }
    }
  }
}
