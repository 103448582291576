.register_form {
  &__header {
    padding-top: 2rem;
  }

  &__requiredlabel {
    color: $interactive-light;
    @include checkoutSmallSpacing('bottom');
  }
  &__password {
    @include checkoutSmallSpacing;
  }

  &__actions {
    @include checkoutSpacing;
    display: flex;
    gap: 2.4rem;
    flex-direction: column-reverse;
    align-items: center;

    &--back,
    &--copy {
      flex-grow: 1;
    }
    &--confirm {
      flex-grow: 1;
      .button--link {
        min-height: 2.4rem;
      }
    }
  }

  .form-section + .form-section {
    @include checkoutSmallSpacing;
  }

  .form-section {
    &:first-child {
      @include checkoutSmallSpacing;
    }
    > .row + .row {
      @include checkoutSmallSpacing;
    }

    .row.phonewithprefix-fields,
    .row.phonewithprefix-label {
      padding-top: 0;
    }
  }

  .fieldset--plain {
    margin: 0;

    .form-group--gender-radio {
      padding-top: 2rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      margin: 0;
      .radio {
        padding: 0;
      }
    }
  }

  .form-section-title {
    @extend %font__title;
    @include checkoutSmallSpacing('bottom', true);
  }

  &--included {
    .fieldset--plain {
      .form-group--gender-radio {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 4rem;
        row-gap: 0.8rem;
        .radio {
          min-width: 0;
        }
      }
    }

    .register_form__email-row {
      display: flex;
      align-items: flex-end;
      .register_form__input-column {
        flex-grow: 1;
        .valid-tick {
          display: none;
        }
      }
      .register_form__edit-column {
        flex-grow: 0;
        .button {
          border-width: 0 0 0.1rem 0;
          padding: 0 0 0 0.8rem;
          span {
            text-decoration: underline;
          }
        }
      }
    }

    .register_form__actions {
      flex-direction: row-reverse;
      gap: 1.2rem;
    }
  }
}
