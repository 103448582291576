.footer--checkout {
  .wrapper-footer {
    .footer-item {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      & + .footer-item {
        @include checkoutSpacing();
      }
    }

    h3 {
      @extend %font__title;
    }
  }

  &-contacts {
    margin-top: 2.4rem;
    li + li {
      margin-top: 1.6rem;
    }
  }
}
