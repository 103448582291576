@font-face {
  font-family: 'MonumentGroteskMono';
  font-style: normal;
  font-weight: normal;
  font-display: block;
  // prettier-ignore
  src: url('fonts/Monument_Grotesk_Mono/ABCMonumentGroteskMono-Light.woff2') format('woff2'),
       url('fonts/Monument_Grotesk_Mono/ABCMonumentGroteskMono-Light.woff') format('woff');
}
@font-face {
  font-family: 'MonumentGroteskMono';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  // prettier-ignore
  src: url('fonts/Monument_Grotesk_Mono/ABCMonumentGroteskMono-Regular.woff2') format('woff2'),
       url('fonts/Monument_Grotesk_Mono/ABCMonumentGroteskMono-Regular.woff') format('woff');
}
@font-face {
  font-family: 'MonumentGroteskMono';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  // prettier-ignore
  src: url('fonts/Monument_Grotesk_Mono/ABCMonumentGroteskMono-Medium.woff2') format('woff2'),
       url('fonts/Monument_Grotesk_Mono/ABCMonumentGroteskMono-Medium.woff') format('woff');
}
