html,
body {
  scroll-padding-top: 140px;
}

[data-whatintent='keyboard'] *:focus-visible {
  // avoid setting color so it fallsback to parent color
  outline: black 0.2rem solid;
  outline-offset: 0.1rem;
}

[data-whatintent='keyboard'] .focus-inverted,
[data-dark='true'],
.inverted {
  &:focus-visible,
  *:focus-visible {
    outline-color: white;
  }
}

.inverted-wrapper {
  > :focus-visible {
    outline-color: white;
  }
}

[data-whatintent='mouse'],
[data-whatintent='touch'] {
  *:focus {
    outline: transparent;
  }
}

.visually-hidden {
  position: absolute !important;
  height: 0.1rem;
  width: 0.1rem;
  overflow: hidden;
  clip: rect(0.1rem 0.1rem 0.1rem 0.1rem);
  white-space: nowrap;
  padding: 0;
}

.visible-on-focus {
  &:not(:focus):not(:active):not(:focus-within) {
    @extend .visually-hidden;
  }
}
