.product-selection {
  padding: 0 0 8rem 0;
  // min-height: calc(var(--view-height) - var(--header-h) - 1.2rem - 0.1rem);
  position: sticky;
  top: calc(var(--header-h) + 1.2rem);
  min-height: calc(var(--view-height) - var(--header-h) - 1.2rem - 0.1rem - 4rem);

  --col-offset: 15;
  --info-bottom: 4rem;
  --info-left: calc(#{var(--container-fill-half-padding)} + calc(#{var(--col-width)} * #{var(--col-offset)}) + calc(#{var(--container-gap)} * #{var(--col-offset)}));

  &.pinned {
    position: fixed;
    bottom: 4.2rem;
    width: var(--sticky-col-width);
    // left: var(--info-left);
    top: unset;
  }

  &__breadcrumb {
    margin-bottom: 4rem;
  }

  &__helpertext {
    margin-top: 0;
    &.sizesShown {
      margin-top: 0;
    }
    &.errors {
      margin-bottom: 1.2rem;
    }
  }

  &__maininfo {
    margin-bottom: 4rem;
  }

  &__line-labels {
    margin-bottom: 2.4rem;
  }

  &__price {
    margin-top: 4rem;
  }

  &__actions-shipping.on-pdp {
    margin-top: 4rem;
    gap: 1.2rem;
  }

  &__ctas {
    margin-bottom: 1.2rem;
  }
}
