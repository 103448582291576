footer {
  background-color: $content-black;
  color: $content-white;
  width: 100%;
  position: relative;
}

.footer {
  padding: $space-unit * 9 0;
  overflow: hidden;

  #footer-logo {
    height: 4.2rem;
    width: auto;
  }

  &-copyright {
    display: block;
    margin-top: 2rem;
    @extend %font__subtitle;
  }

  // * AREAS --------------------------------------------------------------------------

  &-area {
    margin-top: 8rem;

    &__app {
      margin-top: 6rem;
      display: flex;
      gap: 1.2rem;
      @extend %font__subtitle;
      a {
        @extend %font__cta;
        border-bottom: solid 0.1rem $content-white;
      }
    }

    &__newsletter {
      .newsletter-container {
        position: relative;
        display: flex;
        align-items: flex-end;
        gap: 2rem;
        &[data-error='true'] {
          .form-group {
            input {
              border-bottom-color: $alert-error;
            }
          }
        }
      }
      .newsletter-title {
        margin-bottom: 2rem;
        @extend %font__title;
      }
      .form-group {
        flex: 1;
        input {
          width: 100%;
        }
      }
      button {
        &[class*='primary'],
        &[class*='secondary'] {
          min-height: $button-height-desktop;
          padding: $button-padding-desktop;
        }
      }
      .invalid-feedback {
        position: absolute;
        left: 0;
        top: 100%;
        margin-top: 0.8rem;
        color: $alert-error;
      }
    }

    &__countryselector {
      margin-top: 6rem;
    }
  }

  // * COLLECTIONS --------------------------------------------------------------------------
  &-collections {
    row-gap: $space-unit * 6;
  }

  &-collection {
    &-title {
      @extend %font__title;
      display: block;
      margin-bottom: 2rem;
    }
    &__list {
      padding: 2.4rem 0 0;
      list-style: none;
      &:hover {
        .footer-collection__item {
          a,
          button span {
            color: $interactive-dark;
          }
          .icon {
            opacity: 0.6;
          }

          &:hover {
            a,
            button span {
              transition: all 0.3s ease-out;
              font-weight: 600;
              color: $content-white;
            }
            .icon {
              opacity: 1;
            }
          }
        }
      }
    }

    &__item {
      padding: 0;
      &:not(:last-child) {
        margin-bottom: 2rem;
      }

      a,
      button span {
        transition: all 0.3s ease-out;
        @extend %font__cta;
        color: $content-white;
        display: flex;
        align-items: center;
        gap: 0.8rem;
        .icon {
          transition: all 0.3s ease-out;
          width: 1rem;
          background-size: 2.4rem;
        }
      }
    }
  }

  // * COUNTRY --------------------------------------------------------------------------
  &-countrybutton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $content-white;
    width: 100%;
    text-decoration: none;
    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    &__content {
      @extend %font-light--uc;
    }
    &__title {
      @extend %font__title;
    }
  }

  // * PAYMENT LOGOS -----------------------------------------------------------
  .paymentslogos-list {
    .inline-list {
      gap: 0.4rem;
      li {
        width: 5.2rem;
        height: 3.4rem;
      }
    }
  }
}

// * MODALS --------------------------------------------------------------------
.newsletter-modal {
  &--subscription-modal.modal {
    // width: 91.6rem;
    // height: auto;
    // padding: 0;
    .modal {
      &__body {
        padding-top: 5.6rem;
      }
      &__header {
        display: none;
      }
      &__buttonrow {
        margin-top: 6rem;
      }
      &__title {
        margin-bottom: 2rem;
      }
    }

    .form-group--gender-radio {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
    }
  }

  &__subtitle {
    @extend %font__title;
    margin: 6rem 0 4rem;
  }

  &__radio-wrapper {
    padding-bottom: 3.2rem;
    margin-bottom: 4rem;
    border-bottom: solid 0.1rem $outline;

    .checkbox-wrapper {
      .checkbox {
        min-height: 4.4rem;
      }
    }

    label {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &__required-label {
    color: $content-black;
    margin-top: 1.2rem;
    margin-bottom: 2.4rem;
  }

  .button-row {
    margin: 6rem 0 0;
    button {
      width: 100%;
    }
  }

  &__legal-label {
    margin-top: 6rem;
    color: $interactive-light;
  }
}
