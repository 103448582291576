.menu {
  &-modal {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - #{var(--header-h)});
    & > nav.container {
      position: static;
      height: var(--submenu-height);
    }
    &__footer {
      position: static;
    }

    .submenu__wrapper {
      position: absolute;
      overflow: unset;
      bottom: unset;
      height: 100%;
    }
  }
  &-services {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
}

.subnav-filters {
  position: relative;
  &.isSticky {
    border: unset;
  }
}
.cart-container .tabs-list--wrapper {
  position: relative;
}

header {
  position: relative;
  &.header {
    &--pdp {
      position: relative;
    }
  }
}

main {
  margin-top: calc(-1 * #{var(--header-h)});
}
