.form-control {
  position: relative;
  margin-top: 0.8rem;

  &__reset-button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2.4rem;
    height: 2.4rem;
  }
}

.has-showpassword,
.has-buttons {
  display: flex;
  button,
  .show-password {
    @include border('bottom');
    padding: 0 0.4rem;
  }

  &:focus {
    border-color: #000;
  }
}

.is-valid {
  .has-showpassword.has-tick {
    .show-password {
      padding-right: 2.4rem;
    }
    .valid-tick {
      right: 0;
    }
  }
}

.form-select {
  position: relative;
  &::after {
    position: absolute;
    top: calc(50% + 0.4rem); //align for vertical dealignement padding, is 0.2rem but with translateY(-50%) become 0.4rem
    right: 0;
    transform: translateY(-50%);
    content: '';
    @include fixedSprite('10-down-chevron');
    pointer-events: none;
  }

  &[data-dark='true'] {
    &::after {
      @include fixedSprite('10-down-chevron-white');
    }
  }
}

.invalid-feedback {
  color: $alert-error;
  margin-top: 0.5rem;
  flex-shrink: 0;
  width: 100%;
  &::before {
    content: '';
    @include fixedSprite('10-alert');
    transform: translate(-0.2rem, -0.2rem);
    vertical-align: middle;
  }

  > div {
    display: inline;
  }
}

.form-group {
  input:not([type='radio'], [type='checkbox']),
  textarea,
  select {
    @include formControlInput;
  }

  textarea.blockview {
    min-height: 20rem;
    padding-left: 0.9rem;
    padding-right: 0.9rem;
  }

  input.blockview {
    background-color: $content-white;
    padding-left: 1.2rem;
    padding-right: 1.2rem;

    + button {
      background-color: $content-white;
      margin-left: -0.1rem;
      border-width: 0.1rem;
      border-left-width: 0;
      border-style: solid;
    }
  }

  &[data-dark='true'] {
    input:not([type='radio'], [type='checkbox']),
    textarea,
    select {
      color: $content-white;
      border-bottom: 0.1rem solid $interactive-light;
      &::placeholder {
        color: $interactive-dark;
      }

      &:focus,
      &:focus-visible {
        border-color: $content-white;
      }

      &.blockview {
        background-color: $content-black;
        border: 0.1rem solid $interactive-light;
      }
    }
  }

  select {
    color: $interactive-light;
    [data-dark='true'] & {
      color: $interactive-dark;
    }
  }

  &.is-valid {
    select,
    input,
    textarea {
      color: $content-black;
      border-bottom-color: $content-black;

      & ~ .show-password {
        border-bottom-color: $content-black;
      }
    }
  }

  input[type='radio'] {
    @include radio;
  }

  input[type='checkbox'] {
    @include checkbox;
  }

  input[data-dark='true'] {
    color-scheme: dark;
  }
  &__helper-text {
    margin-top: 0.8rem;
    display: block;
    @extend %font-medium--ul;
  }

  &__legend {
    display: block;
    padding: 0;
    text-align: left;
    text-transform: none;
  }

  &--flex {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    @include breakpoint(lg, min) {
      flex-direction: row;
      gap: 4rem;
    }

    .radio {
      display: flex;
      width: auto;
      min-height: unset;
    }
  }
}

form {
  .radio,
  .checkbox {
    min-height: $input-height-mobile;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .radio {
    min-height: $input-height-mobile;
    @media (min-width: get($grid-breakpoints, md)) {
      min-height: $input-height-desktop;
    }
  }
}

// textarea.form-control {
//   display: block;
//   height: ($space-unit * 7) * 4;
//   margin-bottom: 0;
// }
// .disable-resize {
//   resize: none;
// }

.valid-tick {
  content: '';
  right: $space-unit * 2;
  @include fixedSprite('10-correct', transparent, false);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}

[data-dark='true'] {
  .valid-tick {
    @include fixedSprite('10-correct-white', transparent, false);
  }
}

// .tick {
//   content: '';
//   @include fixedSprite('checkmark');
// }

.is-valid {
  .valid-tick {
    display: inline-block;
  }
}

// .form-button-container {
//   display: flex;
//   .button {
//     margin-top: 0 !important;
//   }
// }

// .show-password {
//   @extend %primary-xxs;
//   position: absolute;
//   margin-top: 1.1rem;
//   right: 1.1rem;
// }

.forgot-pw,
.forgot-pw-error__pw {
  margin-top: 0.8rem;
  display: block;
}

.form-required-label {
  color: $interactive-light;
}

.form-submit {
  gap: 0;
  &-dot {
    opacity: 0;

    &._1 {
      animation: dot-blink-1 2s linear infinite;
    }
    &._2 {
      animation: dot-blink-2 2s linear infinite;
    }
    &._3 {
      animation: dot-blink-3 2s linear infinite;
    }
  }
}

@keyframes dot-blink-1 {
  0% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes dot-blink-2 {
  0% {
    opacity: 0;
  }
  21% {
    opacity: 0;
  }
  22% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes dot-blink-3 {
  0% {
    opacity: 0;
  }
  43% {
    opacity: 0;
  }
  44% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
