.content-block {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .img-desk {
    display: none;
  }

  img {
    margin-bottom: 1.6rem;
    width: 100%;
    height: auto;
  }

  &-title {
    margin-bottom: 2rem;
  }

  &-description {
    margin-bottom: 3.2rem;
    flex: 1;
  }

  &-link {
    position: static;
    margin-bottom: 0.3rem; // for viewing outline in focus
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  @include breakpoint(lg, min) {
    img {
      margin-bottom: 2rem;
    }

    &-title {
      margin-bottom: 0.8rem;
    }

    &-title,
    &-description {
      max-width: 62rem;
    }

    .img-desk {
      display: block;
    }
    .img-mobile {
      display: none;
    }
  }
}
