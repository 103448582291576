//GENERAL STYLES --------------------------------------------------
@each $font-type, $font-data in $monument-grotesk-mono {
  %font-#{$font-type} {
    font-family: 'MonumentGroteskMono', Arial, sans-serif;
    &--uc {
      @extend %font-#{$font-type};
      text-transform: uppercase;
    }
    &--ul {
      @extend %font-#{$font-type};
      text-decoration: underline;
    }
    &--st {
      @extend %font-#{$font-type};
      text-decoration: line-through;
    }
  }
}

// %font-{weight}--{decoration}
// UC -> Uppercase
// UL -> Underline
// ST -> Line Through

//SPECIFIC STYLES -----------------------------------------------
%font__title {
  @extend %font-medium--uc;
  font-weight: 600;
}
%font__subtitle {
  @extend %font-regular--uc;
  font-weight: 400;
}
%font__cta {
  @extend %font-regular--uc;
  text-decoration: none;
  font-weight: 500;
}
%font__cta--ul {
  @extend %font-regular--uc;
  @extend %font-regular--ul;
}
%font__strike {
  @extend %font-regular--uc;
  @extend %font-regular--st;
}
%font__strike-price {
  @extend %font-light--st;
}
%font__copy {
  @extend %font-light;
}
%font__link {
  @extend %font-light--ul;
}
%font__labelling {
  @extend %font__copy;
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: none;
}
