.product-reserve--modal {
  .product-reserve {
    &__detail {
      &__line {
        display: flex;
      }
      .store-address {
        display: block;
      }
    }
  }

  .product-reserve-store {
    &__content {
      &__name {
        .store-distance {
          display: inline-block;
        }
        span + span {
          margin-left: 1rem;
        }
      }

      .store-info {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}
