@use 'sass:math';
$subnavfilterHeight: 3.6rem;
$subnavfilterHeightMobile: 5.2rem;

%refine_buttons {
  @extend %font__title;
  display: inline-block;
  height: $refine-options-height;
  cursor: pointer;
  // padding-top: 1.2rem;
  // padding-bottom: 0.8rem;
  // margin-right: ($space-unit * 4);
  @include clear-focus-visible;
  &:focus-visible {
    > span {
      @include focus-visible;
    }
  }
}

%buttons_open-close {
  padding-right: 2.4rem;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    @include fixedSprite('10-open');
  }

  &[aria-expanded='true'] {
    &::after {
      content: '';
      @include fixedSprite('10-minimize');
    }
  }
}

//MOBILE DEFAULT

.subnav-filters {
  position: sticky;
  top: 0;
  width: 100%;
  @include z-index('filters');

  &.isSticky {
    border-bottom: solid 0.1rem rgba(0, 0, 0, 0.1);
    background-color: $content-white;
  }

  ul {
    @include reset-list;
  }
}

.subnav-header {
  height: $subnavfilterHeightMobile;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  > ul {
    @include grid-row();
  }
}

.subnav-header/*,
.subnav-content*/, .subnav-sortby {
  background-color: $content-white;
}

.refine-button,
.sorting-button {
  @extend %refine_buttons;
}

.sortby-item {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: $half-gutter-width;
  .sorting-button {
    @extend %refine_buttons;
    margin-right: 0;
  }
}

.subnav-button {
  @extend %buttons_open-close;
}

.subnav-content {
  ul {
    @include reset-list;
    .checkbox .checkbox__field {
      align-items: center;
    }
  }
  &-drop {
    position: absolute;
    right: 0;
    width: 100%;
    .subnav-content-fixed {
      padding-top: 4rem;
      padding-bottom: 8rem;
      max-height: calc(100vh - $subnavfilterHeightMobile);
      overflow: hidden;
    }
    &.subnav-refinements-content .subnav-content-fixed {
      height: calc(100vh - $subnavfilterHeightMobile);
    }
    z-index: 0;
  }
  [aria-expanded='true'] + &-drop {
    z-index: 1;
  }
}

.subnav-filters .subnav-refinements {
  display: flex;
  &-container {
    @extend .container--fluid;
    position: absolute;
    left: 0;
  }
  // &-action {
  //   position: relative;
  //   z-index: 1;
  // }
  &-list {
    > ul {
      display: grid;
      z-index: 0;
      column-gap: $grid-gutter-width;
      grid-template-columns: repeat(8, 1fr);
      padding-left: math.div($grid-gutter-width, 2);
    }
  }

  &_clear-all {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
  }
}

.subnav-filters-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $overlay-modal;
  top: 0;
  z-index: -1;
  opacity: 0;
  cursor: pointer;
  @include animate(0.2, 'opacity');
  &.show {
    z-index: 1;
    opacity: 1;
  }
}
#for-overlayposition {
  height: 0;
  overflow: hidden;
  line-height: 0;
  padding-top: 0.7rem;
  display: none;
}
