// set default color of button text (override browsers one)
button,
.button {
  color: $content-black;
  &.inverted {
    color: $content-white;
  }
}

$primary: '.button--primary';
$secondary: '.button--secondary';
$tertiary: '.button--tertiary';

@mixin buttonFocus() {
  &:focus-visible,
  &.focus {
    outline-color: $content-black;
    outline-style: outset;
    outline-offset: 0.2rem;
    outline-width: 0.3rem;
  }
}

.button {
  position: relative;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  @include animate(0.2);
  cursor: pointer;
  gap: 0.8rem;

  span {
    @extend %font__cta;
    @include animate(0.2);
  }

  &:disabled {
    cursor: none;
    pointer-events: none;
  }

  @include buttonFocus();

  // PRIMARY & SECONDARY ----------------------------------------------------------
  &[class*='primary'],
  &[class*='secondary'] {
    min-height: $button-height-mobile;
    padding: $button-padding-mobile;
    text-align: center;

    &.full {
      width: 100%;
    }
  }

  // PRIMARY ------------------------------------------------------------
  &--primary,
  &--primary:link,
  &--primary:visited {
    background-color: $content-black;
    color: $content-white;

    // &:focus,
    // &:focus-visible,
    // &.focus {
    //   border: 0.1rem solid $content-white;
    // }

    &:disabled {
      background-color: $disabled-light;
    }

    @media (hover: hover) {
      &:hover,
      &.hover {
        background-color: $interactive-light;
      }
    }

    &.inverted {
      background-color: $content-white;
      color: $content-black;
      &:focus,
      &:focus-visible,
      &.focus {
        border: 0.1rem solid $content-black;
      }

      &:disabled {
        background-color: $disabled-dark;
      }

      @media (hover: hover) {
        &:hover,
        &.hover {
          background-color: $interactive-dark;
        }
      }
    }
  }

  // SECONDARY ------------------------------------------------------------
  &--secondary,
  &--secondary:link,
  &--secondary:visited {
    color: $content-black;
    border: $space-line solid;
    border-color: $content-black;
    background-color: $content-white;

    &:disabled {
      border-color: $disabled-light;
      span {
        color: $disabled-light;
      }
    }
    @media (hover: hover) {
      &:hover,
      &.hover {
        border-color: $interactive-light;
        span {
          color: $interactive-light;
        }
      }
    }

    &.inverted {
      color: $content-white;
      border-color: $content-white;
      background-color: $content-black;

      &:focus,
      &.focus {
        outline-color: $content-white;
      }

      &:disabled {
        border-color: $disabled-dark;
        span {
          color: $disabled-dark;
        }
      }

      @media (hover: hover) {
        &:hover,
        &.hover {
          border-color: $interactive-dark;
          span {
            color: $interactive-dark;
          }
        }
      }
    }
  }

  // TERTIARY -------------------------------------------------------------
  &--tertiary,
  &--tertiary:link,
  &--tertiary:visited {
    color: $content-black;
    border-bottom: $space-line solid;
    border-bottom-color: $content-black;
    min-height: 1.9rem;

    // &:focus,
    // &.focus {
    //   border-bottom: calc($space-line * 2) solid;
    // }

    &:disabled {
      border-bottom-color: $disabled-light;
      span {
        color: $disabled-light;
      }
    }

    @media (hover: hover) {
      &:hover,
      &.hover {
        border-color: $interactive-light;
        span {
          color: $interactive-light;
        }
      }
    }

    &.inverted {
      color: $content-white;
      border-bottom-color: $content-white;

      &:disabled {
        border-bottom-color: $disabled-dark;
        span {
          color: $disabled-dark;
        }
      }

      @media (hover: hover) {
        &:hover,
        &.hover {
          border-color: rgba($content-white, 0.8);
          span {
            color: rgba($content-white, 0.8);
          }
        }
      }
    }
  }

  // QUATERNARY -------------------------------------------------------------
  &--quaternary,
  &--quaternary:link,
  &--quaternary:visited {
    @extend .button--tertiary;
    border: none;

    .icon[class*='icon--16'] {
      vertical-align: middle;
      transform: translateY(-0.1rem);
      margin-right: 0.8rem;
    }
  }

  // ICON -----------------------------------------------------------------
  &--icon,
  &--icon:link,
  &--icon:visited {
    @extend #{$secondary};
    min-width: $button-height-mobile;
    min-height: $button-height-mobile;
    padding: 0;

    &.hideborder {
      border-color: transparent;
    }

    &.issmall {
      min-width: 2.4rem;
      min-height: 2.4rem;
    }

    .icon {
      vertical-align: bottom;
    }

    @media (hover: hover) {
      &:hover,
      &.hover {
        .icon {
          opacity: 0.6;
        }
      }
    }

    &:disabled {
      .icon {
        opacity: 0.4;
      }
    }
  }

  // BUTTON LINK ----------------------------------------------------------
  &--link,
  &--link-underline {
    justify-content: left;

    .icon[class*='icon--10'] {
      vertical-align: bottom;
      transform: translateY(0.2rem);
      margin-left: -0.8rem;
    }

    &.inverted {
    }
  }

  &--link-underline {
    text-decoration: underline;
  }

  //  IMAGE UPLOAD -----------------------------------------------------------
  &--fileupload {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1.2rem;
    border: 0.1rem solid $content-black;
    padding: 1.2rem 1.2rem 1.2rem 3.4rem;
    width: 100%;

    input {
      position: absolute;
      opacity: 0;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      &:hover {
        cursor: pointer;
      }
    }

    .label {
      flex: 1;
      display: flex;
      flex-direction: column-reverse;
      @extend %font__cta;
      padding-right: 3.2rem;

      @include breakpoint(lg, min) {
        flex-direction: row;
        gap: 0.4rem;
      }
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0.5rem;
      top: 1rem;
    }
    &.image-button-add {
      &::before {
        @include fixedSprite('10-open');
      }
    }
    &.image-button-remove {
      &::before {
        @include fixedSprite('10-correct');
      }
    }
    .tooltip {
      position: absolute;
      right: 0.6rem;
      top: 0.9rem;
    }
    .icon--tooltip {
      @include fixedSprite('10-info');
    }
  }

  //  PAUSE -----------------------------------------------------------
  .button--pause {
    @extend .button--quaternary;
    align-items: center;
    .icon {
      margin-right: 0;
    }
  }
}

@keyframes ripple {
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    transform: scale(1);
    opacity: 0;
  }
}
