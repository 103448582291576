.product-hero,
.product-hero--placeholder {
  padding-top: 1.2rem;
  // margin-bottom: 8rem;

  // min-height: calc(var(--view-height) - var(--header-h)); // padding-bottom: $product-ratio;
  // overflow: hidden;
  background-color: $content-white;
  position: relative;
  z-index: 1;
}

.product-hero {
  &__col {
    &--sticky {
      z-index: 1;
      background-color: $content-white;
    }
  }
}

.cursor {
  display: none;
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  @include z(cursor);
  @include animate;
  &--hidden {
    @extend .cursor;
    opacity: 0;
  }
}

.th-player-external-element .th-controls,
.th-player .th-controls {
  display: none !important;
}

.product-image--wrapper {
  width: 100%;
  max-height: calc(#{var(--viewport-height)} - #{var(--header-h)} - 1.2rem - 5.2rem);
  aspect-ratio: 3/4;
  @supports (height: 100svh) {
    max-height: calc(100svh - #{var(--header-h)} - 1.2rem - 5.2rem);
  }

  .product-image {
    max-height: calc(#{var(--viewport-height)} - #{var(--header-h)} - 1.2rem - 5.2rem);
    @supports (height: 100svh) {
      max-height: calc(100svh - #{var(--header-h)} - 1.2rem - 5.2rem);
    }
  }

  .product-image-button {
    height: 100%;
    width: 100%;
    cursor: url(./../../media/zoom-in-cursor.svg) 20 20, zoom-in;
  }

  @include breakpoint(lg, max) {
    img {
      height: 100%;
      object-fit: cover !important;
    }
  }
}

.product-hero-gallery {
  position: relative;
  overflow: hidden;
  list-style: none;

  li {
    padding-top: 0;
    padding-bottom: 0;
  }

  &::before {
    content: '';
    background: linear-gradient(181.75deg, rgba(0, 0, 0, 0.65) 1.48%, rgba(0, 0, 0, 0.1) 65.72%, rgba(0, 0, 0, 0) 84.39%);
    opacity: 0.65;
    transform: rotate(-180deg);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 10.3rem;
    z-index: 2;
    pointer-events: none;
  }

  .product-image img {
    object-position: center;
    object-fit: contain;
  }
  .swiper-slide {
    pointer-events: none;

    &.swiper-slide-active {
      pointer-events: auto;
    }
  }

  .swiper-pagination {
    opacity: 1;
    display: flex;
    align-items: center;

    padding: 0 1.2rem;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;
    gap: 1.2rem;

    &.swiper-pagination-bullets {
      bottom: 2rem;
    }
  }

  .swiper-pagination-bullet {
    flex: 1;
    height: 0.2rem;
    border-radius: 0;
    background: $outline;
    opacity: 1;
    position: relative;
    &.swiper-pagination-bullet-active {
      background: $content-black;
    }
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 3rem;
    }
  }
}

.product-hero__anchorscroll {
  display: flex;
  align-items: flex-end;
  position: sticky;
  top: calc(var(--header-h) + 1.2rem);
  min-height: calc(var(--view-height) - var(--header-h) - 1.2rem - 0.8rem);
  a {
    display: inline-flex;
    @include buttonFocus();
  }
}

//CSS FIXES FOR GSAP EFFECT IN PDP
#product-show {
  // position: static;
  // main {
  //   position: static;
  // }
  footer,
  .footer-prefooter {
    position: relative;
    z-index: 2;
  }
}

#search-show {
  footer,
  .footer-prefooter {
    position: relative;
    z-index: 2;
  }
}

.skip-productgallery {
  position: absolute;
  top: 0;
  z-index: 1;
}
