.login-registration {
  margin-top: var(--header-h);
  padding-top: 6rem;
  padding-bottom: 10rem;

  &__sectiontitle {
    @extend %font__title;
    padding-bottom: 2rem;
  }

  .myaccoint__title {
    padding-bottom: 1.2rem;
    @include checkoutSmallSpacing('bottom', true);
    @include border(bottom);
    @extend %font__title;
  }

  .react-tabs__tab-panel {
    > .tab-inner {
      padding: 3rem 0;
    }
  }

  .form-group + .form-group {
    @include checkoutSmallSpacing;
  }

  .button-cta-passwordlogin {
    @include checkoutSmallSpacing('top', true);
  }

  .login-fields-wrapper {
    @include checkoutSpacing('bottom');
    @include checkoutSpacing('top');
  }

  .remember-me {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 5.2rem;
    margin-top: 3.2rem;
  }

  //STATIC BENEFIT LIST
  &__registration--static {
    p + ul {
      @include checkoutSmallSpacing;
    }

    ul li {
      margin-top: 0.8rem;
    }
  }

  //login page
  & &__login {
    &,
    form {
      display: flex;
      flex-direction: column;
      gap: 4rem;
    }

    &-social {
      .row + .row {
        margin-top: 4rem;
      }
    }

    &-other {
      margin-top: 4rem;
    }
  }
}
