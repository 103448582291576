.footer {
  padding: 6rem 0 2rem;
  // * AREAS --------------------------------------------------------------------------
  &-area {
    &__logo {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 11.3rem;
      .row {
        flex: 1;
        [class^='col-'] {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start;
        }
      }
    }

    &__newsletter {
      margin-top: 0 !important;
    }
    &__app {
      margin-top: 4rem;
    }
  }

  // * COUNTRY --------------------------------------------------------------------------
  &-countrybutton {
    &:not(:last-child) {
      margin-bottom: 0.8rem;
    }
  }

  // * MODALS --------------------------------------------------------------------
  .newsletter-modal {
    &__legal-label {
      margin-top: 2rem;
    }
  }

  // * PAYMENT LOGOS -----------------------------------------------------------
  .paymentslogos-list {
    .inline-list {

      li {
        height: 3.2rem;
        width: 5rem;
      }
    }
  }
}

// * MODALS --------------------------------------------------------------------
.newsletter-modal {
  &--subscription-modal.modal {
    .form-group--gender-radio {
      flex-direction: row;
      flex-wrap: wrap;
      .radio {
        min-width: 34%;
        width: auto;
      }
    }
  }
  &__radio-wrapper {
    padding-bottom: 4rem;
    .checkbox-wrapper {
      .checkbox {
        min-height: 2.4rem;
        &:not(:last-child) {
          margin-bottom: 0.8rem;
        }
      }
    }

    &__newsletter {
      margin-top: 0 !important;
    }
    &__app {
      margin-top: 4rem;
    }
  }

  .form-group--gender-radio {
    // * COUNTRY --------------------------------------------------------------------------
    &-countrybutton {
      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }
    }

    // * MODALS --------------------------------------------------------------------
    .newsletter-modal {
      &__legal-label {
        margin-top: 2rem;
      }
    }
  }
}
