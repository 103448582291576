.checkout-packaging {
  @include checkoutSpacing('top', true);
  @include checkoutSpacing();
  @include border('top');

  .col-24 + .col-24 {
    padding-top: 2.4rem;
  }

  .checkout-subtitle {
    margin-top: 0;
    margin-bottom: 0;
    & + .checkout-packaging__intro {
      margin-top: 2.4rem;
    }
  }

  &__intro {
    padding: 0;
  }

  .back-button {
    align-self: center;
    display: none;
  }

  &__message {
    @include checkoutSmallSpacing();

    &__aspect-ratio {
      z-index: -1;
      pointer-events: none;
      padding-bottom: 53.4%;
    }

    &__field {
      position: relative;
      margin-bottom: 2.4rem;

      &__background {
        display: flex;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 100%;
        align-items: center;
        justify-content: center;
        position: absolute;
        @include z;
        .message-button {
          padding: $space-unit * 4;
          @include checkoutSpacing();
          height: 100%;
          width: 100%;
          word-break: break-word;
          position: absolute;
          overflow-y: auto;

          &:focus {
            @include focus-underline;
          }
        }
        .message-button-row {
          margin-top: ($space-unit * 2);
          &__right-button {
            text-align: right;
          }
        }
      }

      .form-control {
        text-align: center;
        position: relative;
        input,
        textarea {
          padding: 1.1rem 2rem 0.9rem;
        }
        textarea {
          height: 6.8rem;
        }
      }
    }
    .checkbox {
      margin-bottom: $space-unit;
    }
    input[name='emailGift'] {
      margin-bottom: 0;
    }
    &__box {
      margin-top: $space-unit;
      padding-right: 1.6rem;
    }
    .button--secondary {
      margin-bottom: 2.4rem;
    }
  }

  .option-packaging {
    &__detail {
      .packaging-info {
        display: inline-block;
        vertical-align: middle;
      }
    }

    &__img {
      background: $outline;
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .mini-link {
    text-decoration: underline;
  }

  .optionbox {
    margin: 0;
  }

  .optionbox__wrapper {
    gap: 0.8rem;
  }

  .optionbox__item {
    width: auto;
    flex-grow: unset;
    flex-basis: unset;

    display: flex;
    gap: 2.8rem;
    align-items: center;
    padding-left: 0;
    padding-right: 0;

    &__radio {
      position: relative;
      top: unset;
      right: unset;
    }
  }

  .icon--info {
    @include fixedSprite('10-info');
  }

  &__options {
    padding-top: 4rem;
    .optionbox {
      gap: 1.6rem;
    }
  }

  .optionbox__wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 2rem;
    .checkbox {
      padding-left: 1.8rem;
      margin-bottom: 3.2rem;
      margin-top: 0.8rem;
    }
  }

  .gift-card-image {
    width: 100%;
  }

  .charleft {
    display: block;
    margin-bottom: 1.6rem;
    // color: $black_secondary;
  }

  // .packaging-info {
  //   padding-left: 0.6rem;
  // }

  .checkout-navigations {
    @include checkoutSpacing();
    @include border('top');
  }
}
.checkout-packaging-title {
  margin-bottom: 1.6rem;
}
.message-form {
  .col-24 {
    margin-top: 4rem;
  }
}

[data-whatintent='keyboard'] {
  .message-button {
    &:focus {
      outline: 1px solid black;
      outline-offset: 10px;
    }
  }
  .checkout-packaging {
    [role='radio'] {
      &:focus {
        .option-packaging__name {
          text-decoration: underline;
        }
      }
    }
  }
}
.overlay-modal.packaging-modal {
  overflow: auto;
}

.container-mail {
  @include checkoutSpacing('top', true);
}
