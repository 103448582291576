.cs-hero {
  &-topics {
    > ul {
      > li {
        display: none;
      }

      li:first-child {
        display: inline-block;
        & + li {
          display: inline-block;
          & + li {
            display: inline-block;
          }
        }
      }
    }
  }
}
