.loadmore {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;

  .loadmore__inner {
    max-width: 100%;
    width: 46rem;
    padding: 2rem 0;
  }
}
