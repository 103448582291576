.navigation {
  display: flex;
  align-items: center;
  gap: 0.8rem;

  &.inverted {
    filter: invert(100%);
  }

  &.opacityOnDisabled {
    [aria-disabled='true'] {
      opacity: 0.2;
    }
  }
}
