.cookie-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 4.8rem 0 2.4rem;
  background-color: $content-white;
  box-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.1);
  @include vh(max-height);
  overflow: auto;
  @include z('cookiebar');
  .icon--close {
    position: absolute;
    top: 1.2rem;
    right: 0.8rem;
    z-index: 1;
  }
  .cookie-msg {
    margin-bottom: 2.4rem;
  }
  .row {
    align-items: center;
  }
}
.cookie-center {
  &.modal {
    padding-bottom: 0;
  }

  .buttons-wrapper {
    background-color: white;
    z-index: 1;
    padding-bottom: 3.2rem;
    .button {
      margin-top: 1.2rem;
    }
  }
  .modal__body {
    .dialog__description {
      margin-top: 1.6rem;
      padding-bottom: 2.4rem;
      width: 100%;
      @include border('bottom');
      .row {
        align-items: flex-end;
      }
      .button {
        margin-top: 1.6rem;
      }
    }
  }
  &__form {
    > button {
      margin-bottom: 1.2rem;
    }
  }
  .cookie-center__columns {
    margin-top: 1.6rem;
    .cookie-item {
      margin-bottom: 3.2rem;
    }
  }
  .cookie-row {
    grid-area: row;
  }
  .cookie-item {
    display: grid;
    grid-template-areas:
      'title switch'
      'desc desc';
    align-items: end;
    row-gap: 1.6rem;
    h3 {
      font-family: var(--fontlight);
    }
    .cookie-switch {
      position: relative;
      justify-self: end;
      .divisor {
        padding: 0 $space-unit * 2;
      }

      input {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        margin: 0;
        padding: 0;
        opacity: 0;
        cursor: pointer;
        appearance: initial;
        -webkit-appearance: initial;
        border-radius: initial;
        @include z;
        &:not(:checked) {
          + .yes-or-no {
            .yes {
              opacity: 0.55;
            }
          }
        }
        &:checked {
          + .yes-or-no {
            .no {
              opacity: 0.55;
            }
          }
        }
      }
    }
    > p {
      grid-area: desc;
    }
  }
}
.overlay-modal.cookie-overlay {
  @include z('cookieoverlay');
}
html[data-whatinput='keyboard'] {
  .cookie-switch {
    input:focus {
      + .yes-or-no {
        outline: 0.1rem solid $outline;
        outline-offset: 0.4rem;
      }
    }
  }
}
