.product-image--animate {
  position: relative;
  background: $background-product;
  overflow: hidden;
}
.product-image {
  @include fixedRatio;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: $background-product;
  .wrapper-product-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(1);
  }
}
.product-video-pause {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  span {
    @extend %font__cta;
  }
  .product-video-wrapper & {
    position: absolute;
    right: 1.2rem;
    bottom: 3.2rem;
    @include z-index('overlay');
  }
}
