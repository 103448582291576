.tooltip {
  position: relative;
  display: flex;
  align-items: center;

  button::before {
    content: '';
    position: absolute;
    top: -1.6rem;
    left: 50%;
    transform: translate(-50%, 100%);
    width: 0;
    height: 0;
    border-left: ($space-unit * 2) solid transparent;
    border-right: ($space-unit * 2) solid transparent;
    border-top: ($space-unit * 2) solid $content-white;
    z-index: 1;
    visibility: hidden;
  }

  button[aria-expanded='true'] {
    + .tooltip__box {
      opacity: 1;
      visibility: visible;
    }
    &::before {
      visibility: visible;
    }
  }

  button {
    &.tooltip__button:focus-visible {
      @include focus-visible;
    }
  }

  .tooltip__box {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(100% + (#{$space-unit} * 2));
    padding: ($space-unit * 5) ($space-unit * 10) ($space-unit * 5) ($space-unit * 5);
    opacity: 0;
    visibility: hidden;
    background-color: $content-white;
    white-space: pre-line;
    min-width: 25rem;
    @include animate(0.15);

    button.mini-link {
      text-align: left;
      white-space: nowrap;
    }

    .tooltip__close {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      color: $content-white;
    }
  }

  &.inverted {
    button::before {
      border-top: ($space-unit * 2) solid $content-black;
    }

    .tooltip__box {
      background-color: $content-black;
      color: $content-white;
    }
  }
}
