.footer--checkout {
  .wrapper-footer {
    display: flex;
    gap: calc(1.2rem + 3.7%);
    .footer-item {
      flex-basis: 25%;
      & + .footer-item {
        padding-top: 0;
      }
    }
  }
}
