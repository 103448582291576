.component_introlist {
  ul {
    @include reset-list;
  }

  .secondlevel-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 4rem;

    .secondlevel-item > .component_introlist__name {
      @extend %font__title;
      text-decoration: none;
    }
  }

  .component_introlist__name + ul {
    margin-top: 2rem;
  }
}
