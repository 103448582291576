.shopthelookpanel {
  .shopthelookpanel-link + .product-tile__price {
    display: none;
  }
  .shopthelookpanel-item .product-tile__info .product-tile__quick-buy {
    flex-direction: row;
    background-color: $content-white;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: solid 0.1rem #ccc;
    .product-tile__price {
      display: block;
      margin: 0;
      .product-price {
        margin: 1.9rem auto 1.5rem 1.2rem;
        &.discount {
          margin: 0.9rem 1.2rem;
        }
      }
    }
  }
}
