.account-appointments {
  .fieldset--plain {
    .form-group {
      flex-direction: row;
    }
  }

  .button-row {
    flex-direction: row;
    text-align: left;
  }

  .remove-appointment {
    padding-top: 6rem;
  }
  .detail-wrapper {
    form {
      .button-row {
        padding-bottom: 2rem;
      }
    }
  }
}

.modal.bookanappointment {
  .button-row {
    margin-top: 2rem;
  }
}
