.selector-color .selector__menu__item .swatch,
.swatch {
  display: block;
  background-color: $content-black; // default per evitare swatch trasparenti quando ci sono valori errati nel catalogo
  background-size: cover;
  border: $space-line solid transparent;

  &--filter {
    width: 3.2rem;
    height: 1rem;
    display: inline-block;
    margin-right: 0.8rem;
  }

  &--black {
    background-color: black;
    // border: $space-line solid white; // no, solo se è sul nero, è controllato dalla classe inverted del padre
  }
  &--blue {
    background-color: rgb(52, 49, 58);
  }
  &--green {
    background-color: rgb(85, 78, 64);
  }
  &--red {
    background-color: rgb(192, 38, 58);
  }
  &--orange {
    background-color: rgb(218, 74, 47);
  }
  &--pink {
    background-color: rgb(206, 169, 162);
  }
  &--purple {
    background-color: rgb(76, 56, 100);
  }
  &--white {
    background-color: white;
    border: $space-line solid rgba(0, 0, 0, 0.1);
  }
  &--yellow {
    background-color: rgb(255, 183, 0);
  }
  &--grey,
  &--gray {
    background-color: rgb(123, 124, 126);
  }
  &--beige {
    background-color: rgb(202, 192, 174);
  }
  &--miscellaneous {
    background-color: grey;
  }
  &--brown {
    background-color: rgb(81, 58, 56);
  }
  // TODO fix label navy
  &--Navy,
  &--navy {
    background-color: navy;
  }
  &--silver {
    background-color: rgb(153, 157, 156);
  }
  &--fuxia,
  &--fucshia {
    background-color: rgb(196, 41, 121);
  }

  &--gold {
    background-color: rgb(200, 178, 115);
  }

  &--havana {
    background-color: rgb(139, 90, 63);
  }

  &--violet {
    background-color: rgb(100, 51, 130);
  }

  // TODO fix label for multicolor
  &--null,
  &--multicolor,
  &--multicolour,
  &--multicoloured {
    background: linear-gradient(90deg, #962525 0%, #496ea6 34.09%, #68987e 65.86%, #dbb670 100%);
  }

  &.inverted {
    background-color: $content-white;

    &.swatch--black {
      border: $space-line solid white;
    }
    &.swatch--white {
      border: $space-line solid transparent;
    }
  }
}

.disabled {
  .swatch {
    opacity: 0.7;
  }
}
