.tooltip {
  .tooltip__box {
    left: initial;
    right: -1.2rem;
    transform: translateX(calc(var(--tooltip-offset)));
    &::before {
      left: initial;
      right: 0.8rem;
    }
  }
}
.tax-refund-container {
  .tooltip {
    .tooltip__box {
      left: auto;
      right: 50%;
      transform: translate(50%);
      &::before {
        left: auto;
        right: 50%;
        transform: translate(50%, 100%);
      }
    }
  }
}
