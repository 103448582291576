@each $name, $uri in $sprites {
  .icon--#{$name} {
    @include fixedSprite($name);
  }
}
// override gpay
// .icon--payment-paywithgoogle {
//   height: 2rem;
//   width: 3.8rem;
//   background-size: 140%;
// }
[class^='icon--'] {
  border: none;
  min-width: initial;
  padding: 0;
  // height: auto;
  > span,
  > div {
    @extend .visually-hidden;
  }
}

.icon.auto-size {
  background-size: cover;
  &[class*=' icon--10'] {
    width: 1rem;
  }
  &[class*=' icon--16'] {
    height: 1.6rem;
  }
}
