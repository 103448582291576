.selector-color {
  // .selector__menu {
  //   &:not(:hover) {
  //     .selector__menu__item.selected {
  //       .selector__menu__item__label {
  //         display: none;
  //       }
  //     }
  //   }
  //   .focused {
  //     .selector__menu__item {
  //       &.selected {
  //         .selector__menu__item__label {
  //           display: none;
  //         }
  //       }
  //       &:focus {
  //         .selector__menu__item__label {
  //           display: inline-block;
  //         }
  //       }
  //     }
  //   }
  // }
  // .selector__menu__item {
  //   .selector__menu__item__label {
  //     position: absolute;
  //     display: none;
  //     right: 0;
  //     white-space: nowrap;
  //     padding-left: 0.8em;
  //     transform: translateX(100%);
  //   }
  //   &:hover {
  //     .selector__menu__item__label {
  //       display: inline-block;
  //     }
  //   }
  // }

  &.inpdp {
    margin-bottom: 2rem;
    .selector__menu {
      ul {
        gap: 0.6rem;
      }
    }
  }

  &-dropdown {
    margin-bottom: 2rem;
    .selector__menu {
      &__item {
        padding-top: 1rem;
        padding-bottom: 1rem !important;
      }
    }
    .selector-color {
      margin-bottom: 0;
      .selector__menu ul {
        gap: 0.8rem;
      }
    }
  }
}
