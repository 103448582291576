.checkout-main-title {
  @include visually-hidden;
}

//SPACES

.address_form,
.billingAddressForm {
  .row + .row {
    @include checkoutXSmallSpacing();
  }
  .col-24 + .col-24 {
    padding-top: 0;
  }
}
