/*
  used in client service as a experience or in PDP as a component in contact us modal
*/
.getintouch {
  &--expanded {
    padding: 2.8rem 0 4rem;

    #contact-us {
      margin-top: 6rem;
      width: 100%;
    }
  }

  &--collapsed {
    padding: 2.8rem 0;
    border-bottom: solid 0.1rem $outline;

    .getintouch__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1.2rem;
    }
  }

  &--blocks {
    .getintouch {
      &__wrapper {
        gap: 6.4rem;
      }
      &__blocks-row {
        row-gap: 6rem;
        width: 100%;
      }

      &__contact-block {
        height: 100%;
        display: flex;
        flex-direction: column;

        &-title {
          margin-bottom: 2rem;
          display: flex;
          flex-wrap: wrap;
          gap: 0.8rem;

          .tag-status {
            &--online {
              color: $alert-success;
            }
            &--offline {
              color: $interactive-light;
            }
          }
        }

        &-description {
          margin-bottom: 2rem;
          flex: 1;
        }
      }
    }
  }

  &__contact-ROWS + &__contact-ROWS {
    margin-top: 6rem;
  }

  &__contacts {
    list-style: none;
    display: flex;
    flex-direction: column;
    column-gap: 2.4rem;
    row-gap: 1.6rem;
    padding: 0;
    margin: 0;
    li {
      padding: 0;
    }
  }

  &__cs-section {
    &.client-service__section {
      padding: 4rem 0 6rem;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  @include breakpoint(lg, min) {
    &--expanded {
      padding: 4rem 0;

      #contact-us {
        width: handle-cols(4, lg);
        margin-top: 0;
      }
    }

    &--collapsed {
      padding: 4rem 0 2.8rem;

      .getintouch__row {
        align-items: flex-start;
      }
    }

    &--blocks {
      .getintouch {
        &__wrapper {
          gap: 10rem;
        }
        &__blocks-row {
          row-gap: 4rem;
        }

        &__contact-block {
          &-description {
            margin-bottom: 3.2rem;
          }
        }
      }
    }

    &__contact-COLUMNS {
      .getintouch {
        &__contact-block {
          &-info {
            flex-grow: 1;
          }
        }
      }
    }

    &__contact-ROWS {
      .getintouch {
        &__contact-block {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &-info {
            flex-grow: 1;
            max-width: 30.4rem;
          }

          .button {
            max-width: 22.5rem;
          }
        }
      }
    }

    &__contact-ROWS + &__contact-ROWS {
      margin-top: 4rem;
    }

    &__row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 1.2rem;

      &:not(:first-child) {
        align-items: center;
      }
    }

    &__contacts {
      flex-direction: row;
      align-items: center;
      column-gap: 2.4rem;
      row-gap: 1.2rem;
      flex-wrap: wrap;
    }

    &__wrapper {
      gap: 4rem;
    }

    &__select {
      flex-shrink: 0;
      .form-group {
        display: flex;
        gap: 1.2rem;
        justify-content: flex-end;

        .form-select {
          width: handle-cols(4, lg);
          transform: translateY(-1rem);
        }
      }
    }
  }

  @include breakpoint(xxl, min) {
    &__select {
      .form-group {
        .form-select {
          width: handle-cols(3, xxl);
        }
      }
    }

    #contact-us {
      width: handle-cols(3, xxl);
    }
  }
}
