.in-drawer {
  &.login-modal {
    .modal__body {
      padding-top: 7rem;
    }
  }

  &.login-modal,
  &.minibag-modal__wrapper {
    .button-login-cta,
    .button-cta-passwordlogin {
      margin-top: 6rem;
    }

    h2 {
      margin-bottom: 6rem;
    }
  }
}
