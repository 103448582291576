.sales {
  &-intro-text {
    padding: 6rem 0;
  }

  &-form {
    padding: 2.5rem 0 8rem;
    &-submit {
      margin-top: 4.8rem;
    }

    &-checks {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
      margin-top: 4.8rem;
    }
    &-otp-group {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
      .row {
        row-gap: 2.4rem;
      }
    }
    .form-group__helper-text {
      text-decoration: none;
      margin-top: 2rem;
      color: $interactive-light;
    }

    .phonewithprefix-label {
      padding-top: 4.8rem;
    }

    .checkbox {
      min-height: 2.4rem;
    }

    .form-control {
      input:disabled {
        border-color: $interactive-dark;
      }
    }
  }

  &-thank-you {
    padding: 6rem 0 0;

    &-buttons {
      row-gap: 2rem;
      padding: 5.6rem 0 4.4rem;
    }
  }
}

@include breakpoint(lg, min) {
  .sales {
    &-intro-text {
      padding: 12rem 0 6rem;
    }

    &-form {
      padding: 0.8rem 0 10rem;
      &-submit {
        margin-top: 5.2rem;
      }

      &-checks {
        margin-top: 3.3rem;
      }

      &-otp-group {
        gap: 0;
        &--label {
          margin: 4rem 0 0.8rem;
        }
        .form-control {
          margin-top: 0;
        }
      }

      .phonewithprefix-label {
        padding-top: 4rem;
      }
    }

    &-thank-you {
      padding: 12rem 0;

      &-buttons {
        padding: 5.2rem 0 12rem;
      }
    }
  }
}
