.component-details {
  padding-bottom: 12rem;

  &__images {
    margin-bottom: 7.6rem;
  }

  &__title {
    margin-bottom: 4rem;
  }

  &--extended {
    position: relative;
    padding: 8rem 0 14rem;

    .accordion {
      &__panel > div {
        padding: 4rem 0 4rem;
      }

      &__item:not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    .component-details {
      &__title {
        margin-bottom: 0;
        &.single {
          margin-bottom: 4rem;
        }
        &--wrapper {
          &:not(.single) {
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            pointer-events: none;
            padding-right: 0.6rem;
          }
        }
      }
      &__images {
        margin-bottom: 3.2rem;
        &.single {
          margin-bottom: 7.6rem;
        }
      }

      &__content {
        max-width: 83.6rem;
      }
      &__contents {
        position: relative;
      }
    }
  }
}
