@mixin baseFontSize() {
  font-size: 1.4rem;
  list-style: 1.6rem;
  @extend %font__copy;
}

.checkoutpage {
  #component-container {
    .adyen-checkout__button.adyen-checkout__button--pay {
      display: none;
    }
  }

  .adyen-checkout__input-wrapper {
    @extend .form-control;
  }

  .adyen-checkout__label {
    @include checkoutSmallSpacing('bottom', true);
  }

  .adyen-checkout__input {
    @include formControlInput();
  }
  .adyen-checkout__button.adyen-checkout__button--pay {
    border-radius: 0;
  }
  .adyen-checkout__label__text {
    @include baseFontSize;
    color: $content-black;
    padding: 0;
  }

  .adyen-checkout__error-text {
    @extend .invalid-feedback;
    display: none;
    @include baseFontSize();
  }

  .adyen-checkout__field--error {
    .adyen-checkout__error-text {
      display: block;
    }
  }

  .adyen-checkout-input__inline-validation {
    &--valid {
      @include fixedSprite('10-correct', transparent, false);
      right: 0.8rem;
      img {
        display: none;
      }
    }
    &--invalid {
      display: none;
    }
  }

  .adyen-checkout__field--storedCard {
    margin-left: 0 !important; // since date is hidden there should be no margin
    &:not(.adyen-checkout__field__cvc) {
      display: none; // hide expiry date
    }
  }

  .adyen-checkout__card__exp-cvc {
    flex-wrap: wrap;
    gap: 1.6rem;
    & > .adyen-checkout__field {
      flex: 1;
      margin: 0;
    }
  }

  .adyen-checkout__card__cardNumber__brandIcon,
  .adyen-checkout__icon,
  .adyen-checkout__spinner__wrapper {
    filter: grayscale(100%);
  }

  .adyen-checkout__button--pay {
    @extend .button;
    @extend .button--primary;
    height: $button-height-desktop;
    padding: 0 ($space-unit * 4);
    width: 100%;
  }

  .adyen-checkout__amazonpay__button--changeAddress {
    @extend .button;
    @extend .button--secondary;
    height: $button-height-desktop;
    padding: 0 ($space-unit * 4);
    margin-top: $space-unit * 4;
    width: 100%;
  }

  .adyen-checkout__card-input .adyen-checkout__fieldset--billingAddress,
  .adyen-checkout__card__holderName,
  .adyen-checkout__card__kcp-authentication,
  .adyen-checkout__card__socialSecurityNumber,
  .adyen-checkout__installments,
  .adyen-checkout__store-details {
    margin-top: 0;
  }

  .adyen-checkout__checkbox {
    min-height: 5.2rem;
    display: flex;
    align-items: center;

    &__label {
      &::before,
      &::after {
        display: none;
      }

      @extend %font__copy;
      padding: 0 0 0 2.8rem;
      font-size: 1.4rem;
      line-height: 1.8rem;
    }

    input.adyen-checkout__checkbox__input[type='checkbox'] {
      pointer-events: auto;
      opacity: 1;
      top: 0;
    }
  }

  .adyen-checkout__store-details {
    @extend .checkbox;
    @include checkoutSmallSpacing('bottom');
  }

  .adyen-checkout__card__brands {
    display: none;
  }

  .adyen-checkout__input--focus,
  .adyen-checkout__input--focus:hover,
  .adyen-checkout__input:active,
  .adyen-checkout__input:active:hover,
  .adyen-checkout__input:focus,
  .adyen-checkout__input:focus:hover {
    box-shadow: none;
    border-width: 0 0 0.1rem 0;
    border-color: $content-black;
  }

  @at-root {
    [data-whatintent='keyboard'] & .adyen-checkout__input--focus,
    .adyen-checkout__input:focus-visible {
      @include focus-visible;
    }
  }
}

#componentaction-container {
  width: 100%;
  .adyen-checkout__threeds2__challenge {
    width: 100%;
    min-height: 452px;
    iframe {
      width: 100%;
      overflow: hidden;
      height: 452px;
    }
  }
  .adyen-checkout__qr-loader {
    text-align: center;
    img {
      display: inline;
    }
  }
}
#threedscontainer {
  width: 100%;
  .adyen-checkout__threeds2__challenge {
    width: 100%;
    min-height: 452px;
    iframe {
      width: 100%;
      overflow: hidden;
      height: 452px;
    }
  }
}
