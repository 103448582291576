// SECTION - COMPONENTNS --------------------------------------------------------------------
.client-service {
  // ANCHOR - GENERAL --------------------------------------------------------------------
  &-hub-grid {
    .row {
      row-gap: 6.4rem;
    }
  }

  &-accordion-payments {
    &__panel {
      &--content {
        padding: 4rem 0 2rem;
      }
    }

    .please-note-description {
      width: handle-cols(8, xxl);
    }
  }
}
