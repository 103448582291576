//* ANCHOR - DEFAULT --------------------------------------------------------------------
.store,
.store-locator__map {
  height: $storelocator-height-desktop;
}
.store-locator__wrapper {
  padding: 0;
}

.store-locator {
  //* SECTION - SEARCH --------------------------------------------------------------------
  .container--search {
    .search-store {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      padding: 0;
      background-color: $content-white;

      //* ANCHOR - SEARCH FIELD --------------------------------------------------------------------
      &__autocomplete {
        $space: calc(var(--storelocator-filters-width) + 1.2rem);
        flex: 1;
        width: 100%;
        max-width: 45.6rem;
        background-color: transparent;
        display: flex;
        margin: 0 1.2rem;
        height: 6.8rem;

        .autocomplete {
          padding: 4rem 1.2rem;
        }
      }

      //*SECTION - MODALS --------------------------------------------------------------------
      //* ANCHOR - FILTERS --------------------------------------------------------------------

      &__results-filters {
        margin: 0;
        align-items: center;
        gap: 2rem;
        height: 6.8rem;
        z-index: 1;
        flex: 1;
        justify-content: flex-end;

        #resultsOptions,
        #allLocationsOptions,
        #refineOptions {
          position: absolute;
          left: 0;
          right: 0;
        }
      }

      &__filters {
        top: 6.8rem;

        &__container {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          .use-location {
            margin-top: 0;
          }
          .storelocator-filters {
            display: grid;
            // grid-template-rows: repeat(4, 1fr) / repeat(3, 1fr);
            grid-template-rows: repeat(4, 1fr);
            grid-auto-flow: column;
            column-gap: 0.8rem;
            row-gap: 1.2rem;

            li {
              &:not(:last-child) {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      //*ANCHOR - ALL BOUTIQUES --------------------------------------------------------------------
      &__regions {
        max-height: 85.8rem - 2rem * 2;

        .button__close {
          top: 2.2rem;
        }
        &__title {
          padding: 2.2rem 1.2rem;
        }

        .wrapper-regions {
          display: grid;
          grid-template-columns: repeat(3, minmax(min-content, 22.8rem));
          column-gap: 1.2rem;
          padding: 4rem 1.2rem 4rem;
          .region-col,
          .region-col ul {
            gap: 0.8rem;
            h3 {
              margin-bottom: 2rem - 0.8rem;
            }
          }
        }
      }

      //*ANCHOR - RESULTS --------------------------------------------------------------------
      &__resultslist {
        top: 0;
        max-height: 56.8rem;
        .button__close {
          top: 2.2rem;
        }
        &__title {
          padding: 2.2rem 1.2rem;
        }

        ul {
          flex: 1;
          overflow: auto;
          list-style: none;
          display: flex;
          flex-direction: column;
          padding: 4rem 1.2rem;
        }
      }
      //*!SECTION - End modals
    }
  }
  //* !SECTION - End Search
}

//* ANCHOR - STORE PAGE --------------------------------------------------------------------
.store-page {
  padding: calc(#{var(--header-h)} + 10rem) 0 12rem;

  &__section {
    &:not(:first-child) {
      padding: 4rem 0 12rem;
    }
    &-title {
      margin-bottom: 0;
    }

    //* ANCHOR SINGLES SECTION --------------------------------------------------------------------

    &#services {
      .section__content {
        .store-page__section-title {
          padding-right: 0;
          height: auto;
          display: block;
        }
      }

      .service-index {
        margin-bottom: 0;
      }
    }
  }

  &__inner-section {
    &:not(:last-child) {
      margin-bottom: 4rem;
    }

    &-title {
      margin-bottom: 0;
    }
  }

  &__nearby {
    &-infos {
      gap: 1.2rem;
    }

    &-list {
      gap: 5.2rem;
      .links {
        margin-top: 2.4rem;
        padding-top: 1.2rem;
        flex-direction: row;
        gap: 2.4rem;
      }
    }
  }

  .breadcrumb {
    margin-bottom: 5.2rem;
    ol {
      gap: 0.8rem;
      li {
        gap: 0.6rem;
      }
    }
  }

  .store-locator__map {
    margin-top: 4rem;
    margin-bottom: 8rem;
  }
}
