.in-drawer {
  &.login-modal,
  &.minibag-modal__wrapper {
    .wrapper-header-modal {
      padding: 1.2rem 3.2rem 1.2rem 0;
    }

    h2 {
      margin-bottom: 4rem;

      &.login-modal-subtitle {
        margin-bottom: 4rem;
      }
    }
  }
}
