.client-service {
  &__section {
    padding: 4rem 0 10rem;

    .component-section-title {
      margin-bottom: 3.2rem;
    }

    &.section {
      &--intro-section {
        padding: 10rem 0 10rem;
      }
    }
  }
}

// SECTION - COMPONENTNS --------------------------------------------------------------------
.client-service {
  &-hub-grid {
    .row {
      row-gap: 6rem;
    }
  }

  &-accordion-payments {
    .please-note-description {
      width: handle-cols(12, lg);
    }
  }

  &__baa-guest-advice {
    row-gap: 3.8rem;
  }
}

// !SECTION
