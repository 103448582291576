.bag-product {
  display: flex;
  flex-direction: row;
  padding-top: 0;
  padding-bottom: $space-unit * 10;
  margin-bottom: $space-unit * 10;
  opacity: 1;
  @include animate(0.5, 'opacity');

  &:not(:last-of-type) {
    @include border('bottom');
  }
  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &.is-hide {
    opacity: 0;
  }

  .product-image {
    height: auto;
  }

  > .row {
    flex-grow: 1;
  }

  &__divider {
    @include border('top');
    height: $space-line;
    margin-top: $space-unit * 4;
    margin-bottom: $space-unit * 4;
  }

  &__content {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    > .bag-product__preorderinfo {
      display: none;
    }
  }

  &__out-of-stock {
    color: $alert-error;
    margin-top: 1.8rem;
  }

  &__no_refundable {
    font-weight: bold;
    font-size: 1.2rem;
    margin-top: 1.4rem;
  }

  &__name-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    &__price-block {
      flex-shrink: 0;
      text-align: right;
    }
    &__name {
      @extend %font__title;
      @include del-link;
      flex-shrink: 1;
      padding-right: 1.2rem;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @supports (-webkit-line-clamp: 2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    &__price {
      flex-shrink: 0;
    }
    &__message {
      color: $content-black;
      display: block;
    }
  }

  &__mbmlabel {
    margin-bottom: $space-unit * 5;
    .bag-product--mbm {
      // @include fixedSprite('label-monclerbyme');
      width: 90px;
      height: 20px;
      span {
        display: none;
      }
    }
  }

  &__details {
    margin-top: 1rem;
    margin-bottom: $space-unit * 8;

    > div:not(:last-child) {
      margin-bottom: $space-unit * 2;
    }
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.8rem;
    text-transform: capitalize;
    margin-top: 2rem;
    padding-bottom: 0.1rem;
    align-items: center;
    .cta {
      text-decoration: initial;
    }
  }

  &__added-wishlist {
    @extend %font__cta;
    line-height: 24px;
    opacity: 0;
    transform: translateY(2rem);
    @include animate(0.3, 'all');

    &.is-wished {
      opacity: 1;
      transform: translateY(0);
    }

    .icon {
      vertical-align: middle;
    }
  }

  .bag-product__preorderinfo__subtitle {
    margin-top: 1.2rem;

    color: rgba(0, 0, 0, 0.55);
  }
  &__preorderinfo {
    margin-top: $space-unit * 5;
    &__text {
      margin-top: $space-unit * 2;

      color: rgba(0, 0, 0, 0.55);
    }
  }

  &__image {
    .product-image {
      height: auto;
    }
  }

  .disclaimer-box {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    > button {
      text-align: left;
      font-weight: bold;
    }
    p {
      font-weight: bold;
    }
  }

  &__details {
    .label,
    .selector__button__label,
    .selector-quantity__label {
      @extend %font__title;
    }
  }

  &--product-exchanged {
    .product-image {
      opacity: 0.5;
    }
  }
}
