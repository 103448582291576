.service-bar {
  background-color: $content-white;
  color: $content-black;
  overflow: hidden;
  position: relative;
  border-width: 1px 0;
  border-style: solid;
  border-color: $border-light;
  .service-bar__list {
    list-style: none;
    display: flex;
    gap: 3.2rem;
    overflow: hidden;
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    .service-bar__service {
      flex-shrink: 0;
      padding: 0;
    }
  }
  &[data-stacked='false'] {
    // With animation
    .btn-playpause {
      display: block;
    }
    .service-bar__loop {
      width: max-content;
      display: inline-flex;
    }
    .service-bar__service {
      flex-basis: auto;
      max-width: none;
    }
  }
  .btn-playpause {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 0;
    padding: 2.4rem 2rem 2.4rem 1.2rem;
    transform: translateY(-50%);
    background: linear-gradient(90deg, #ffffff 90%, rgba(255, 255, 255, 0) 100%);
    display: none;
    text-transform: uppercase;
    &[aria-pressed='true'] .service-bar-pause,
    &[aria-pressed='false'] .service-bar-play {
      display: none;
    }
    .service-bar-pause,
    .service-bar-play {
      display: flex;
      align-items: center;
    }
    .btn--pause,
    .btn--play {
      margin-right: 0.8rem;
    }
    .btn--pause {
      @include fixedSprite('16-pause');
    }
    .btn--play {
      @include fixedSprite('16-play');
    }
  }
}
