.myaccount {
  &-content {
    padding: 6rem 0 10rem;
  }

  &-hero {
    margin-top: var(--header-h);
  }

  //* DASHBOARD --------------------------------------------------------------------
  &-dashboard {
    margin-top: var(--header-h);
    // DASHBOARD - WELCOME --------------------------------------------------------------------
    &__welcome {
      margin-top: 6rem;
      margin-bottom: 6rem;
      .welcome_text {
        padding-top: 6rem;
      }
    }

    &__imgwrapper {
      padding: 0;
      padding-top: 2rem;
      list-style: none;
      li {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    &__imgcard {
      text-decoration: none;
      display: block;
      @include buttonFocus();
      //TODO - Chiedere specifiche
      opacity: 1;
      transition: all 0.3s ease-out;

      &:hover {
        //TODO - Chiedere specifiche
        opacity: 0.6;
      }

      &-label {
        display: block;
        margin-top: 1.6rem;
        @extend %font__cta;
      }
    }

    &__linkswrapper {
      row-gap: 6rem;
      padding: 8rem 0 10rem;
      list-style: none;
      &-link {
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        .link {
          &-title {
            @extend %font__title;
            margin-bottom: 2rem;
          }
          &-content {
            flex: 1;
            margin-bottom: 2rem;
          }
        }

        .button {
          width: 100%;
        }
      }
    }
  }

  //* SECTIONS --------------------------------------------------------------------
  &__section {
    padding: 6rem 0;
    &.short {
      padding: 4rem 0;
    }
    &:not(:last-child) {
      border-bottom: 0.1rem solid $outline;
    }
    &:first-child {
      padding-top: 0;
    }
    &-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;
      &.no-content {
        margin-bottom: 0;
      }
      h2 {
        @extend %font__title;
      }
    }

    &-infos {
      display: flex;
      flex-direction: column;
      row-gap: 1.2rem;

      &--row {
        display: flex;
        justify-content: space-between;
      }
    }

    //* SECTION - SINGLES --------------------------------------------------------------------
    //* ANCHOR - PERSONAL INFO --------------------------------------------------------------------
    &#personalinfo {
      .edit-profile-form {
        padding-top: 0.3rem;
        .row {
          margin-bottom: 4rem;
          row-gap: 4rem;
          &--gender {
            margin-top: 2rem;
            margin-bottom: 6rem;
          }
          &--phone {
            margin-bottom: 0;
          }

          &.phonewithprefix-label {
            margin-bottom: 0;
            padding-top: 0;
          }
        }

        .fieldset--plain {
          margin: 0;
        }

        .form-group--gender-radio {
          display: inline-grid;
          row-gap: 2rem;
          column-gap: 1.2rem;
          margin-top: 2rem;
          .radio {
            padding: 0;
            margin: 0;
          }
        }

        .email-slot {
          display: flex;
          flex-direction: row-reverse;
          align-items: flex-end;
          gap: 1.2rem;
          input {
            padding: 0;
            height: auto;
            border: none;
          }
          .form-group {
            flex: 1;
          }
        }
      }
      .button-row {
        row-gap: 2rem;
        align-items: center;
        [type='submit'] {
          width: 100%;
        }
      }

      .edit-email-form {
        display: flex;
        flex-direction: column;
        gap: 4rem;
        margin-top: 2rem;
        .button-row {
          align-items: center;
          margin-top: 2rem;
        }
      }
    }

    //* ANCHOR - ADDRESSES --------------------------------------------------------------------
    &#addresses,
    &#payments {
      .accordion {
        &__panel > div {
          padding: 1.6rem 0 2.8rem;
        }

        &__header {
          height: 2.4rem;
          span {
            @extend %font__cta;
          }
          &.removing {
            span {
              font-weight: 400;
              text-transform: none;
            }
          }
        }
        &__item {
          &:not(:last-child) {
            margin-bottom: 1.2rem;
          }
        }
      }
      .button-row {
        &--add {
          padding-top: 2rem;
          row-gap: 2rem;
          .button--secondary,
          [type='submit'] {
            width: 100%;
          }
        }

        &--addnew {
          padding-top: 4rem;
          .button--secondary {
            width: 100%;
          }
        }

        &--edit {
          row-gap: 2rem;
          align-items: center;
          padding: 2rem 0 2.8rem;
          flex-direction: column-reverse;
          text-align: center;
          [type='submit'] {
            width: 100%;
          }
        }
      }

      .checkout-shipping__address__address-book {
        &__edit,
        &__new {
          display: flex;
          flex-direction: column;
          row-gap: 4rem;

          .new-item {
            &-title {
              padding: 4rem 0 0;
            }
            &-firstrow {
              row-gap: 4rem;
            }
          }
          .address_form {
            display: flex;
            flex-direction: column;
            row-gap: 4rem;
            .row {
              row-gap: 4rem;
              padding-top: 0;
              [class^='col'] {
                padding-top: 0;
              }
            }
          }
        }
      }

      .address,
      .payment {
        &-container {
          .remove-item {
            padding-bottom: 4.8rem;
          }
        }
        &-title {
          @extend %font__subtitle;
          margin-bottom: 1.2rem;
        }

        &-details {
          display: flex;
          flex-direction: column;
          gap: 0.8rem;
        }

        &-actions {
          display: flex;
          gap: 2.4rem;
          margin-top: 1.2rem;
        }
      }
    }

    // !SECTION END SINGLES
  }
}
