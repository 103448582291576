.product-zoom-modal {
  // Overriding rules
  &.modal.modal--fullheight {
    padding: 0;
    .container {
      padding: 0;
    }
    .modal__content {
      min-height: 100dvh;
      max-width: none;
    }
    .swiper-vertical {
      .swiper-wrapper {
        flex-direction: column;
      }
    }
  }

  .modal__wrapper {
    max-width: none;
    padding: 0;
    flex-basis: 100%;
    height: 100%;
  }
  .modal__body {
    padding-bottom: 0;
    .swiper-container {
      &.product-zoom {
        height: 100dvh;
      }
      &.product-zoom-thumbs {
        height: 100%;
        .product-zoom-thumbs__thumb-button {
          opacity: 0.3;
        }
        .swiper-slide-thumb-active .product-zoom-thumbs__thumb-button {
          position: relative;
          opacity: 1;
          transition: opacity 0.2s linear;
          &::before {
            content: '';
            background-color: $content-black;
            height: 1px;
            width: 100%;
            position: absolute;
            bottom: -0.4rem;
            left: 0;
            z-index: 1;
          }
        }
      }
    }
  }
  .product-zoom-thumbs--wrapper {
    position: absolute;
    left: 0;
    bottom: 6.1rem;
    width: 100vw;
    padding: 0 1.2rem;
    z-index: 1;
    video {
      max-width: 100%;
    }
  }
  // this height should keep the main image ~20px from the thumbs: 4.7rem is height of the controls wrapper; 28vw is the magic number to scale with thumbnails ratio
  $zoom-mobile-height: calc(100dvh - 4.7rem - 28vw);
  .gallery-wrapper__video,
  .zoom-img-container {
    height: $zoom-mobile-height;
    background-color: $background-product;
    overflow: hidden;
  }
  .gallery-wrapper,
  .zoom-img-container *:not(img) {
    width: 100%;
    height: 100%;
  }
  .gallery-wrapper__video,
  .zoom-img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .zoom-img-wrapper img,
  .gallery-wrapper__video video {
    position: relative;
    height: auto;
    max-height: 100dvh;
    width: auto;
    max-width: 100vw;
  }

  .modal__zoom-controls-wrapper {
    position: absolute;
    z-index: 3;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 1.2rem 2.3rem;
    align-items: center;
  }
  .modal__zoom-controls {
    display: flex;
    align-items: center;
    .modal__zoom-in,
    .modal__zoom-out {
      height: 2.4rem;
      width: 2.4rem;
      &[disabled] {
        opacity: 0.2;
      }
      &::before {
        content: '';
        display: block;
        height: 1.6rem;
        width: 1.6rem;
        margin: 0 auto;
      }
    }
    .modal__zoom-in {
      &::before {
        @include sprite('16-plus');
      }
    }
    .modal__zoom-out {
      &::before {
        @include sprite('16-minus');
      }
    }
    .modal__zoom-scale {
      margin: 0 1.2rem;
    }
  }
}
