//GENERAL STYLES --------------------------------------------------
@each $font-type, $font-data in $monument-grotesk-mono {
  .font-#{$font-type} {
    @extend %font-#{$font-type};
    &--uc {
      @extend %font-#{$font-type};
      text-transform: uppercase;
    }
    &--ul {
      @extend %font-#{$font-type};
      text-decoration: underline;
    }
    &--st {
      @extend %font-#{$font-type};
      text-decoration: line-through;
    }
  }
}

//SPECIFIC STYLES -----------------------------------------------
.font__title {
  @extend %font__title;
}
.font__subtitle {
  @extend %font__subtitle;
}
.font__cta {
  @extend %font__cta;
}
.font__strike {
  @extend %font-regular--uc;
  @extend %font-regular--st;
}
.font__copy {
  @extend %font__copy;
}
.font__link {
  @extend %font__link;
}
.font__labelling {
  @extend %font__labelling;
}
