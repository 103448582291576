.checkout-review {
  @include checkoutSmallSpacing('bottom');
  @include border('bottom');

  & + div {
    @include checkoutSmallSpacing();
  }

  .review-title {
    @extend %font__title;
  }
  .row {
    > div {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  .container-edit-shipping {
    @include grid-row;
  }

  .edit-button-container {
    text-align: right;
  }

  .container-customer {
    max-width: 100%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    span + span {
      &::before {
        content: ' | ';
      }
    }
  }
}
