.ticker-bar {
  // height: $ticker-bar;
  .swiper-container {
    width: 50%;
  }
}
.clothing {
  .ticker-bar {
    &.button-logout {
      // background-color: $status_error;
      padding: 0 1.2rem;
      .message {
        margin: auto;
      }
    }
  }
}
