.tabs {
  .client-service__tablist {
    [role='tab'] {
      & + [role='tab'] {
        margin-left: 4rem;
      }
    }
  }
  .client-service__tabpanel {
    padding-top: 6rem;
  }
}
