.story {
  &-listing {
    background-color: $content-black;
    color: $content-white;
    padding: 10rem 0 8rem;
    overflow-x: hidden;

    &__title {
      margin-bottom: 2.4rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 1.6rem;
      @media (min-width: get($grid-breakpoints, md)) {
        margin-bottom: 2rem;
      }
    }

    &__row {
      overflow: auto;
      flex-wrap: nowrap;
    }

    .navigation {
      filter: invert(100%);
      display: flex;
      gap: 0.8rem;
      align-items: center;
      [aria-disabled='true'] {
        opacity: 0.4;
      }
    }
  }

  a:focus-visible {
    outline-color: $content-white;
  }

  aspect-ratio: 3/4;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 2rem 1.2rem;
  padding-top: calc((50% / 3 * 4) - 0.7rem); //padding top at 50% (padding % is related to width)
  @media (min-width: get($grid-breakpoints, md)) {
    padding: 2rem 1.8rem;
    padding-top: calc((50% / 3 * 4) - 0.7rem); //padding top at 50% (padding % is related to width)
  }
  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: $overlay-image;
    z-index: 2;
  }

  img {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
  }

  &__content {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    z-index: 2;
    &-title {
      @extend %font__title;
    }

    &-description {
      margin-top: 1.6rem;
      @extend %font__subtitle;
      a,
      span {
        text-decoration: none;
        display: block;
      }
    }
  }

  &__link {
    z-index: 2;
    position: relative;
    a {
      @extend %font__cta;
      border-bottom: 0.1rem solid $content-white;
    }
  }
}
