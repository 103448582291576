.login-modal {
  &.in-drawer {
    .modal__body {
      gap: 6rem;
    }
  }
  .modal__body--right {
    gap: 6rem;
  }

  &__login-social {
    .row:first-child {
      margin-bottom: 2.8rem;
    }
  }
}
