.bag-summary {
  margin-top: 4rem;

  #promotionalcode {
    .form-control {
      input {
        height: 4.4rem;
      }
    }

    #promocode-button {
      height: 4.4rem;
    }
  }
}
