.header {
  // * TOP BAR -----------------------------------------------------------------
  &-topbar__container {
    padding: 1.6rem 0 2.5rem;
    .container {
      display: block;
    }
  }
  // * LOGO -----------------------------------------------------------------
  &-logo {
    svg {
      height: 3.87rem;
      &.logo_us {
        height: 6rem;
      }
    }
  }

  // * AREAS -----------------------------------------------------------------
  &-area {
    padding-top: 0.4rem;

    &__actions {
      &,
      button {
        text-align: right;
      }
    }
  }
}

header {
  @at-root {
    #product-show & {
      // $transition: all 0.4s cubic-bezier(0.85, 0, 0.65, 1);

      top: 0;
      // transition: $transition;
      // transition-delay: 0.4s;

      &.hidden {
        opacity: 1;
        transition-delay: 0s;

        top: calc(var(--header-h) * -1);
        // transition: $transition;
      }
    }
  }
}

//* MENU ----------------------------------------------------------------------
.menu {
  &-modal {
    height: auto;
    overflow: auto;
    // @include vh(max-height, 100, 1svh);

    max-height: calc(90vh - #{var(--header-h)});
    @include scrollbar;
    &__container {
      padding-top: 6rem;
      padding-bottom: 16rem;
    }
  }

  &-close-button {
    position: relative;
    top: unset;
    left: unset;
    display: inline-block;
  }
  &-voice {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    &:not(:last-child) {
      margin-bottom: $space-unit * 2;
    }
    &__list {
      margin: 0;
      padding-left: 0;
    }

    &__nav {
      &-title {
        padding: 0;
      }
    }

    button[aria-expanded='false'],
    a {
      display: inline-flex;
      transition: color 0.4s ease-out;
      padding: 0;
      h2 {
        width: unset;
      }
      &:hover {
        h2,
        h3 {
          font-weight: 600;
        }
      }

      &:focus-visible {
        outline: $outline-width solid $content-black;
      }
    }

    &[data-active='true'] {
      padding-bottom: $space-unit * 10;
    }
  }

  &-accordion--height-zero {
    visibility: hidden;
  }
}

//* SUBMENU ----------------------------------------------------------------------
.submenu {
  &-voice {
    margin-top: 0;
    padding: 0;

    &:not(:first-child) {
      margin-top: $space-unit * 2;
    }

    &__list {
      gap: 0.8rem;
      pointer-events: none;

      &:hover {
        .submenu-voice a {
          color: $interactive-light;
          border-bottom-color: $interactive-light;
        }
      }
      margin-top: $space-unit * 2;
      padding-top: $space-unit * 5;
      &:not(:first-child) {
        margin-top: 1rem;
      }

      li {
        pointer-events: all;
      }
    }

    & > a {
      transition: all 0.4s ease-out;
      // padding: $space-unit 0;
      display: block;
      width: 100%;
      &:hover {
        color: $content-black !important;
        border-bottom-color: $content-black !important;
        font-weight: 600;
      }
    }
  }
}
