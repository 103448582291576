.checkoutpage {
  //checkout only - not checkout confirmation page
  .bag-summary.checkout {
    position: sticky;
    top: 5.4rem;
  }
}

.checkout--summary__block {
  @include border('bottom');
  padding-bottom: 0.8rem;
}

.order-confirm {
  .checkout-products-wrapper {
    margin-top: 3.2rem;
  }
}
