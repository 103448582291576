.component-details {
  padding-bottom: 20rem;

  &__images {
    margin-bottom: 12rem;
  }

  &__contents {
    &--content {
      .details__content {
        max-width: 46.2rem;
      }
    }
  }

  &--extended {
    padding: 8rem 0 20rem;

    .component-details {
      &__images {
        &.single {
          margin-bottom: 12rem;
        }
      }
    }
  }
}
