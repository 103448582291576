$transition: top 0.4s ease-out;

.sticky-tray {
  top: -$button-height-desktop;
  bottom: unset;
  border-top: none;
  transition: $transition;
  transition-delay: 0s;
  z-index: 5;
  background-color: transparent;
  &.show {
    top: 0;
    bottom: unset;
    transition-delay: 0.4s;
  }
  &.forward {
    z-index: 8;
  }
  &--inner {
    transform: translate(0, calc(var(--header-h) - var(--header-scroll) - 1px));
  }
  &__wrapper {
    justify-content: flex-end;
    align-items: center;
    gap: 1.2rem;
  }

  &__button {
    $col: absolute-col-size(3);
    $pad: #{var(--container-fill-half-padding)};
    $css: calc($col + $pad);
    @include manual-col($css);
  }

  &__modal {
    .row,
    .container {
      position: static;
    }

    .modal__close {
      visibility: hidden;
      &:focus-visible {
        visibility: visible;
      }
    }

    .modal__container {
      bottom: unset;
      left: unset;
      right: 0;
      top: calc(#{var(--header-h)} - #{var(--header-scroll)} - 1px + 3.6rem);
      $col: absolute-col-size(9);
      $pad: calc(1.2rem + var(--container-fill-half-padding));
      width: calc($col + $pad);
    }

    .modal__content {
      max-width: unset;
      flex-basis: unset;
    }

    .modal__body {
      padding-top: 4rem;
      padding-bottom: 3.8rem;
      padding-left: 1.2rem;
      padding-right: var(--container-fill-half-padding);
      gap: 3.2rem;
    }
  }
}
