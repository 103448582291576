.selector-color {
  .selector__menu {
    // flex-direction: column;
    // align-items: flex-start;
    bottom: initial;
    //padding-top: 3.8rem;

    .label {
      @include reset-visually-hidden();
      padding-top: 0.3rem;
      padding-bottom: 2rem;
    }

    &-list {
      align-items: flex-start;

      li {
        &:first-child {
          margin-left: 0;
        }
      }
    }

    &__item {
      // padding-bottom: 2rem;
      @include minClicableArea(2.4rem, 4rem);
      &--button {
        padding: 0.8rem 0 1.6rem;
      }

      &__label {
        padding-right: $space-unit * 2;
      }
    }
  }

  &.small-swtches {
    .selector__menu {
      ul {
        display: flex;
        width: 100%;
        > li {
          flex-basis: 26.5%;
          flex-shrink: 0;
        }
      }
    }
  }

  &.inTile {
    .selector-wrapper {
      margin-top: 1.6rem;
    }
  }
}
