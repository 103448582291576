.accountmenu {
  &-drawer {
    .login-register-header {
      padding-top: 10rem;
    }
    &__title {
      padding-top: 6rem;
      padding-bottom: 4rem;
      @extend %font__title;
    }
    &__menu {
      @include reset-list;
      display: flex;
      flex-direction: column;
      gap: 4rem;
    }
    &__item {
      a {
        @extend %font__cta;
      }
    }
  }
}
