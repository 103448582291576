.in-dialog {
  &.login-modal {
    &.modal.modal--fullheight {
      padding: 1.2rem 0;
    }

    .modal__header {
      margin-bottom: 8rem;
    }

    .button-login-cta,
    .button-cta-passwordlogin {
      margin-top: 2rem;
    }

    .login-modal__registartion {
      margin-top: -8rem;
    }
  }
}
