.cs-hero {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $overlay-image;
  }

  &.inverted {
    color: $content-white;
  }

  .container {
    min-height: 48rem;
    margin-top: 13.7rem;
    padding-bottom: 6rem;
  }

  .row {
    z-index: 1;
    position: relative;
  }

  &-intro {
    @include checkoutSpacing('bottom', true);
  }

  &-title {
    @extend %font__title;
    margin-bottom: 3.2rem;
  }

  &-image {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &-topics {
    display: flex;
    gap: 1.6rem;
    flex-direction: column;
    margin-top: 3.2rem;

    > ul {
      display: flex;
      gap: 1.6rem;
      flex-direction: column;
      @include reset-list;
    }
  }

  &.text-white {
    color: $content-white;
  }
}
