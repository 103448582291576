.subnav-filters {
  &-modalheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1.2rem;
  }

  &-modal {
    .modal__content {
      position: relative;
      overflow: auto;
    }
    .modal__body {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

.subnav-content {
  ul {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
  .sort__list {
    padding: 2.2rem 0;
  }

  .accordion__group {
    padding: 2.2rem 0 7.2rem;
  }

  > div {
    height: 100%;
    padding: 0 1.2rem;
  }

  .subnav-refinements-actions {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    button {
      flex-basis: 50%;
    }
  }
}
