.corporate-locations {
  &__title {
    padding-bottom: 6rem;
  }

  &_panel {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 4rem;
  }

  &_row {
    &.is-multiloc {
      grid-column: 2 span;
      &:not(:last-child) {
        padding-bottom: 6rem;
        margin-bottom: 2rem;
      }
      .locations {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.2rem;
        row-gap: 4rem;
      }
    }
  }

  &_location {
    .info {
      padding-right: 20%;
    }
  }
}
