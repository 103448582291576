.account-appointments,
.modal.bookanappointment {
  .fieldset--plain {
    margin: 0;

    .form-group {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      .radio {
        width: auto;
        padding: 0;
        min-height: unset;
      }
    }
  }
  .button-row {
    flex-direction: column-reverse;
    row-gap: 2rem;
    text-align: center;
  }

  .get-directions {
    @extend .button;
    @extend .button--tertiary;
  }

  .note {
    color: $interactive-light;
  }

  .phonewithprefix-label {
    padding: 0;
  }

  .checkout-pickup__map {
    margin: 0;
    aspect-ratio: 4/3;
  }

  .ReserveForm {
    & > .row {
      row-gap: 4rem;
    }
    input:placeholder-shown {
      text-overflow: ellipsis;
    }
  }

  .baa-storelist {
    margin-top: 4rem;
    .optionbox {
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      &__item {
        display: flex;
        gap: 2.4rem;
        input {
          margin-top: 0.2rem;
          position: static;
        }
      }
    }
    &__item {
      &-title {
        @extend %font__cta;
      }
      .store-details {
        padding-top: 1rem;
        row-gap: 0.8rem;
        .name {
          margin: 0;
        }
      }
    }
  }
  .baa-content {
    &--children {
      .store-details {
        row-gap: 0.8rem;
        .name {
          margin: 0;
        }
        .telephone {
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  .row {
    &-appointmenttype {
      .optionbox {
        row-gap: 2rem;
        column-gap: 4rem;
        margin: 0;
        &__item {
          gap: 2.8rem;
          display: flex;
          flex: unset;
          flex-basis: unset;
          border: none;
          &__radio {
            position: static;
          }
          &__content {
            padding: 0;
            border: none;
          }
        }
      }
      label {
        width: auto;
        max-width: unset;
        flex: 0;
      }
      input {
        margin-top: 0.2rem;
      }
    }
  }

  .remove-appointment {
    padding-top: 4rem;
  }

  .detail-wrapper {
    form {
      padding-top: 6rem;
      .title-edit {
        @extend %font__title;
        padding-bottom: 4rem;
      }
      .detail-row {
        padding: 4rem 0;
      }
      .button-row {
        flex-direction: row;
        row-gap: 2rem;
        text-align: left;
        align-items: center;
        &--primaries {
          row-gap: 1.2rem;
        }
      }
    }
  }

  .dateselection-row {
    & > .row {
      row-gap: 1.2rem;
    }
  }

  .yourdetails-title {
    @extend %font__title;
    margin-top: 2rem;
  }
}

.modal.bookanappointment {
  .dialog__title {
    margin-bottom: 2rem;
  }
  .store {
    &-name {
      margin-bottom: 2rem;
    }

    &-phone {
      text-wrap: nowrap;
    }
  }

  .button-row {
    margin-top: 0;
    margin-left: -0.6rem;
    margin-right: -0.6rem;
  }
}
