.store-details {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .name {
    margin-bottom: $space-unit;
  }

  .email,
  .telephone {
    margin-bottom: $space-unit;
    @include hoverablelink;
    &:first-of-type {
      margin-top: ($space-unit * 3);
    }
  }

  .detail-title {
    margin-bottom: $space-unit;
  }

  &__hours {
    dl {
      display: flex;
      flex-wrap: wrap;
      row-gap: 0.8rem;
    }
    dd,
    dt {
      width: 50%;
    }
  }
}

%store-section {
  @each $breakpoint, $padding in $grid-container-padding {
    @if $breakpoint== 'xs' {
      padding: 4rem $column-gapped-width 4rem $padding;
    } @else {
      @include breakpoint($breakpoint, min) {
        padding: 4rem $column-gapped-width 4rem $padding;
      }
    }
  }

  &:not(:last-child) {
    // margin-bottom: 4rem;
    border-bottom: 0.1rem solid $border-light;
  }

  .button {
    width: 100%;
  }
}

.store {
  &-locator__wrapper {
    .store {
      position: absolute;
      top: -1rem;
      left: 0;
      width: 100%;
      z-index: 2;
      background-color: $content-white;

      .button--close {
        position: absolute;
        top: 1.8rem;
        right: 1.2rem;
        z-index: 1;
      }
    }
  }

  &-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__summary {
    padding: 2rem 3.2rem 2rem 0;
    .get-directions {
      width: 100%;
    }
    &__name {
      @extend %font__title;
      margin-bottom: 4rem;
    }

    &__viewmore {
      margin-top: 2rem;
      @extend .button--tertiary;
    }
  }

  &__moreinfo {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    // padding: 4rem 0;
    @include scrollbar;

    & > .container {
      padding: 0;
    }

    &__section {
      @extend %store-section;

      &__title {
        @extend %font__title;
        margin-bottom: 4rem;
      }

      dl {
        display: flex;
        flex-direction: column;
        row-gap: 0;
      }

      dt {
        @extend %font__subtitle;
        margin-bottom: 1.4rem;
      }

      dd {
        &:not(:last-child) {
          margin-bottom: 4rem;
        }
      }

      .boutique-collections {
        padding: 0;
        list-style: none;

        li {
          @extend %font__subtitle;
          padding: 0;
        }
      }

      .instore-service {
        &-wrapper {
          padding: 0;
          list-style: none;
          li {
            padding: 0;
            &:not(:last-child) {
              margin-bottom: 4rem;
            }
          }
        }

        &-title {
          @extend %font__subtitle;
          margin-bottom: 1.8rem;
        }
      }
    }
  }

  .store-in-cluster {
    @extend %store-section;
  }
}
