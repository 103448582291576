.minibag {
  &__productcontainer + &__productcontainer {
    margin-top: 2.4rem;
    padding-top: 2.4rem;
    border-top: solid 1px $outline;
  }

  &__product,
  &__productcontainer {
    display: flex;
    width: 100%;
    gap: 1.2rem;
  }

  &__productcontainer {
    flex-direction: column;
  }

  &__product__image {
    flex-grow: 0;
    .product-image {
      height: auto;
      @at-root &,
        .checkout-products & {
        width: 10.4rem;
      }
    }
  }

  &__product__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .option-product__label {
      @extend %font__title;
    }
  }

  &__product__info {
    > p {
      margin-top: 0;
      & + p {
        margin-top: 0.4rem;
      }
    }
  }

  &__product__columns {
    display: flex;
    gap: 0.6rem;
    justify-content: space-between;
  }

  &__product__title {
    flex-grow: 0;
    word-break: break-word;
  }

  &__product__price {
    flex-grow: 1;
    text-align: right;
    white-space: nowrap;
  }

  &__product__label {
    display: block;
    text-align: right;
    margin-top: $space-unit;

    &--outofstock {
      @extend .minibag__product__label;
      color: $alert-error;
      display: flex;
      align-items: center;
    }

    &--preorder {
      @extend .minibag__product__label;
    }
  }

  &__product__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__product__actions {
    display: flex;
    gap: 0.8rem;
  }

  &__mobile-label {
    padding-top: 2.4rem;
    padding-bottom: 0.8rem;
    border-bottom: solid 1px $outline;
    @extend %font__cta--ul;
  }
}
