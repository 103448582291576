.product-reserve--modal {
  .dialog__title {
    margin-bottom: 4rem;
  }

  .login-modal-forms {
    > h2 {
      display: none;
    }
  }

  .product-reserve__detail,
  .include-login-registration form {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }

  .product-reserve {
    width: 100%;
    .bag-info-box {
      margin-top: 4rem;
    }

    .tabs {
      position: relative;
    }

    .react-tabs__tab-panel--selected {
      min-height: 20rem;
    }

    // STORE SELECTOR TABS
    &__detail {
      > .divider {
        margin-bottom: 0;
      }

      &__text {
        padding: 3.2rem 0;
        &:last-child {
          padding-bottom: 0;
        }

        .button {
          margin-top: 3.2rem;
          @extend .full;
        }
      }

      // STORE SELECTOR LIST - not best solution... align MONCLER COMPONENT/HTML
      &__line {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.8rem 0;
        flex-direction: row-reverse;
        gap: 3.2rem;

        .use-location {
          @extend .button--link-underline;
        }
      }

      &__stores {
        max-height: 40rem;
        padding-right: 0.4rem;
        margin: 4rem 0 0.4rem;
        @include scrollbar;
      }

      &__form {
        &__note {
          padding-top: 1.2rem;
        }
      }

      // RESERVE FORM
      &__selected-store {
        > div {
          display: flex;
          p {
            flex-grow: 1;
          }
        }
      }

      .wrapper-info,
      .wrapper-bookanappointment {
        display: flex;
        flex-direction: column;
        .row + {
          & .row {
            padding-top: 1.2rem;
          }
          > .col-24 + .col-24 {
            padding-top: 0.8rem;
          }
        }
      }

      .ReserveForm {
        > .row {
          padding-top: 1.2rem;
        }
        .form-control:not(textarea) {
          margin-top: 0;
        }
      }

      .reserve-info-title {
        @extend %font__subtitle;
      }

      .store-name {
        @extend %font__title;
      }

      .store-address {
        display: none;
      }

      //REVIEWS
      &__review {
        &-item {
          padding-bottom: 3.2rem;
        }

        &-header {
          @extend %font__title;
          margin-bottom: 2.4rem;
        }

        &-content {
          display: flex;
          flex-direction: column;
          gap: 1.2rem;
        }
      }
    }

    // LOGIN - PASSWORD
    .password-form {
      @extend .mt-40;
    }

    .edit-email-greethings {
      span {
        display: none;
      }
    }

    .button-back {
      margin-bottom: 2.4rem;
    }

    &-store {
      &__content {
        display: flex;
        flex-direction: column;
        gap: 3.2rem;
        &__name {
          span:first-child {
            @extend %font__title;
          }

          .store-distance {
            @extend %font__copy;
            color: $content-secondary;
            display: block;
          }
        }

        .store-info {
          grid-template-columns: 1fr;

          dt {
            @extend %font__subtitle;
            padding-top: 0.8rem;
          }
        }

        .button--reserve {
          @extend .full;
        }

        .appount-flag {
          padding: 1.6rem 0;
        }
      }

      & + .product-reserve-store {
        margin-top: 5.2rem;
      }
    }
  }
}
