@use 'sass:math';
@use 'sass:list';

@mixin grid-row() {
  display: flex;
  flex-wrap: wrap;
  margin-right: $gutter-compensation;
  margin-left: $gutter-compensation;
}

@mixin grid-container() {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 192rem;

  @each $breakpoint, $padding in $grid-container-padding {
    @if $breakpoint== 'xs' {
      padding: 0 $padding;
    } @else {
      @include breakpoint($breakpoint, min) {
        padding: 0 $padding;
      }
    }
  }
}

.container,
%container {
  @include grid-container();

  &--neg {
    @each $breakpoint, $padding in $grid-container-padding {
      @if $breakpoint== 'xs' {
        margin: 0 (-$padding);
      } @else {
        @include breakpoint($breakpoint, min) {
          margin: 0 (-$padding);
        }
      }
    }
  }

  &--scroll {
    @extend %container;
    padding: 0;
    overflow-x: hidden;
    .row,
    .row--scroll {
      @each $breakpoint, $padding in $grid-container-padding {
        @if $breakpoint== 'xs' {
          padding: 0 $padding;
        } @else {
          @include breakpoint($breakpoint, min) {
            padding: 0 $padding;
          }
        }
      }
    }

    .row--scroll {
      @include breakpoint(lg, max) {
        flex-wrap: nowrap;
        overflow-x: auto;
      }
    }
  }

  &--halfright {
    padding-left: $space-unit * 0.5;
  }

  &--halfleft {
    padding-right: $space-unit * 0.5;
  }

  &--fluid {
    position: relative;
    width: 100%;

    @each $breakpoint, $padding in $grid-container-padding {
      @if $breakpoint== 'xs' {
        padding: 0 $padding;
      } @else {
        @include breakpoint($breakpoint, min) {
          padding: 0 $padding;
        }
      }
    }
  }
}

.row {
  @include grid-row();

  &.reverse,
  &.row-reverse {
    flex-direction: row-reverse;
  }

  &--full-bleed {
    margin-left: 0;
    margin-right: 0;

    [class^='col-'] {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &--inner {
    margin-left: 0;
    margin-right: 0;
  }

  & + .row.with-space {
    margin-top: 4rem;
  }

  &--scroll {
    @extend .row;
  }
}

//alignment ulitilities

.justify-start {
  justify-content: flex-start;
  text-align: left;
}

.justify-center {
  justify-content: center;
  // text-align: center;
}

.justify-end {
  justify-content: flex-end;
  text-align: right;
}

.justify-around {
  justify-content: space-around;
}

.justify-between {
  justify-content: space-between;
}

.align-top {
  align-items: flex-start;
}

.align-middle {
  align-items: center;
}

.align-bottom {
  align-items: flex-end;
}

$column-default-width: calc(math.div(100vw, $grid-columns) - ($half-gutter-width * 2));
$column-gapped-width: math.div(100vw, $grid-columns);

// default grid

.col,
[class^='col-'],
.inner-col,
[class^='inner-col'] {
  padding-right: $half-gutter-width;
  padding-left: $half-gutter-width;
  flex: 1 0 auto;
  width: 100%;
}

.col-fill,
.inner-col-fill {
  width: auto;
  flex: 1 0 auto;
  padding-right: $half-gutter-width;
  padding-left: $half-gutter-width;
}

@for $i from 1 through $grid-columns {
  .col-#{$i} {
    flex-basis: math.div(100%, $grid-columns) * $i;
    max-width: math.div(100%, $grid-columns) * $i;
  }
  .col-#{$i}-5 {
    flex-basis: math.div(100%, $grid-columns) * ($i + 0.5);
    max-width: math.div(100%, $grid-columns) * ($i + 0.5);
  }

  .inner-col-#{$i} {
    @include inner-col($i);
  }
  .inner-col-#{$i}-5 {
    @include inner-col($i + 0.5);
  }
}

@for $i from 0 through $grid-columns {
  .offset-#{$i} {
    @if $i==0 {
      margin-left: 0;
    } @else {
      margin-left: math.div(100%, $grid-columns) * $i;
    }
  }

  .inner-offset-#{$i} {
    @if $i==0 {
      margin-left: 0;
    } @else {
      @include inner-col-size(margin-left, $i);
    }
  }
}

@mixin grid-col($i: 1) {
  padding-right: $half-gutter-width;
  padding-left: $half-gutter-width;
  flex: 1 0 auto;
  width: 100%;

  flex-basis: math.div(100%, $grid-columns) * $i;
  max-width: math.div(100%, $grid-columns) * $i;
}

// @for $i from 0 through $grid-columns {
//   .col-push-#{$i} {
//     @if $i==0 {
//       margin-right: 0;
//     } @else {
//       margin-right: 100% / $grid-columns * $i;
//     }
//   }
// }

@each $breakpoint, $media in $grid-breakpoints {
  @include breakpoint($breakpoint, min) {
    .col-#{$breakpoint} {
      flex-grow: 1;
      max-width: 100%;
    }

    .col-#{$breakpoint}-fill,
    .inner-col-#{$breakpoint}-fill {
      width: auto;
      flex: 1 0 auto;
      padding-right: $half-gutter-width;
      padding-left: $half-gutter-width;
    }

    @for $i from 1 through $grid-columns {
      .col-#{$breakpoint}-#{$i} {
        flex-basis: math.div(100%, $grid-columns) * $i;
        max-width: math.div(100%, $grid-columns) * $i;
      }

      .col-#{$breakpoint}-#{$i}-5 {
        flex-basis: math.div(100%, $grid-columns) * calc($i + 0.5);
        max-width: math.div(100%, $grid-columns) * calc($i + 0.5);
      }
      .inner-col-#{$breakpoint}-#{$i} {
        @include inner-col($i);
      }
      .inner-col-#{$breakpoint}-#{$i}-5 {
        @include inner-col($i + 5);
      }
    }

    @for $i from 0 through $grid-columns {
      .offset-#{$breakpoint}-#{$i} {
        @if $i==0 {
          margin-left: 0;
        } @else {
          margin-left: math.div(100%, $grid-columns) * $i;
        }
      }
      .inner-offset-#{$breakpoint}-#{$i} {
        @if $i==0 {
          margin-left: 0;
        } @else {
          @include inner-col-size(margin-left, $i);
        }
      }
    }
  }
}

%neg-container {
  @each $breakpoint, $padding in $grid-container-padding {
    @if $breakpoint== 'xs' {
      margin: 0 (-$padding);
    } @else {
      @include breakpoint($breakpoint, min) {
        margin: 0 (-$padding);
      }
    }

    > * {
      @if $breakpoint== 'xs' {
        padding: 0 $padding;
      } @else {
        @include breakpoint($breakpoint, min) {
          padding: 0 $padding;
        }
      }
    }
  }
}

@mixin col($i: $grid-columns, $columns: $grid-columns) {
  padding-right: $half-gutter-width;
  padding-left: $half-gutter-width;
  flex: 1 0 auto;
  width: 100%;
  flex-basis: math.div(100%, $columns) * $i;
  max-width: math.div(100%, $columns) * $i;
}

@mixin col-width($i: $grid-columns, $columns: $grid-columns) {
  width: 100%;
  max-width: math.div(100%, $columns) * $i;
}

@mixin modal-width($i: $grid-columns, $columns: $grid-columns) {
  min-width: math.div(100%, $columns) * $i;
  // to do: calcolare anche i gutter del container
}

@mixin breakpointsVars($breakpoint) {
  $container_padding: calc(map-get($grid-container-padding, $breakpoint) * 2);
  $container_fill_padding: $container_padding;

  $inner_single_space: 1.2rem;

  $inner_column_spaces: calc($inner_single_space * 23);

  $container_width: calc(100vw - $container_padding);

  @if $breakpoint == xxl {
    $container_width: calc(192rem - $container_padding);
    $container_fill_padding: calc(100vw - $container_width);
  }

  $one_col_width: calc(($container_width - $inner_column_spaces) / 24);

  --container-padding: #{$container_padding};
  --container-fill-padding: #{$container_fill_padding};
  --container-fill-half-padding: #{calc($container_fill_padding / 2)};
  --container-width: #{$container_width};
  --container-gap: #{$inner_single_space};
  --col-width: #{$one_col_width};
}

:root {
  @each $breakpoint, $media in $grid-breakpoints {
    @if $breakpoint == xs {
      @include breakpointsVars($breakpoint);
    } @else {
      @include breakpoint($breakpoint, min) {
        @include breakpointsVars($breakpoint);
      }
    }
  }
}

// @mixin inner-col($css, $cols: 1) {
//   @each $breakpoint, $media in $grid-breakpoints {
//     @include breakpoint($breakpoint, min) {
//       $container_padding: calc(map-get($grid-container-padding, $breakpoint) * 2);

//       $inner_single_space: 1.2rem;

//       $inner_column_spaces: calc($inner_single_space * 23);

//       $container_width: calc(100vw - $container_padding);

//       @if $breakpoint == xxl {
//         $container_width: calc(192rem - $container_padding);
//       }

//       $one_col_width: calc(($container_width - $inner_column_spaces) / 24);

//       $component_inner_spaces: calc($inner_single_space * ($cols - 1));

//       #{$css}: calc(($cols * $one_col_width) + $component_inner_spaces);
//     }
//   }
// }
