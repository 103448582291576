.login-register-social {
  gap: 2.4rem;
  &.is-inline {
    gap: 4rem;
    row-gap: 2rem;
    flex-direction: row;
  }
}

.other-login-methods {
  gap: 2.4rem;
}
