.order-confirm {
  @include checkoutLargeSpacing();
  &__title {
    margin-bottom: 5.2rem;
  }
  &__payment {
    padding-top: 0;
  }

  &__summary {
    .bag-product:last-of-type {
      padding-bottom: 0;
    }
  }
}
