.editorial-hero {
  // margin-top: calc(var(--header-h) * -1);
  // margin-bottom: 0.7rem;
  color: $content-white;
  position: relative;

  &--hidden {
    margin-top: var(--header-h);
  }

  > .container {
    height: 52rem;
    padding-top: calc(2.4rem + $header-height-mobile);
    padding-bottom: 2.4rem;
    position: relative;
  }

  ul {
    @include reset-list;
  }

  .row {
    justify-content: center;
  }

  > .row {
    position: relative;
    z-index: 1;
  }

  .branded-image,
  .branded-image::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .branded-image {
    z-index: 0;
    &::before {
      content: '';
      background-color: rgba(0, 0, 0, 0.55);
    }
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  &__description {
    margin-top: 2.8rem;

    &.open {
      span {
        display: inline;
      }
    }
  }

  &__read-more {
    &-text {
      display: none;
    }

    &-button {
      margin-top: 2.4rem;
      display: block;
    }
  }
}

.editorial-hero__breadcrumb {
  margin-bottom: 6rem;
  a {
    text-decoration: none;
  }
}

.editorial-hero__counters {
  margin-top: 6rem;
  li button span {
    @extend %font__subtitle;
  }

  .editorial-hero__counter {
    display: inline-block;
    min-width: 2.4rem;
    text-align: left;
  }
}

.editorial-hero__visual {
  margin-top: 3.2rem;
}

.editorial-hero__image {
  max-width: 100%;
  height: auto;
}
