.section-menu {
  display: flex;
  flex-direction: column;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 100vw;
    height: $space-line;
    bottom: 0;
    background: $outline;
    left: 50%;
    transform: translateX(-50%);
  }
  &::after {
    content: '';
    position: absolute;
    width: 100vw;
    height: $space-line;
    top: 0;
    background: $outline;
    left: 50%;
    transform: translateX(-50%);
  }

  &[data-expanded='false'] {
    li:not(.selected) {
      display: none;
    }
  }

  &[data-expanded='true'] {
    padding-bottom: 2rem;

    .selected {
      &::after {
        transform: rotate(0);
      }

      a span {
        border-bottom: solid 0.1rem $content-black;
      }
    }
  }
  li {
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 0;
    height: 5.2rem;
    a {
      opacity: 1;
      width: 100%;
    }
    &:not(:last-child) {
      margin-bottom: 0;
    }
    &.selected {
      order: -1;
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 1.4rem;
        @include fixedSprite('10-up-chevron');
        transform: rotate(180deg);
        background-size: 120%;
        pointer-events: none;
      }
    }
  }
}
