.clothing {
  .ticker-bar {
    background-color: $alert-error;
    .message {
      font-weight: bold;
    }
    &.button-logout {
      padding: 0 2rem;
      justify-content: space-between;
      .button {
        text-transform: capitalize;
        font-weight: bold;
      }
      .message {
        margin: 0;
      }
    }
  }
}

.ticker-bar {
  display: flex;
  align-items: stretch;
  width: 100%;
  min-height: $ticker-bar-m;
  background-color: var(--ticker-bgcolor);
  transition: height 0.2s ease-out, background-color 0.2s ease-in-out;
  overflow: hidden;
  position: relative;
  @extend %font__cta;
  // @extend %primary-xs-u;
  --ticker-bgcolor: #{$content-black};
  --ticker-color: #{$content-white};
  --animation-delay: 5500ms;

  &.single-slide {
    .swiper-slide {
      white-space: initial;
    }
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    white-space: nowrap;
    justify-content: start;
  }

  .message-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 0 3.2rem;
  }

  .message {
    -webkit-text-size-adjust: 100%;
    text-align: center;
    position: relative;
    margin: 0 auto;
    color: var(--ticker-color);
    transition: color 0.2s ease-in-out, transform 0s linear 2700ms;
    span,
    a {
      color: var(--ticker-color) !important;
    }
  }

  .swiper-initialized {
    .message::after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      z-index: 1;
      background-color: var(--ticker-bgcolor);
      transition: background-color 0.2s ease-in-out;
    }
  }

  // animations starts on slide active
  .swiper-slide-active {
    .message {
      &::after {
        animation: reveal 300ms ease-in-out 400ms forwards, cover 300ms ease-in-out 5700ms forwards;
        animation-delay: 400ms, var(--animation-delay);
      }
    }
    .message.swipe-animation {
      position: absolute;
      // animation: move 2s linear 2s forwards;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    // for default ovverride
    margin-top: initial;
    width: 2.4rem;
    height: 2.4rem;
    // at the moment the outline does not work
    outline-color: var(--ticker-color);
    background: initial;
    $sprite: map-get($sprites, '10-right-chevron');
    background-color: var(--ticker-color);
    -webkit-mask-image: url($sprite);
    mask-image: url($sprite);
    mask-size: cover;
    z-index: 1;
    transition: background-color 0.2s ease-in-out;
  }

  @mixin a11n-fix() {
    border: 0.2rem solid var(--ticker-color);
  }

  [class^='a11n-fix'] {
    position: absolute;
    top: 50%;
    width: 2.6rem;
    height: 2.6rem;
  }

  .a11n-fix {
    &--prev {
      left: 0.4rem;
      transform: translateY(-50%) rotate(180deg);
    }
    &--next {
      right: 0.4rem;
      transform: translateY(-50%);
    }
    &--close {
      right: $space-unit;
      transform: translateY(-50%);
    }
  }

  .swiper-button-next {
    right: 0.4rem;
    transform: translateY(-50%);

    &:focus-visible + .a11n-fix--next {
      @include a11n-fix();
    }
  }
  .swiper-button-prev {
    left: 0.4rem;
    transform: translateY(-50%) rotate(180deg);

    &:focus-visible + .a11n-fix--prev {
      @include a11n-fix();
    }
  }

  &.clickfromstore {
    background-color: $alert-error;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: $content-white;
    .button {
      position: absolute;
      right: 1.2rem;
      text-transform: capitalize;
      color: $content-white;
    }
  }

  .icon--close-white {
    position: absolute;
    right: $space-unit;
    top: 50%;
    transform: translateY(-50%);
    outline-color: var(--ticker-color);
    background: initial;
    $sprite: map-get($sprites, '10-close-white');
    background-color: var(--ticker-color);
    -webkit-mask-image: url($sprite);
    mask-image: url($sprite);
    mask-size: cover;
    z-index: 1;
    transition: background-color 0.2s ease-in-out;
    width: 2.4rem;
    height: 2.4rem;

    &:focus-visible + .a11n-fix--close {
      @include a11n-fix();
    }
  }

  &.close {
    height: 0;
  }
}

@keyframes reveal {
  0% {
    width: 100%;
    left: 0%;
  }
  100% {
    width: 0%;
    left: 100%;
  }
}

@keyframes cover {
  0% {
    left: 0%;
    width: 0%;
  }
  100% {
    left: 0%;
    width: 100%;
  }
}
