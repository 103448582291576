.card {
  &-content {
    img {
      margin-bottom: $space-unit * 4;
      &.lazyload,
      &.lazyloading {
        opacity: 1;
        @include animate-skeleton;
      }
    }
  }
}
