.size-modal {
  .sizechart--measure {
    flex-direction: column;
  }
  .sizechart__wrapper {
    width: auto;
  }
  .sizechart__mannequin {
    width: 100%;
    align-self: center;
    margin-top: 4rem;
    min-height: 450px;
  }

  .sizeguide-modelsizes {
    margin: 0;
    border: none;
    padding: 0;
    .sizeguide-modelsizes-head {
      margin-bottom: 1.6rem;
    }
    img {
      max-width: 12.9rem;
      margin-right: 1.6rem;
      align-self: flex-start;
    }
    .sizeguide-modelsizes-head {
      margin-top: 0.2rem;
    }
    .sizeguide-modelsizes-table {
      display: flex;
      flex-direction: column;
      row-gap: 0.4rem;
    }
  }
  .sizeguide-modelsizes-accordion-item {
    border: 1px solid $background-product;
    padding: 1.2rem;
    margin: 6rem 0;
    .accordion__header {
      padding-right: 2.4rem;
    }
    .header__open,
    .header__close {
      top: 1.2rem;
    }
    .sizeguide-modelsizes-table {
      text-align: left;
    }
    .accordion__panel_open {
      > div {
        padding: 0;
      }
      > .sizeguide-modelsizes-table {
        display: none;
      }
    }
  }
  .sizechart-tabs [role='tablist'] {
    $size-modal-paddings: 1.2rem;
    margin: 0 -$size-modal-paddings;
    padding: 0 $size-modal-paddings;
    border-bottom: 1px solid $outline;
    &::before {
      display: none;
    }

    [role='tab'] {
      flex-shrink: 0;
    }
  }
}
