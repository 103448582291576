.shopthelook {
  margin: 6rem 0;
}
.shopthelook-upper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.2rem;
}
.shopthelook-title {
  display: block;
}
.shopthelook__arrow-container {
  display: flex;
}
.shopthelook__arrow {
  display: flex;
  &[disabled] {
    opacity: 0.2;
  }
}
.shopthelook-items {
  .button {
    width: 100%;
    display: block;
  }
}
.shopthelookpanel .product-tile__quick-buy .product-tile__price {
  display: none;
}
.shopthelookpanel-images {
  display: flex;
  gap: 1.2rem;
}
.shopthelookpanel-link {
  text-transform: uppercase;
}
.shopthelookpanel-item {
  .product-tile__image-wrapper {
    background-color: $content-white;
  }
  .product-tile__info {
    height: auto;
    .product-tile__info_content {
      position: static;
    }
    .product-tile__quick-buy-panel {
      .alert {
        margin-top: 3.3rem;
        display: flex;
        align-items: center;
        gap: 1.1rem;
        font-size: 1.4rem;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
    .product-tile__quick-buy {
      flex-direction: column;
      > div,
      button {
        width: 100%;
      }
    }
  }
  .swiper-container {
    overflow: unset;
  }
  .swiper-wrapper {
    transform: none;
    .swiper-slide:not(.swiper-slide-active) {
      position: absolute;
      z-index: 1;
      opacity: 0;
      pointer-events: none;
    }
    .swiper-slide {
      opacity: 1;
    }
  }
}
.shopthelookmodule {
  display: flex;
  flex-direction: column;
  .shopthelookmodule-closeup,
  .shopthelookmodule-hero {
    position: relative;
  }
  .shopthelookmodule-hero {
    background-color: #f1f1f1;

    .shopthelookmodule-title {
      padding: 3.9rem 1.2rem 0;
      display: flex;
      justify-content: space-between;
    }

    .shopthelookmodule-thumbs {
      display: flex;
      gap: 1.2rem;
      justify-content: center;
      &.long {
        justify-content: flex-start;
      }
      .button {
        width: 6.7rem;
        &:first-child {
          margin-left: 1.2rem;
        }
      }
      .button.active {
        position: relative;
        &::before {
          content: '';
          background-color: $content-black;
          height: 1px;
          width: 100%;
          position: absolute;
          bottom: 0;
          z-index: 1;
        }
      }
      &.react-tabs__tab-list .shopthelookmodule-tab[role='tab'] {
        margin: 0;
        padding: 0;
        height: 9rem;
        flex: 0 0 6.7rem;
      }
    }

    .product-video-pause {
      position: absolute;
      left: auto;
      top: auto;
      right: 1.2rem;
      bottom: 1.2rem;
      width: auto;
      height: auto;
      background: none;
      display: flex;
      align-items: center;
      z-index: 2;
    }
  }
  .shopthelookmodule-closeup {
    .product-tile {
      padding: 5.6rem;
      .product-tile__name {
        height: auto;
        margin-top: 1.5rem;
      }
      .product-tile__seoname {
        height: auto;
      }
      .product-tile_quick-buy-wrp {
        /* opacity transition seems to cause issues here */
        transition: none;
        &:empty {
          margin-top: 0;
        }
      }
      .product-tile__price,
      .product-tile__quick-buy {
        margin-top: 1.2rem;
      }
      .product-tile__quite {
        padding-top: 1.2rem;
      }
    }
  }
}
.shopthelookpanel .product-tile__quick-buy-panel,
.shopthelookmodule .product-tile__quick-buy {
  .alert {
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
  }
}
// .shopthelookmodule-thumbs,
// .shopthelook-title {
//   position: absolute;
//   top: 50%;
//   z-index: 1;
//   transform: translateY(-50%);
// }
// .shopthelook-title {
//   left: 2.2rem;
// }
