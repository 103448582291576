.tabs {
  [role='tab'] {
    // @extend %primary-s-u;
    cursor: pointer;
    padding: 1.6rem 0 1.4rem;
    position: relative;
    display: flex;
    justify-content: left;
    flex-grow: 1;
    > div {
      position: relative;
    }

    &[aria-selected='true'] {
      font-weight: bold;
      &::after {
        content: '';
        position: absolute;
        height: $space-line;
        background-color: black;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }

  .client-service__tablist {
    & > li {
      &[aria-selected='false'] {
        &::after {
          content: '';
          position: absolute;
          height: $space-line;
          bottom: 0;
        }
      }
    }
  }

  .react-tabs__tab--disabled {
    pointer-events: none;
    cursor: initial;
    opacity: 0.55;
  }
}
