.product-selection {
  padding: 4rem 0 4rem;
  &__breadcrumb {
    margin-bottom: 3.2rem;
    a {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      @extend %font__cta;
    }
  }

  &__maininfo {
    margin-bottom: 6rem;
  }

  &__line-labels {
    margin-bottom: 4rem;
    span {
      @extend %font__subtitle;
    }
  }

  &__name {
    @extend %font__title;
  }

  &__seoname {
    margin-top: 0.4rem;
    margin-bottom: 2.4rem;
  }

  &__description {
    margin-bottom: 1.6rem;
  }

  &__name + &__description {
    margin-top: 2.4rem;
  }

  &__price {
    margin-top: 4rem;
    @extend %font__title;
    display: block;
  }

  &__actions-shipping.on-pdp {
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.6rem;
  }

  &__helpertext {
    margin: 4.3rem 0 2rem;
    &.sizesShown {
      margin-top: 7.8rem;
    }
  }

  &__ctas {
    display: flex;
    gap: 0.8rem;
    margin-bottom: 0.8rem;
    .selector__outofstock {
      background-color: $disabled-light;
    }
    button,
    .button {
      flex: 1;
      &[class*='icon'] {
        flex: 0;
      }
    }
  }

  .selector-color {
    overflow: unset;
  }
}

.selector {
  &__label {
    color: $content-black;
    display: flex;
    gap: 0.6rem;
    &__name {
      @extend %font__title;
    }
  }

  &__error {
    margin-bottom: 0.4rem;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    transform: translateX(-0.4rem);
    .errormessage {
      color: $alert-error;
      @extend %font__title;
    }
  }
}

#product-selection-scrolltrigger {
  height: 2px;
  background-color: aqua;
  width: 100%;
}
