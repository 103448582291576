.order-confirm {
  @include checkoutLargeSpacing();
  padding-bottom: 10rem;

  &__title {
    @extend %font__title;
    @include border('bottom');
    padding-bottom: 0.6rem;
    margin-bottom: 6rem;
  }

  &__subtitle {
    @extend %font__title;
    padding-bottom: 1.2rem;
  }

  &__totalslist {
    @include checkoutSmallSpacing();
  }

  &__payment {
    @include checkoutSpacing();
  }

  &__summary {
    .bag-product {
      padding-bottom: 2.4rem;
      margin-bottom: 2.4rem;

      &:last-of-type {
        padding-bottom: 3.2rem;
        margin-bottom: 0;
      }

      &__details {
        margin-bottom: 0;
      }

      &__image {
        width: 10.4rem;
        flex-grow: 0;
      }
    }

    .text-section__dl {
      grid-template-columns: 1.25fr 0.75fr;
      row-gap: 0.8rem;
    }
  }

  .container-bag-summary {
    position: relative;

    .fixed-summary-mobile {
      @include animate(1);
      position: absolute;
      top: 100%;
      opacity: 0;
      visibility: hidden;
      z-index: 0;
      left: 0;
      right: 0;
      margin: -0.2rem -0.1rem 0;
      background-color: $content-white;
      transition-property: opacity, visibility, transform;
      transform: translateY(-1rem);
      padding: 1.2rem;
      @include border();
      border-top-width: 0;
      &.visible {
        z-index: 1;
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }

      .collapsed-row {
        @include animate(0.5);
        overflow: hidden;
      }
    }
  }

  .editorial-row {
    padding-top: 14rem;
    padding-bottom: 4rem;
  }

  .remember-me {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 5.2rem;
    margin-top: 2rem;
  }
}
