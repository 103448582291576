// COLOR SELECTOR USED IN: PLP Tile

.selector-color {
  position: relative;
  width: 100%;
  color: $content-black;
  overflow: hidden;

  .selector__header {
    margin-bottom: 1.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .selector__menu {
    display: flex;
    flex-direction: column;

    &--grid {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      column-gap: 0.6rem;
      row-gap: 0.2rem;

      .selector__menu__item {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .label {
      @extend %font__title;
    }

    &-labels {
      padding-bottom: 0.8rem;
    }

    &-value {
      margin-left: 0.6rem;
    }

    .selector__menu-list {
      @include reset-list;
      display: flex;
      gap: 1rem;
    }

    &__item {
      display: flex;
      align-items: center;
      border-bottom: solid 1px transparent;
      padding-bottom: 0.4rem;

      position: relative;
      justify-content: center;
      cursor: pointer;

      .selector__item-radio {
        appearance: initial;
        -webkit-appearance: initial;
        margin: initial;
        border: initial;
        outline: none;
        width: 0;
        height: 0;

        &:focus-visible ~ .swatch {
          outline: 0.2rem solid;
          outline-offset: 0.1rem;
        }
      }

      .swatch {
        position: relative;
        width: 7.2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        @include border();
        &--label {
          margin-left: 1.2rem;
        }
      }

      &.is-disabled {
        .swatch::after {
          content: '';
          position: absolute;
          width: 3.2rem;
          height: 0.1rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
          background-color: $content-white;
          mix-blend-mode: difference;
        }
      }

      &:focus-visible {
        outline-offset: 0.1rem;
      }

      &.selected {
        border-bottom: solid 1px $content-black;
      }
    }
  }

  //INTILE VARIANT

  &.intile {
    height: 0;

    &:not(.inwishlist) {
      visibility: hidden;
    }

    .selector-color__hasmore {
      @extend %font__subtitle;
      line-height: 2rem;
    }

    .selector__menu {
      &__item {
        &__label {
          @extend .visually-hidden;
        }
      }
    }

    &.is-expanded {
      height: auto;
    }
  }

  &.small-swtches {
    .selector__menu {
      &-list {
        gap: 0.4rem;
        display: grid;
        grid-template-columns: repeat(6, minmax(4rem, 1fr));

        label {
          @include minClicableArea(2.4rem, 4rem);
        }
      }
      .swatch {
        min-width: 4rem;
        width: 100%;
        height: 1rem;
      }
    }
  }

  &.inpdp {
    margin-bottom: 2.4rem;

    .swiper {
      overflow: hidden;
    }
    .selector__menu {
      display: block;
      $mov: -0.3rem;
      margin: 0 -0.3rem; //adjust overflow for accessibility

      ul {
        display: flex;
        flex-direction: row;
        gap: 0.8rem;
        flex-wrap: wrap;
      }
      &__item {
        margin: 0.3rem;
        display: block;
        min-height: unset;
        .swatch {
          width: 100%;
        }
        &__label {
          display: none;
        }

        input {
          -webkit-appearance: initial;
          margin: initial;
          border: initial;
          outline: none;
          width: 0;
          height: 0;
          position: absolute;
          left: 0;
        }
        [data-whatintent='keyboard'] & {
          &:focus-within {
            position: relative;
            &:after {
              //adjust overflow for accessibility
              content: '';
              position: absolute;
              left: $mov;
              top: $mov;
              right: $mov;
              bottom: -0.4rem;
              border: 0.2rem solid black;
            }
          }
        }
      }
    }
  }

  &-dropdown {
    border-bottom: solid 0.1rem rgba(204, 204, 204, 1);
    margin-bottom: 2.4rem;
    .selector__menu {
      #colors-dropdown {
        width: 100%;
      }

      ul {
        li {
          width: 100%;
        }
        padding: 0;
        gap: 0;
        flex-direction: column;
        align-items: stretch;
        width: 100%;
        padding-bottom: 1.2rem;
      }

      &__item {
        display: flex;
        width: 100%;
        padding-top: 1.6rem;
        padding-bottom: 1.6rem !important;
        text-decoration: none;

        &:focus-visible {
          outline: 0.2rem solid;
        }

        &--button {
          position: relative;
          &::after {
            position: absolute;
            content: '';
            @include fixedSprite('10-down-chevron');
            right: 0;
            @include animate(0.4, 'transform');
          }

          &.active {
            &:after {
              transform: rotate(-180deg);
            }
          }
        }
      }
    }
    .selector-color {
      margin-bottom: 0;
    }
  }

  &.with-carousel {
    .selector__menu {
      .swatch {
        width: 100%;
      }
    }
  }

  fieldset {
    min-inline-size: auto;
  }
}
.selector-wrapper {
  width: 100%;
}

.selector-navigation {
  display: flex;
  gap: 0.8rem;
  .selector-button {
    height: 1.8rem;
    display: flex;
    align-items: center;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 2.4rem;
    }
    &.swiper-button-disabled {
      opacity: 0.4;
    }
  }

  &-label {
    flex-grow: 1;
    button {
      margin-left: -0.6rem;
      @extend %font__labelling;
    }
  }

  &-chevrons {
    display: flex;
    flex-wrap: nowrap;
  }

  &.selector-navigation-top {
    position: absolute;
    right: 0;
    z-index: 1;
  }
}
