.wishlist {
  &__intro {
    display: flex;
    justify-content: space-between;

    &--left {
      @include absolute-col(7);
    }
  }

  &-wrapper {
    @include checkoutSmallSpacing();
    @include checkoutSpacing('bottom');
  }

  &--empty {
    padding-top: 4rem;
    padding-bottom: ($space-unit * 30);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      text-align: center;
      margin-bottom: $space-unit * 4;
    }

    &__button-container {
      padding: 0 1.6rem;
    }
  }

  &__list {
    @include reset-list;
    > li + li {
      @include border('top');
      @include checkoutSpacing();
    }
    > li {
      @include checkoutSpacing('bottom');

      .bag-product {
        margin-top: 0.1rem;
        margin-bottom: 0.1rem;
        &__actions {
          justify-content: space-between;
          align-items: baseline;
        }
        &__content {
          overflow: visible;
        }
      }
    }
  }

  &__banner-wrapper {
    @include reset-list;
  }

  &-rail {
    .grid-container {
      row-gap: 6rem;
    }

    &--2cols {
      .grid-container {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }
  }

  //MODAL
  &-share {
    .ReserveForm {
      display: flex;
      flex-direction: column;
      gap: 4rem;
    }
  }
}

.wishlist--publicpage {
  overflow: hidden;
}
