.cs-content-block {
  &-card {
    &_text {
      flex-grow: 1;
    }

    p {
      padding-right: 4rem;
    }
    p + a {
      margin-top: 1.2rem;
    }
  }

  .row {
    row-gap: 4rem;
  }
}
