$ratios: (
  4: 3,
  3: 2,
  16: 9,
  1: 1,
);

.aspect-ratio--4-3 {
  &::before {
    @extend %default;
    padding-top: 75%;
  }
}

.aspect-ratio--3-4 {
  &::before {
    @extend %default;
    padding-top: 133%;
  }
}

.aspect-ratio--2-3 {
  &::before {
    @extend %default;
    padding-top: 150%;
  }
}

.aspect-ratio--3-2 {
  &::before {
    @extend %default;
    padding-top: 66.66%;
  }
}

.aspect-ratio--1-1 {
  &::before {
    @extend %default;
    padding-top: 100%;
  }
}

.aspect-ratio--21-9 {
  &::before {
    @extend %default;
    padding-top: 42.86%;
    @media (max-width: 1023px) {
      padding-top: 133%;
    }
  }
}

.aspect-ratio--16-9 {
  &::before {
    @extend %default;
    padding-top: 56.25%;
  }
}
.aspect-ratio--9-16 {
  &::before {
    @extend %default;
    padding-top: 177.77%;

    @include breakpoint(lg, min) {
      padding-top: 56.25%;
    }
  }
}

.aspect-ratio--3-1 {
  &::before {
    @extend %default;
    padding-top: 33.3%;
    @media (max-width: 599px) {
      padding-top: 100%;
    }
  }
}

.aspect-ratio--viewport {
  &::before {
    @extend %default;
    padding-top: calc(var(--view-height) - #{$header-height-desktop});
    @media (max-width: 1023px) {
      padding-top: calc(var(--view-height) - #{$header-height-mobile});
    }
  }
}

.has-ticker {
  .aspect-ratio--viewport {
    &::before {
      padding-top: calc(var(--view-height) - #{$header-height-desktop} - #{$ticker-bar});
      @media (max-width: 1023px) {
        padding-top: calc(var(--view-height) - #{$header-height-mobile} - #{$ticker-bar-m});
      }
    }
  }
}

@each $aspect1, $aspect2 in $ratios {
  .image-ratio--#{$aspect1}-#{$aspect2} {
    @extend %default-img;
    aspect-ratio: #{$aspect1} / #{$aspect2};
  }
  .image-ratio--#{$aspect2}-#{$aspect1} {
    @extend %default-img;
    aspect-ratio: #{$aspect2} / #{$aspect1};
  }
}

%default {
  content: '';
  display: block;
  width: 100%;
}
%default-img {
  width: 100%;
  object-fit: contain;
}
