@use 'sass:math';
// DONOTCOMMIT
@each $multiply in $spacing-multiplier {
  $space: $space-unit * $multiply;
  $space-nounit: math.div($space, 0.1rem);
  // margin bottom
  .mb-#{$space-nounit} {
    margin-bottom: $space;
  }
  // margin top
  .mt-#{$space-nounit} {
    margin-top: $space;
  }
  // margin y axes
  .my-#{$space-nounit} {
    margin-top: $space;
    margin-bottom: $space;
  }
  // padding bottom
  .pb-#{$space-nounit} {
    padding-bottom: $space;
  }
  // padding top
  .pt-#{$space-nounit} {
    padding-top: $space;
  }
  // padding y axes
  .py-#{$space-nounit} {
    padding-top: $space;
    padding-bottom: $space;
  }
  // padding x axes
  .px-#{$space-nounit} {
    padding-left: $space;
    padding-right: $space;
  }
  // min-height
  .min-#{$space-nounit} {
    min-height: $space;
  }

  // gap
  .gap-#{$space-nounit} {
    gap: $space;
  }
  // row-gap
  .row-gap-#{$space-nounit} {
    row-gap: $space;
  }
  // column-gap
  .column-gap-#{$space-nounit} {
    column-gap: $space;
  }

  @each $name, $br in $grid-breakpoints {
    @include breakpoint($name, min) {
      // margin bottom
      .mb-#{$name}-#{$space-nounit} {
        margin-bottom: $space;
      }
      // margin top
      .mt-#{$name}-#{$space-nounit} {
        margin-top: $space;
      }
      // margin y axes
      .my-#{$name}-#{$space-nounit} {
        margin-top: $space;
        margin-bottom: $space;
      }
      // padding bottom
      .pb-#{$name}-#{$space-nounit} {
        padding-bottom: $space;
      }
      // padding top
      .pt-#{$name}-#{$space-nounit} {
        padding-top: $space;
      }
      // padding y axes
      .py-#{$name}-#{$space-nounit} {
        padding-top: $space;
        padding-bottom: $space;
      }
      // padding x axes
      .px-#{$name}-#{$space-nounit} {
        padding-left: $space;
        padding-right: $space;
      }
      // min-height
      .min-#{$name}-#{$space-nounit} {
        min-height: $space;
      }

      // gap
      .gap-#{$name}-#{$space-nounit} {
        gap: $space;
      }
      // row-gap
      .row-gap-#{$name}-#{$space-nounit} {
        row-gap: $space;
      }
      // column-gap
      .column-gap-#{$name}-#{$space-nounit} {
        column-gap: $space;
      }
    }
  }
}

.d-none {
  display: none !important;
}
.d-block {
  display: block;
}
.d-flex {
  display: flex;
}
.d-column {
  display: flex;
  flex-direction: column;
}
.d-row {
  display: flex;
  flex-direction: row;
}

.d-abs {
  display: absolute;
}

.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
@each $name, $br in $grid-breakpoints {
  @include breakpoint($name, min) {
    .d-#{$name}-none {
      display: none !important;
    }
    .d-#{$name}-block {
      display: block !important;
    }
    .d-#{$name}-flex {
      display: flex !important;
    }
    .d-#{$name}-inline-flex {
      display: inline-flex !important;
    }
    .d-#{$name}-inline {
      display: inline-flex !important;
    }

    .d-#{$name}-column {
      display: flex !important;
      flex-direction: column;
    }
    .d-#{$name}-row {
      display: flex !important;
      flex-direction: row;
    }
  }
}

@for $i from 1 through 12 {
  .f-order-#{$i} {
    order: $i;
  }

  @each $name, $br in $grid-breakpoints {
    @include breakpoint($name, min) {
      .f-order-#{$name}-#{$i} {
        order: $i !important;
      }
    }
  }
}

.hidden {
  // note that this is hidden to screen reader
  visibility: hidden;
}

.link,
.link-button,
a {
  @extend %font__link;
  color: $content-black;
}

.block {
  display: block;
}

img[data-sizes='auto'] {
  display: block;
  width: 100%;
}

img[data-sizes='fluid'] {
  display: block;
  max-width: 100%;
}

.lazyload,
.lazyloading {
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  // transition: opacity 300ms;
}

.disclaimer-single-byte-jp {
  display: block;
  margin-top: $space-unit;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

@each $breakpoint, $media in $grid-breakpoints {
  @include breakpoint($breakpoint, min) {
    .text-#{$breakpoint}-center {
      text-align: center;
    }
    .text-#{$breakpoint}-left {
      text-align: left;
    }
    .text-#{$breakpoint}-right {
      text-align: right;
    }
  }
}

.error-message {
  margin-top: $space-unit;
  font-weight: 600;
}

.info-box {
  border: 1px solid $outline;
  padding: 1.2rem;
  width: 100%;
  margin-top: 2.4rem;
  margin-bottom: 0.8rem;
  > b {
    display: block;
    margin-bottom: $space-unit * 2;
  }
}

.image-background--half {
  &:after {
    content: '';
    width: 100%;
    height: 50%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    pointer-events: none;
  }
}
.image-background--whole {
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    pointer-events: none;
  }
}

.image-background--bright {
  &:after {
    background: linear-gradient(180deg, rgba(63, 63, 63, 0) 0%, rgba(63, 63, 63, 0.4) 100%);
  }
}
.image-background--darker {
  &:after {
    background: linear-gradient(0deg, rgba(63, 63, 63, 0.7) 0%, rgba(63, 63, 63, 0) 100%);
  }
}

.text-shadow-active {
  text-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.2);

  .button--arrow-right[class*='inverted']::after {
    filter: drop-shadow(0 0 1px $content-black);
  }
}

.bold {
  font-weight: bold;
}

.button-underline {
  display: block;
  margin-top: $space-unit;
  font-weight: bold;
  text-decoration: underline;
  outline-offset: 2px;
  text-underline-offset: 2px;
}

.text-disclaimer {
  @extend %font__copy;
  margin-bottom: 1.6rem;
  a {
    font-weight: bold;
  }
}
.bag-disclaimer {
  font-weight: bold;
  margin-top: 1.2rem;
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-align: left;
  > * {
    text-align: left;
  }
}

.border-top {
  border-top: 1px solid $outline;
}
.border-bottom {
  border-bottom: 1px solid $outline;
}

.disabled-text {
  color: $disabled-dark;
}

.no-overflow-container {
  overflow: hidden;
}

.invert {
  filter: invert(1);
}
//   .image-background--radial {
//     .promo-column__img-holder {
//       &::before {
//         position: relative;
//         z-index: 1;
//         background: radial-gradient(50% 50% at 50% 50%, rgba(63, 63, 63, 0.25) 0%, rgba(63, 63, 63, 0) 100%);
//       }
//     }
//     &::before {
//       position: relative;
//       z-index: 2;
//       background: radial-gradient(50% 50% at 50% 50%, rgba(63, 63, 63, 0.25) 0%, rgba(63, 63, 63, 0) 100%);
//     }
//   }

//   .image-background--linear {
//     .promo-column__img-holder {
//       &::before {
//         position: relative;
//         z-index: 1;
//         background: linear-gradient(0deg, rgba(63, 63, 63, 0.25) 0%, rgba(63, 63, 63, 0.25) 0.01%, rgba(63, 63, 63, 0) 100%);
//       }
//     }
//     &::before {
//       position: relative;
//       z-index: 2;
//       background: linear-gradient(0deg, rgba(63, 63, 63, 0.25) 0%, rgba(63, 63, 63, 0.25) 0.01%, rgba(63, 63, 63, 0) 100%);
//     }
//   }
// }

@include breakpoint(md) {
  .row-reverse-mobile {
    flex-direction: column-reverse;
    row-gap: 1.2rem;
    [class^='col-'] {
      display: flex;
      justify-content: center;
    }
  }
}
