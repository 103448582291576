// grid settings
// prettier-ignore
$grid-breakpoints: (
  xs: 375px,
  sm: 768px,
  md: 1024px,
  lg: 1280px, //dovrebbe esserci un'altro breakpoint (1200px)
  xl: 1440px,
  xxl:1920px
);
$grid-columns: 24;
$grid-gutter-width: 1.2rem;
$grid-container-padding-single: 1.2rem;
$grid-container-padding: (
  xs: 1.2rem,
  sm: 1.2rem,
  md: 1.2rem,
  lg: 2.2rem,
  xl: 2.2rem,
  xxl: 1.8rem,
);
// $grid-max-width: (
//   xl: 1440px,
// );
$grid-overlay-base-color: rgba(2, 187, 156, 0.12);
$grid-overlay-colors: (
  xs: rgba(0, 0, 255, 0.2),
  sm: rgba(241, 126, 126, 0.2),
  md: rgba(130, 241, 126, 0.2),
  lg: rgba(218, 126, 241, 0.2),
  xl: rgba(126, 206, 241, 0.2),
  xxl: rgba(241, 126, 195, 0.2),
);
$repeating-width: calc(100% / #{$grid-columns});
$column-width: calc((100% / #{$grid-columns}) - #{$grid-gutter-width});
$product-ratio: 133.33334%;

$gutter-compensation: $grid-gutter-width * 0.5 * -1;
$half-gutter-width: $grid-gutter-width * 0.5;
