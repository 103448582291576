.wrapper-payment-methods {
  padding-bottom: 2.2rem;
}

.checkout-privacyflags {
  p {
    @include checkoutSmallSpacing();
    @include checkoutSmallSpacing('bottom');
  }
}
