.size-modal {
  .sizeguide-modelsizes {
    img {
      max-width: 6.7rem;
    }
    .sizeguide-modelsizes-head {
      margin-bottom: 2.8rem;
    }
  }
}
