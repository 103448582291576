.checkout-shipping {
  .spacerlabel {
    &::before {
      content: '\00a0'; // non breaking space
    }
  }
}
.shipping-tabs {
  .container {
    padding: 0;
  }

  .shipping-tabs-tablist {
    padding-left: 0;
    padding-right: 0;
  }

  .container-shipping-detail {
    padding-top: 3.2rem;
  }
}
