:root {
  --font-light: MonumentGroteskMono;
  --font-regular: MonumentGroteskMono-Regular;
  --font-medium: MonumentGroteskMono-Medium;
}

html {
  // scroll-behavior: smooth;
  /* evita gli spostamenti quando appare il menu che fa sparire la scrollbar */
  // disattivo temporaneamente per testare il fix sull'ovcerflow hidden sul body
  // overflow-y: scroll;
  font-size: 62.5%;

  // height: 100%; // Rompe il footer
  &.initial-scroll {
    scroll-behavior: initial;
  }
  &.smooth-scroll {
    scroll-behavior: smooth;
  }
  &.no-overflow-ios {
    &,
    body {
      overflow: hidden;
      height: 100%;
    }
  }
  &.mbm-html {
    height: 100%;
    body {
      height: 100%;
      min-height: initial;
      overflow: hidden;
    }
  }
}

body {
  @extend %font__copy;

  &[data-header-transparent='true'] {
    padding-top: 0;
  }
  font-size: 1.4rem;
  line-height: 1.8rem;
  letter-spacing: 0;
  color: $content-black;
  background-color: $content-white;
  position: relative;
  min-height: 100vh;
  //height: 100%; Rompeva footer
  height: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // fallback
  --scrollbar-width: 0px;

  &.no-fixedHeader {
    padding-top: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $overlay-modal;
    opacity: 0;
    pointer-events: none;
    @include z(overlay);
    // @include animate(0.15);
    @include animate(0.3);
  }

  &.overlay {
    &::before {
      opacity: 1;
      pointer-events: initial;
    }
  }

  &.no-overflow,
  &.ReactModal__Body--open {
    overflow: hidden;
    header,
    .footer:not(.checkout),
    #maincontent,
    .sticky-tray,
    .cookie-bar,
    .contextual-nav {
      padding-right: var(--scrollbar-width);
    }
  }

  &.hide-scroll {
    overflow: hidden;
  }

  &.page-template-ai_plp {
    main {
      padding-top: 0;
    }
  }
  &.page-scrolled {
    header {
      transform: translateY(-100%);
      .logo-header {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}

#top-page {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.1rem;
}

main {
  min-height: var(--viewport-height);
  // @include gray-gradient;
  background: #fff;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  // position: relative;

  &.no-padding {
    padding-top: 0;
  }

  // &.main--autoheight {
  //   min-height: calc(100vh - #{$footer-clientservice-height-desktop} - #{$header-height-desktop});
  //   padding-bottom: $space-unit * 9;
  // }

  // &.main--checkout {
  //   min-height: calc(100vh - #{$footer-clientservice-height-desktop} - #{$header-height-desktop});
  //   padding-bottom: $space-unit * 9;
  //   padding-top: 3.2rem;
  //   background: initial;
  //   .checkout-container {
  //     min-height: 20rem;
  //   }
  // }
}

img {
  max-width: 100%;
  margin: 0;
  padding: 0;
  display: block;
  height: auto;
}

ul,
ol {
  padding-top: $space-unit * 3;
  padding-bottom: $space-unit * 3;
  list-style-position: outside;
  padding-left: 1.8rem;
  list-style-type: disc;

  li {
    padding: $space-unit 0;
  }
}

a,
a:visited {
  color: inherit;
}

button,
[role='button'],
[role='option'] {
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  font: inherit;
  margin: 0;
  user-select: none;
}

input[type='search']::-webkit-search-cancel-button {
  /* Remove default cancel button */
  -webkit-appearance: none;
}

:root {
  --view-height: 100vh;
}

.sizeguide-enabled {
  &,
  body {
    overflow: hidden;
  }
}

b {
  font-weight: bold;
}

em {
  font-style: italic;
}

hr.divider {
  border-width: 0.1rem 0 0 0;
}

.divider {
  &,
  &--medium {
    @include border('top');
    @include checkoutSpacing('top', true);
    @include checkoutSpacing('bottom', true);
  }

  &--large {
    @include checkoutLargeSpacing('top', true);
    @include checkoutLargeSpacing('bottom', true);
  }
  &--small {
    @include checkoutSmallSpacing('top', true);
    @include checkoutSmallSpacing('bottom', true);
  }
  &--xsmall {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }
}

//* ANCHOR - TITLE --------------------------------------------------------------------
.editorial-page-title {
  @extend %container;
  @extend %font__title;
  height: 11.6rem;
  display: flex;
  align-items: flex-end;
  padding-bottom: 3.8rem;
}

//* grecaptcha -----------------------------------------------------------------------
.grecaptcha-badge {
  z-index: 10;
}

//* TEXT - EMAIL TRIM ----------------------------------------------------------------
.ellipsed-email {
  max-width: 100%;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

//* TEXT - PAGE - SINGLES ----------------------------------------------------------------
.page-template-archive {
  background-color: $content-black;
  main,
  [class^='experience-'] {
    background-color: $content-black;
  }
}

// TEXT - EMAIL TRIM ----------------------------------------------------------------

ul,
ol {
  &.nolist {
    @include reset-list;
  }
}

.hero {
  &-image {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $overlay-image;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $overlay-image;
    }
    height: 48rem;
    position: relative;
    padding-top: 17.8rem;

    &-title {
      color: $content-white;
      max-width: 24rem;
      z-index: 1;
      position: relative;
    }

    &-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
      }
    }

    &-title {
      color: $content-white;
    }
  }
}
