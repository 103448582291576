.product-zoom-modal {
  .zoom-img-wrapper {
    cursor: url(./../../media/zoom-in-cursor.svg) 20 20, zoom-in;
  }
  .gallery-wrapper.scaled .zoom-img-wrapper {
    cursor: url(./../../media/zoom-out-cursor.svg) 20 20, zoom-out;
  }
  .modal__zoom-controls-wrapper {
    padding: 0 1.4rem 1.65rem;
    justify-content: flex-end;
    .modal__zoom-controls-hint {
      display: none;
    }
  }
  .product-zoom-thumbs--wrapper {
    width: 6.7rem;
    height: 59.4rem;
    right: 1.8rem;
  }
}
