.accordion__group {
  text-align: left;
  position: relative;
  width: 100%;

  &--flex {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 4rem;
    .accordion__item:not(:last-child) {
      margin-bottom: 0;
    }
  }

  &--list {
    @include reset-list;
  }
}

.accordion__item:not(:last-child) {
  margin-bottom: 2rem;
  @media (min-width: get($grid-breakpoints, md)) {
    margin-bottom: 2.4rem;
  }
}

.accordion__header {
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $content-black;
  text-align: left;

  [data-dark='true'] & {
    color: $content-white;
  }

  width: 100%;

  & > h3,
  & > h4,
  & > h5 {
    width: calc(100% - 1.2rem);
  }

  & > span {
    @extend %font__title;
    text-align: left;
    width: calc(100% - 12px);
    sup {
      vertical-align: super;
      font-size: 1rem;
    }
  }

  &:focus,
  &:focus-visible {
    outline: transparent !important;

    .header__open,
    .header__close {
      [data-whatintent='keyboard'] & {
        outline: $outline-width solid $outline-color;
      }
      [data-dark='true'] & {
        [data-whatintent='keyboard'] & {
          outline: $outline-width solid $content-white;
        }
      }
    }
  }

  .header__open {
    &::after {
      @include fixedSprite('10-open');
    }

    [data-dark='true'] & {
      &::after {
        @include fixedSprite('10-open-white');
      }
    }
  }

  .header__close {
    &::after {
      @include fixedSprite('10-minimize');
    }

    [data-dark='true'] & {
      &::after {
        @include fixedSprite('10-minimize-white');
      }
    }
  }

  .header__open,
  .header__close {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    min-width: ($space-unit * 6);
    min-height: ($space-unit * 6);
    width: auto;
    height: auto;
    display: none;
    align-items: center;

    .text {
      display: none;
    }

    &::after {
      content: '';
      display: block;
      width: ($space-unit * 6);
      height: ($space-unit * 6);
    }
  }

  &--cta-text {
    & > span {
      width: calc(100% - 54px);
    }
    .header__open,
    .header__close {
      .text {
        display: block;
      }
      .text,
      &::after {
        opacity: 0.55;
      }
    }
  }

  &--cta-hidden {
    .header__open,
    .header__close {
      display: none;
    }
    cursor: default;
  }

  &[aria-expanded='false'] {
    .header__open {
      display: flex;
    }

    & + .accordion__panel {
      height: 0;
      visibility: hidden;
    }
  }

  &[aria-expanded='true'] {
    .header__close {
      display: flex;
    }
  }

  &--payments {
    align-items: flex-start;
    .header__open,
    .header__close {
      top: 1rem;
      transform: initial;
    }
    > span {
      white-space: initial;
      line-height: 1.6;
    }
  }
}

.accordion__panel {
  overflow: hidden;
  visibility: visible;
  > div {
    padding: 2rem 0 4rem;
  }

  li::marker {
    font-size: 1rem;
  }

  @include animate();
  > * {
    padding-top: $space-unit * 2;
  }

  &--grenoble {
    text-transform: uppercase;
  }
}
