.minibag {
  &__product__image {
    .product-image {
      width: 14.6rem;
    }
  }

  &__product__title {
    white-space: normal;
    text-overflow: unset;
  }
}

.minibag-modal__wrapper {
  .minibag__product__info {
    > p + p {
      margin-top: 1.2rem;
    }
  }
}
