.product-zoom-modal {
  .gallery-wrapper__video,
  .zoom-img-container {
    width: 100%;
    height: 100%;
  }
  .product-zoom-thumbs--wrapper {
    top: 50%;
    right: 2.2rem;
    left: auto;
    transform: translateY(-50%);
    width: 4.6rem;
    height: 43em;
    padding: 0;
  }
}
