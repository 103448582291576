$transition: bottom 0.4s cubic-bezier(0.85, 0, 0.65, 1);
.sticky-tray {
  position: fixed;

  left: 0;
  width: 100%;
  z-index: 8;
  background-color: $content-white;
  border-top: solid 0.1rem #ccc;

  transition: $transition;
  bottom: 0;

  &--inner {
    background-color: $content-white;
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__price {
    flex: 1;
    @include absolute-col(6, lg, min);
    @include absolute-col(4, xxl, min);
  }

  &__name {
    @include absolute-col(15, lg, min);
    @include absolute-col(17, xxl, min);
  }

  &__button {
    flex: 1;
    [class*='button'] {
      width: 100%;
    }
  }

  &__overlay {
    @include z-index('over-header');
  }

  &__modal {
    .selector-color.inpdp {
      margin-bottom: 0;
    }

    .selector-size-expanded {
      .selector__error {
        margin-top: 0;
        transform: unset;
      }
    }

    .modal__container {
      position: absolute;
      bottom: 5.2rem;
      left: 0;
      width: 100%;
    }

    .modal__body {
      padding: 3.2rem 0;
      display: flex;
      flex-direction: column;
      gap: 3.2rem;
    }
  }
}
