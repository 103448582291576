@charset 'utf-8';

@import 'base/reset';
@import 'base/fonts';
@import 'helpers'; // utility sass che non compilano css (es. var e mixin)
@import 'base/icons'; // genera le classi per le icone
@import 'base/typography-classes';
@import 'base/aspect-ratio';
@import 'vendors/aos';
@import 'base/utils';
@import 'base/grid'; // sistema griglia tipo bootstrap
@import 'base/gridoverlay'; // grid overlay (Ctrl + G)
@import 'base/debug'; // classi per debug
@import 'base/print'; // mini gestione stampa

@import '@adyen/adyen-web/dist/adyen.css'; // imported here because importing from react component breaks SSR

// // @import 'mq-layouts';
// // @import 'mq-components';
@import 'mq-old';

@import 'base/app';
