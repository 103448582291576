.expresspayment-modal {
  .modal__body {
    display: flex;
    flex-direction: column;
  }
  &__intro {
    margin-bottom: 0.8rem;
  }
}

.minibag__expresspayment,
.expresspayment-modal {
  #container-paywithgoogle {
    display: none;
  }

  fieldset {
    position: relative;
  }

  .button--arrow-back {
    @extend .button--secondary;
    @extend .visible-on-focus;

    .icon--chevron-left {
      @include fixedSprite('10-left-chevron');
    }
  }

  .checkout-privacyflags {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 2rem 0;
  }

  .data-processing {
    margin-top: 2rem;
  }

  .button--primary {
    width: 100%;
  }
}

.button {
  &--amazonpay,
  &--applepay,
  &--paypal,
  &--googlepay,
  &.dw-apple-pay-button,
  &.dw-apple-pay-button:hover {
    @extend .full;

    border-radius: 0;
    margin-bottom: 0;

    &:not(:first-child) {
      margin-top: 1.2rem;
    }

    &:after {
      height: 2rem !important;
      width: 100% !important;
      background-size: contain;
      background-position: center center;
    }
  }
  &--amazonpay {
    &:after {
      content: '';
      @include fixedSprite('payment-amazonpaybutton');
    }
  }
  &--paypal {
    &:after {
      content: '';
      @include fixedSprite('payment-paypalbutton');
    }
  }
  &--googlepay {
    &:after {
      content: '';
      @include fixedSprite('payment-googlepaybutton');
    }
  }
  &.dw-apple-pay-button {
    @extend .button;
    &:after {
      content: '';
    }
  }
}

.checkout-express {
  @include checkoutLargeSpacing();
  padding-bottom: 16rem;

  .step__header__content {
    @include checkoutSmallSpacing('bottom', true);
    @include checkoutSpacing('top', true);
    @include border('bottom');
    padding-bottom: 2rem;
  }

  .step__header__title {
    @extend %font__title;
  }

  .checkout-review .border-top {
    border-top-color: transparent;
  }

  .express-review-container {
    gap: 2rem;
  }

  .accordion-step:last-child {
    border-bottom-width: 0;
    padding-bottom: 0;
  }

  .step__header__content {
    margin-top: 0;
  }
}
