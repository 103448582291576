.product-hero,
.product-hero--placeholder {
  min-height: 100vh;
  // margin-bottom: 6rem;
}
.product-hero-gallery {
  &::before {
    content: none;
  }
  row-gap: 4rem;
  max-height: unset;
  overflow: hidden;
  padding-left: 0;
  padding-top: 0;
}

.product-hero__col--sticky,
.product-hero-gallery {
  padding-bottom: 4rem;
}

.product-image {
  &-description {
    &__index {
      display: block;
    }

    &__label {
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &--wrapper {
    margin-bottom: 2rem;
  }
}

.product-hero__anchorscroll {
  --col-offset: 14;
  --info-bottom: 0.8rem;
  --info-left: calc(#{var(--container-fill-half-padding)} + calc(#{var(--col-width)} * #{var(--col-offset)}) + calc(#{var(--container-gap)} * #{var(--col-offset)}));

  &.pinned {
    position: fixed;
    bottom: var(--info-bottom);
    @include absolute-col(1);
    left: var(--info-left);
    top: unset;
  }
}
