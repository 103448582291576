.product-zoom-modal {
  .modal__container {
    background-color: $background-product;
  }
  .modal__zoom-controls-wrapper {
    padding: 0 2.2rem 1.9rem;
  }
  .zoom-img-container {
    height: auto;
  }
}
