// SIZE SELECTOR USED IN: PDP, PLP Tile, Reserve in boutique, Shop by Look

.selector-size-expanded {
  position: relative;
  --selector-color: #{$interactive-light};

  // programmatic focus to selector

  &.is-inline {
    display: flex;
    flex-direction: row;
    padding: 0;

    .selector__menu--size {
      padding-top: 0;
    }
  }

  .recommended {
    flex: 1;
    color: $content-black;
    margin-left: ($space-unit * 4);

    // span:only-of-type {
    //   display: none;
    // }
  }

  .btn-label-find-size {
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
    text-transform: uppercase;
  }

  .selector__header {
    display: flex;
    align-items: center;
    padding: 1.1rem 0 0;
    justify-content: space-between;
    white-space: pre;
    flex-wrap: wrap;
    row-gap: 0.4rem;
    &.justify-start {
      justify-content: flex-start;
    }
  }

  .fitting {
    margin-top: 3.2rem;
    b,
    strong {
      @extend %font__title;
    }
  }

  .selector__error {
    margin-top: -1.6rem;
    transform: translateY(-1.2rem);
  }

  .selector__label {
    &__name {
      display: inline-block;
      @extend %font__title;
    }

    &__value,
    &__error {
      @extend %font__subtitle;
      display: inline-block;
    }
  }

  .errormessage {
    color: $alert-error;
    @include text-shorten;
  }

  .selector__menu {
    display: flex;
    align-items: center;
    padding: 1.4rem 0 1.7rem;

    &--size {
      padding-top: 1.4rem;
    }

    ul {
      @include reset-list;
      display: flex;
      flex-wrap: wrap;
      bottom: initial;
      min-width: 100%;
      row-gap: $selector-size-inpage-rowgap-desktop;
      column-gap: 2.7rem;
    }

    &__item {
      &:hover {
        opacity: 0.7;
      }

      &__label {
        position: relative;
        display: inline-block;
        &::after {
          content: '';
          height: 0.1rem;
          bottom: 0;
          // background-color: $grey_line;
          width: 100%;
          min-width: 1.6rem;
          @include center('horizontal');
          visibility: visible;
        }
      }

      &.is-disabled {
        color: $disabled-light;
        position: relative;
        @extend %font__strike;
      }

      &.selected {
        .selector__menu__item__label {
          &::after {
            background-color: var(--selector-color);
            transform: translateX(-50%) scaleX(1);
          }
        }
      }
    }
  }
  // for reworked size selector
  .selector__menu--size {
    display: flex;
    align-items: center;
    row-gap: 1rem;
    column-gap: 1rem;
    flex-wrap: wrap;
    justify-content: space-between;
    .on-pdp & {
      margin-left: -0.6rem;

      label:only-child {
        margin-left: 0.6rem;
      }
    }
  }

  .selector-size-item {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $interactive-light;
    min-height: 2.4rem;
    min-width: 2.4rem;

    > input {
      appearance: initial;
      -webkit-appearance: initial;
      margin: initial;
      border: initial;
      outline: none;
      width: 0;
      height: 0;
      &:focus-visible + .selector-size-item__label {
        outline: 0.2rem solid;
        outline-offset: 0.1rem;
      }
    }

    &.selected {
      color: $content-black;
      .selector-size-item__label {
        &::after {
          background-color: var(--selector-color);
          transform: translateX(-50%) scaleX(1);
        }
      }
    }
    &:hover:not(.selected) {
      .selector-size-item__label {
        &::after {
          transform: translateX(-50%) scaleX(1);
        }
      }
    }
    &.disabled {
      position: relative;
      @extend %font__strike;
    }
  }
  .selector-size-item__label {
    position: relative;
    display: inline-block;
    &::after {
      content: '';
      height: 0.1rem;
      bottom: 0;
      // background-color: $grey_line;
      width: 100%;
      min-width: 1.6rem;
      @include center('horizontal');
      @include animate(0.3);
      visibility: visible;
      transform: translateX(-50%) scaleX(0);
    }
  }

  //INVERTED VARIANT

  &.inverted {
    --selector-color: #{$content-white};
    border-color: $interactive-white;

    .recommended {
      color: $content-white;
    }

    // da verificare
    .button--link {
      color: $content-white;

      i {
        filter: invert(1);
      }

      &::after {
        filter: invert(1);
      }
    }

    // .selector__menu__item {
    //   &:focus {
    //     @include touchtarget-focus($outline-color-inverted);
    //   }
    // }
  }

  //INTILE VARIANT
  &.intile {
    .selector__menu {
      padding: $selector-size-intile-padding;
      ul {
        row-gap: $selector-size-intile-rowgap;
        grid-template-columns: repeat($selector-size-intile-cols, 1fr);
      }
    }
    .selector__header {
      padding-top: 0;
    }

    .selector__menu--size {
      padding-top: 0.8rem;
      justify-content: left;
      column-gap: 4.4rem;

      .selector-size-item {
        min-width: unset;
        @include minClicableArea;
      }
    }
  }

  //IN PDP VARIANT
  &.inpdp {
    margin-bottom: 6rem;
    .selector__header {
      padding: 0.3rem 0;
      margin-bottom: 0.7rem;
    }

    .selector__menu--size {
      justify-content: left;
      column-gap: 3.2rem;
      row-gap: 2.6rem;
      margin-top: 2.4rem;
      padding-top: 0;
    }
  }

  &.format-selector {
    .selector__menu__item::before {
      width: calc(100% + 1.6rem);
    }
  }
}
