.shopthelook {
  margin-bottom: 0;
}
.product-selection.with-looks {
  padding-bottom: 4rem;
  &.pinned {
    padding-bottom: 8rem;
    bottom: 0;
  }
}
.shopthelookmodule {
  flex-direction: row;
  .shopthelookmodule-hero,
  .shopthelookmodule-closeup {
    flex-basis: 50%;
  }
  .shopthelookmodule-title,
  .shopthelookmodule-thumbs {
    position: absolute;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
  }
  .shopthelookmodule-hero {
    height: 100vh;
    position: sticky;
    top: 0;

    .shopthelookmodule-title {
      left: 2.2rem;
      padding: 0;
      .shopthelook-title + .shopthelook-title {
        display: none;
      }
    }
    .product-image img {
      width: auto;
      height: 100%;
      margin: 0 auto;
    }
    .product-tile__image-video {
      height: 100%;
      video {
        width: auto;
        margin: 0 auto;
        max-width: 100%;
        max-height: 100%;
        display: block;
      }
      .product-video-pause {
        right: 1.8rem;
        bottom: 1.8rem;
      }
    }
    .shopthelookmodule-thumbs {
      right: 2.2rem;
      flex-direction: column;
      width: 7.2rem;
    }
  }
  .shopthelookmodule-closeup {
    // .react-tabs__tab-panel {
    //   height: 100%;
    // }
    .shopthelookmodule-title {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 2.2rem;
    }

    .product-tile {
      width: 50%;
      padding: 0;
      margin-top: clamp(8rem, 100vh - 60rem, 20rem);
      margin-inline: auto;
      .product-tile__price {
        margin-top: 1.4rem;
      }
      .product-tile__quite {
        padding-top: 1rem;
      }
      .product-tile__info_content {
        position: static;
      }
    }
  }
}
